import React from "react";
import styled from "styled-components";

//background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(7,17,26,1) 100%);

const BottomFadeWrapper = styled.div`
  height: 56px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(7, 17, 26, 1) 100%
  );
  position: fixed;
  bottom: 0;
`;

const BottomFade = () => {
  return <BottomFadeWrapper></BottomFadeWrapper>;
};

export default BottomFade;
