import React from "react";
import styled from "styled-components";
import HomePageFirstSectionCard from "./HomePageFirstSectionCard";

const HomePageFirstSectionWrapper = styled.div`
  .home-page-first-section-wrapper {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 375px;
    .home-page-first-section-inner {
      .home-page-first-section-title {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 105px;
        h2 {
          ${(props) => props.theme.font_medium_48};
        }
      }

      .home-page-first-section-cards {
        display: flex;
        column-gap: 30px;
      }
    }
  }
  @media (max-width: 1900px) {
    .home-page-first-section-wrapper {
      padding: 0 150px;
      .home-page-first-section-inner {
        .home-page-first-section-title {
          margin-bottom: 75px;
          h2 {
            font-size: 40px;
            line-height: 52px;
          }
        }
      }
    }
  }
  @media (max-width: 1441px) {
    .home-page-first-section-wrapper {
      padding: 0 100px;
    }
  }

  @media (max-width: 1281px) {
    .home-page-first-section-wrapper {
      padding: 0 50px;
      .home-page-first-section-inner {
        .home-page-first-section-title {
          margin-bottom: 35px;
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .home-page-first-section-wrapper {
      padding: 0 25px;
      .home-page-first-section-inner {
        .home-page-first-section-title {
          margin-bottom: 35px;
          h2 {
            font-size: 34px;
            line-height: 44px;
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    .home-page-first-section-wrapper {
      .home-page-first-section-inner {
        .home-page-first-section-title {
          margin-bottom: 35px;
          width: 100%;
          h2 {
            font-size: 26px;
            line-height: 33px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .home-page-first-section-wrapper {
      .home-page-first-section-inner {
        .home-page-first-section-title {
          margin-bottom: 35px;
          width: 100%;
          h2 {
            font-size: 22px;
            line-height: 28px;
          }
        }
        .home-page-first-section-cards {
          display: block;
        }
      }
    }
  }
`;

const HomePageFirstSection = () => {
  return (
    <HomePageFirstSectionWrapper>
      <div className="home-page-first-section-wrapper section">
        <div className="home-page-first-section-inner">
          <div className="home-page-first-section-title">
            <h2>
              From software solutions to team extensions and everything in
              between.
            </h2>
          </div>
          <div className="home-page-first-section-cards">
            <HomePageFirstSectionCard
              title="Team Extension"
              text="Leverage the power of our extended team model and create new or extend existing teams equipped with technical expertise and engineering talent, ready to bring your innovations to the market."
            />
            <HomePageFirstSectionCard
              title="Custom Solution Development"
              text="Trust Ice Labs' engineering team to maintain and oversee all stages of the development cycle - from the initial stages to post-delivery support with a robust Agile development methodology."
            />
            <HomePageFirstSectionCard
              title="Managed Services"
              text="Rely on our extensive knowledge to keep your business processes secure and protected or resolve any technical issue and challenge, while you focus on achieving your business objectives."
            />
          </div>
        </div>
      </div>
    </HomePageFirstSectionWrapper>
  );
};

export default HomePageFirstSection;
