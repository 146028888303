import React from "react";
import styled from "styled-components";
import LatestBlogsCard from "../LatestBlogs/LatestBlogsCard";
import laptop from "../../img/latest-blogs/laptop.png";
import CustomButton from "../CustomButton/CustomButton";

const BlogPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: end;
      .content {
        width: 100%;
        margin-bottom: 40px;
        .text {
          text-align: center;
          margin-bottom: 40px;
          h2 {
            ${(props) => props.theme.font_bold_106};
            margin-bottom: 20px;
          }
          p {
            ${(props) => props.theme.font_regular_20};
            color: #fff;
          }
        }
        .intro-wrapper {
          display: flex;
          column-gap: 30px;
          .intro-left {
            flex-basis: 65%;
          }
          .intro-right {
            padding: 50px 36px 36px;
            flex-basis: 35%;
            background: transparent
              linear-gradient(179deg, #1f3040 0%, #1f304029 100%) 0% 0%
              no-repeat padding-box;
            box-shadow: 25px 25px 40px #0009109e;
            border-radius: 10px;
            h2 {
              ${(props) => props.theme.font_medium_48};
            }
            p {
              ${(props) => props.theme.font_regular_20};
              color: #fff;
              margin: 15px 0;
            }
            .form-group {
              margin-bottom: 10px;
              input {
                padding: 20px;
                background-color: transparent;
                border: 1px solid #9bb3cf;
                border-radius: 5px;
                color: #9bb3cf;
                display: block;
                width: 100%;
                ${(props) => props.theme.font_regular_18};

                &::placeholder {
                  ${(props) => props.theme.font_regular_18};
                  color: #9bb3cf;
                }
                &:focus {
                  outline: 0;
                  border-color: ${(props) => props.theme.secondary_color};
                }
              }
            }
            .form-end {
              .left {
                margin-bottom: 10px;
                display: flex;
                column-gap: 10px;
                align-items: center;
                flex-basis: 60%;
                input {
                  margin: 0;
                }
                label {
                  ${(props) => props.theme.font_regular_12}
                }
              }
              .right {
                text-align: right;
                > div {
                  display: block;
                  a {
                    padding: 15px 30px;
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
      }
    }
  }
  @media (max-width: 1537px) and (max-height: 865px) {
    .wrapper {
      .inner {
        .content {
          .text {
            margin-bottom: 30px;
            h2 {
              font-size: 80px;
              line-height: 104px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .content {
          margin-bottom: 30px;
          .text {
            margin-bottom: 25px;
            h2 {
              font-size: 70px;
              line-height: 91px;
              margin-bottom: 5px;
            }
          }
          .intro-wrapper {
            .intro-right {
              h2 {
                font-size: 33px;
                line-height: 43px;
              }
              p {
                font-size: 20px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        align-items: center;
        .content {
          margin-bottom: 0;
          .text {
            h2 {
              font-size: 60px;
              line-height: 78px;
            }
            p {
              font-size: 18px;
              line-height: 23px;
            }
          }
          .intro-wrapper {
            .intro-right {
              padding: 30px 25px;
              h2 {
                font-size: 33px;
                line-height: 43px;
              }
              p {
                font-size: 20px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) and (max-height: 721px) {
    .wrapper {
      .inner {
        align-items: flex-end;
        .content {
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .content {
          .text {
            h2 {
              font-size: 60px;
              line-height: 78px;
            }
            p {
              font-size: 16px;
              line-height: 21px;
            }
          }
          .intro-wrapper {
            .intro-right {
              padding: 30px 25px;
              h2 {
                font-size: 28px;
                line-height: 36px;
              }
              p {
                font-size: 18px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .content {
          margin-bottom: 15px;
          .text {
            margin-bottom: 5px;
            h2 {
              font-size: 28px;
              line-height: 36px;
              margin-bottom: 0;
            }
            p {
              display: none;
            }
          }
          .intro-wrapper {
            display: block;
            .intro-right {
              padding: 10px;
              margin-top: 10px;
              h2 {
                font-size: 20px;
                line-height: 26px;
              }
              p {
                font-size: 14px;
                line-height: 18px;
                margin: 5px 0;
              }
              .form-group {
                input {
                  padding: 10px;
                  font-size: 14px;
                  line-height: 18px;
                  &::placeholder {
                    font-size: 14px;
                    line-height: 18px;
                  }
                }
              }
              .form-end {
                .right {
                  > div {
                    a {
                      padding: 10px 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BlogPageIntro = () => {
  return (
    <BlogPageIntroWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <div className="text">
              <h2>The Blog</h2>
              <p>
                This Ice Labs blog is a hub for in-depth development tutorials
                and new technology announcements created by professional
                software engineers and researches in the Adeva network.
              </p>
            </div>
            <div className="intro-wrapper">
              <div className="intro-left">
                <LatestBlogsCard
                  title="Lorem ipsum dolor sit amet, consetetur dolor"
                  category="SOFTWARE DEVELOPMENT"
                  img={laptop}
                  text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet"
                  readTime="10 min read"
                />
              </div>
              <div className="intro-right">
                <h2>Receive the latest updates in your inbox</h2>
                <p>
                  Want to receive Ice Labs news and updates from our blog? Join
                  our mailing list here!
                </p>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    required
                  />
                </div>
                <div className="form-end">
                  <div className="left">
                    <div>
                      <input type="checkbox" id="accept" name="accept" />
                    </div>

                    <label htmlFor="accept">
                      I accept that the information I provide is processed
                      according to Ice Labs privacy policy.
                    </label>
                  </div>
                  <div className="right">
                    <CustomButton title="Subscribe" to="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlogPageIntroWrapper>
  );
};

export default BlogPageIntro;
