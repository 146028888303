import React from "react";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";

const CareersPageFirstSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: end;
      .content {
        width: 100%;
        margin-bottom: 40px;
        h2 {
          ${(props) => props.theme.font_medium_48};
          text-align: center;
          margin-bottom: 50px;
        }
        .table-positions {
          position: relative;
          background: transparent
            linear-gradient(150deg, #1f3040 0%, #1f304000 100%) 0% 0% no-repeat
            padding-box;
          box-shadow: 18px 18px 40px #07111a;
          border-radius: 10px;
          h3 {
            ${(props) => props.theme.font_regular_26};
            color: #fff;
            text-align: center;
            padding: 50px;
          }
          .card-wrapper {
            .card {
              padding: 20px 70px;
              border-top: 1px solid #fff;
              .card-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .card-left {
                  h4 {
                    ${(props) => props.theme.font_demibold_26};
                    color: ${(props) => props.theme.secondary_color};
                  }
                  p {
                    ${(props) => props.theme.font_regular_20};
                    color: #9bb3cf;
                  }
                }
                .card-right {
                  display: flex;
                  column-gap: 15px;
                }
              }
            }
          }

          .circle-lines {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translatex(-50%);
            .down {
              transform: rotate(180deg);
              margin-top: 44px;
            }
            span {
              width: 18px;
              height: 18px;
              background-color: ${(props) => props.theme.secondary_color};
              display: block;
              border-radius: 50%;
              position: relative;
            }
            hr {
              border: 0;
              border-left: 2px solid #9bb3cf;
              margin: 0;
              height: 25px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 15px;
              z-index: -1;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
      }
    }
  }

  @media (max-width: 1536px) and (max-height: 899px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .content {
          .table-positions {
            h3 {
              padding: 30px 50px;
            }
            .circle-lines {
              top: -35px;
              hr {
                height: 20px;
              }
              .down {
                margin-top: 34px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1441px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .content {
          .table-positions {
            h3 {
              padding: 40px 50px;
            }
            .circle-lines {
              .down {
                margin-top: 43px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .content {
          h2 {
            font-size: 35px;
            line-height: 45px;
            margin-bottom: 15px;
          }
          .table-positions {
            h3 {
              padding: 20px;
            }
            .circle-lines {
              display: none;
            }
            .card-wrapper {
              .card {
                padding: 15px 40px;
                .card-inner {
                  .card-left {
                    h4 {
                      font-size: 24px;
                      line-height: 31px;
                    }
                    p {
                      font-size: 18px;
                      line-height: 23px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        align-items: center;
        .content {
          margin-bottom: 0;
          h2 {
            font-size: 25px;
            line-height: 32px;
            margin-bottom: 10px;
          }
          .table-positions {
            h3 {
              padding: 10px;
              font-size: 20px;
              line-height: 26px;
            }
            .card-wrapper {
              .card {
                padding: 10px 10px;
                .card-inner {
                  display: block;
                  text-align: center;
                  .card-left {
                    margin-bottom: 10px;
                    h4 {
                      font-size: 16px;
                      line-height: 21px;
                    }
                    p {
                      font-size: 14px;
                      line-height: 18px;
                    }
                  }
                  .card-right {
                    column-gap: 10px;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 376px) and (max-height: 668px) {
    .wrapper {
      .inner {
        .content {
          margin-bottom: 0;
          h2 {
            font-size: 25px;
            line-height: 32px;
            margin-bottom: 10px;
          }
          .table-positions {
            h3 {
              padding: 10px;
              font-size: 20px;
              line-height: 26px;
            }
            .card-wrapper {
              .card {
                padding: 5px;
                .card-inner {
                  .card-left {
                    margin-bottom: 5px;
                    h4 {
                      font-size: 16px;
                      line-height: 21px;
                    }
                    p {
                      font-size: 14px;
                      line-height: 18px;
                    }
                  }
                  .card-right {
                    column-gap: 10px;
                    justify-content: center;
                    a {
                      padding: 6px 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CareersPageFirstSection = () => {
  return (
    <CareersPageFirstSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <h2>Join our team</h2>
            <div className="table-positions">
              <div className="circle-lines">
                <div className="up">
                  <span>
                    <hr />
                  </span>
                </div>
                <div className="down">
                  <span>
                    <hr />
                  </span>
                </div>
              </div>
              <h3>Open positions</h3>
              <div className="card-wrapper">
                <div className="card">
                  <div className="card-inner">
                    <div className="card-left">
                      <h4>Business Developer</h4>
                      <p>Full-time</p>
                    </div>
                    <div className="card-right">
                      <CustomButton title="Apply now" />
                      <CustomButton title="Refer a friend" colorTheme="dark" />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-inner">
                    <div className="card-left">
                      <h4>Machine Learning Developer</h4>
                      <p>Full-time</p>
                    </div>
                    <div className="card-right">
                      <CustomButton title="Apply now" />
                      <CustomButton title="Refer a friend" colorTheme="dark" />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-inner">
                    <div className="card-left">
                      <h4>Senior iOS Engineer</h4>
                      <p>Part-time</p>
                    </div>
                    <div className="card-right">
                      <CustomButton title="Apply now" />
                      <CustomButton title="Refer a friend" colorTheme="dark" />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-inner">
                    <div className="card-left">
                      <h4>JavaScript Developer</h4>
                      <p>Part-time</p>
                    </div>
                    <div className="card-right">
                      <CustomButton title="Apply now" />
                      <CustomButton title="Refer a friend" colorTheme="dark" />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-inner">
                    <div className="card-left">
                      <h4>Python Engineer</h4>
                      <p>Part-time</p>
                    </div>
                    <div className="card-right">
                      <CustomButton title="Apply now" />
                      <CustomButton title="Refer a friend" colorTheme="dark" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CareersPageFirstSectionWrapper>
  );
};

export default CareersPageFirstSection;
