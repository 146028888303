import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import BottomFade from "../BottomFade/BottomFade";
import LatestBlogs from "../LatestBlogs/LatestBlogs";
import Menu from "../NavBar/Menu";
import BlogPageFirstSection from "./BlogPageFirstSection";
import BlogSubscribeSection from "./BlogSubscribeSection";
import BlogPageIntro from "./BlogPageIntro";
import BlogPageSecondSection from "./BlogPageSecondSection";
import BlogPageThirdSection from "./BlogPageThirdSection";

const BlogPage = () => {
  const { setIsWheelEnabled, MouseWheelHandler } = useContext(GlobalContext);

  useEffect(() => {
    setIsWheelEnabled(false);
    setTimeout(() => {
      setIsWheelEnabled(true);
    }, 3000);
  }, []);
  return (
    <div
      onWheel={(e) => MouseWheelHandler(e)}
      onTouchStart={(e) => MouseWheelHandler(e)}
      onTouchEnd={(e) => MouseWheelHandler(e)}
    >
      <Menu />
      <BlogPageIntro />
      <BlogPageFirstSection />
      <BlogPageSecondSection />
      <BlogPageThirdSection />
      <BlogSubscribeSection />
      <BottomFade />
    </div>
  );
};

export default BlogPage;
