import React from "react";
import styled from "styled-components";
import laptop from "../../img/latest-blogs/laptop.png";
import LatestBlogsCard from "./LatestBlogsCard";

const LatestBlogsWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        ${(props) => props.theme.font_medium_48};
        text-align: center;
        margin-bottom: 45px;
      }
      .card-wrapper {
        display: flex;
        column-gap: 30px;
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
        h2 {
          font-size: 40px;
          line-height: 52px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        h2 {
          font-size: 40px;
          line-height: 52px;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        h2 {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        h2 {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 10px;
        h2 {
          font-size: 25px;
          line-height: 32px;
          margin-bottom: 10px;
        }
        .card-wrapper {
          flex-wrap: wrap;
          row-gap: 10px;
          column-gap: 0;
        }
      }
    }
  }
`;

const LatestBlogs = () => {
  return (
    <LatestBlogsWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div>
            <h2>Latest Case Studies</h2>
            <div className="card-wrapper">
              <LatestBlogsCard
                title="Lorem ipsum dolor sit amet, consetetur dolor"
                img={laptop}
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum"
                category="SOFTWARE DEVELOPMENT"
                readTime="10 min read"
              />
              <LatestBlogsCard
                title="Lorem ipsum dolor sit amet, consetetur dolor"
                img={laptop}
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum"
                category="SOFTWARE DEVELOPMENT"
                readTime="10 min read"
              />
              <LatestBlogsCard
                title="Lorem ipsum dolor sit amet, consetetur dolor"
                img={laptop}
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum"
                category="SOFTWARE DEVELOPMENT"
                readTime="10 min read"
              />
            </div>
          </div>
        </div>
      </div>
    </LatestBlogsWrapper>
  );
};

export default LatestBlogs;
