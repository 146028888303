import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline; 
        box-sizing: border-box; 

    }
    *, *:before, *:after {
      box-sizing: inherit;
    }
    :root{
      overflow:hidden;

      --circle1AnimationTopFirst:-1225px;
      --circle1AnimationTopSecond:-825px;
      --circle2AnimationTopFirst:-1480px;
      --circle2AnimationTopSecond:-1080px;

      --managedServicesTextAnimation:120px;


      --managedServicesFirstCogAnimationBottom1:-50px;
      --managedServicesFirstCogAnimationBottom2:650px;
      --managedServicesFirstCogAnimationStatic:350px;


      --managedServicesSecondCogAnimationBottom1:-320px;
      --managedServicesSecondCogAnimationBottom2:450px;
      --managedServicesSecondCogAnimationStatic:100px;

      --rectangleAnimation1width1:1500px;
      --rectangleAnimation1height1:1000px;
      --rectangleAnimation1top1:-275px;
      --rectangleAnimation1left1:-260px;
      --rectangleAnimation1width2:1000px;
      --rectangleAnimation1height2:750px;
      --rectangleAnimation1top2:-12px;
      --rectangleAnimation1left2:0px;

      --rectangleAnimation2width1:1600px;
      --rectangleAnimation2height1:1100px;
      --rectangleAnimation2top1:-320px;
      --rectangleAnimation2left1:-306px;
      --rectangleAnimation2width2:1100px;
      --rectangleAnimation2height2:850px;
      --rectangleAnimation2top2:-70px;
      --rectangleAnimation2left2:-45px;

      --rectangleAnimation3width1:1700px;
      --rectangleAnimation3height1:1200px;
      --rectangleAnimation3top1:-370px;
      --rectangleAnimation3left1:-350px;
      --rectangleAnimation3width2:1200px;
      --rectangleAnimation3height2:950px;
      --rectangleAnimation3top2:-120px;
      --rectangleAnimation3left2:-96px;

      --mainImageSecondAnimationTransform:200px;


      --circleStaticAnimationRotate:45deg;
      --circleIntroAnimationTransform1:0px;
      --circleIntroAnimationTransform2:-450px;


    }
    body{
      background-color:#07111A;
      // overflow:hidden;
      // touch-action:pan-y;
    }
    html {
      animation: smoothscroll1 3s;
    }
    
    html:focus-within {
      animation-name: smoothscroll2;
      scroll-behavior: smooth;
    }
    

    @media(max-width:1367px){
      :root{
      --managedServicesFirstCogAnimationBottom2:550px;
      --managedServicesSecondCogAnimationBottom2:400px;


      --rectangleAnimation1width2:850px;
      --rectangleAnimation1height2:400px;

      --rectangleAnimation2width2:950px;
      --rectangleAnimation2height2:500px;

      --rectangleAnimation3width2:1050px;
      --rectangleAnimation3height2:800px;
      }
    }

    @media(max-width:1025px){
      :root{


      --rectangleAnimation1width2:750px;
      --rectangleAnimation1height2:500px;

      --rectangleAnimation2width2:850px;
      --rectangleAnimation2height2:600px;

      --rectangleAnimation3width2:950px;
      --rectangleAnimation3height2:700px;
      }
    }
    @media(max-width:426px){
      :root{
      --managedServicesFirstCogAnimationBottom2:450px;
      --managedServicesSecondCogAnimationBottom2:300px;

      }
    }


`;

export default GlobalStyle;
