import React from "react";
import styled from "styled-components";

const BlogSingleElementPageThirdSectionWrapper = styled.div`
  .wrapper {
    height: 100vh;
    position: relative;
    .inner {
      height: 100%;
      padding: 0 550px;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        margin-bottom: 60px;
        width: 100%;
        h3 {
          ${(props) => props.theme.font_demibold_26};
          margin-bottom: 30px;
          color: #fff;
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
        }
        ul {
          ${(props) => props.theme.font_regular_20};
          margin-top: 30px;
          color: #fff;
          li {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
`;

const BlogSingleElementPageThirdSection = () => {
  return (
    <BlogSingleElementPageThirdSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <h3>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            </h3>
            <p>
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              met, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              nvidunt ut labore et dolore magna aliquyam erat, sed diam
              voluptua. At vero eos et accusam etjusto duo dolores et ea rebum.
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
              r sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
              elitr, sed diam nonumy eirmod empor invidunt ut labore et dolore
              magna aliquyam erat, sed diam voluptua. At vero eos et ccusam et
              justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
              takimata sanctus est orem ipsum dolor sit amet.
            </p>
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore{" "}
              </li>
              <li>
                Magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                et justo duo dolores et ea rebum onsetetur sadipscing elitr, se{" "}
              </li>
              <li>
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                ipsum dolor sit orem ipsum dolor sit amet, consetetur sadipscing
                elitr, sed diam nonumy eirmod tempor nvidunt ut labore et dolore
                magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                et
              </li>
            </ul>
          </div>
        </div>
      </div>
    </BlogSingleElementPageThirdSectionWrapper>
  );
};

export default BlogSingleElementPageThirdSection;
