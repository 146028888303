import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HomePageThirdSectionCardWrapper = styled.div`
  .card {
    cursor: default;
    .card-wrapper {
      margin: 0 15px;
      width: 255px;
      height: 125px;
      background: transparent
        linear-gradient(360deg, #1f3040 -35%, #1f304000 135%) 0% 0% no-repeat
        padding-box;
      box-shadow: 18px 18px 24px #07111ae6;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      transition: all 0.8s ease-out;

      p {
        ${(props) => props.theme.font_demibold_18};
        color: ${(props) => props.theme.secondary_color};
        text-transform: capitalize;
        text-align: center;
        padding: 0 10px;
      }
    }
    &:hover {
      .card-wrapper {
        box-shadow: 25px 25px 40px #000910;
        background: #1f3040 0% 0% no-repeat padding-box;
        border-radius: 70px;
      }
    }
  }

  @media (max-width: 1281px) {
    .card {
      .card-wrapper {
        height: 105px;
      }
    }
  }

  @media (max-width: 1025px) {
    .card {
      .card-wrapper {
        height: 105px;
        width: 230px;
      }
    }
  }

  @media (max-width: 769px) {
    .card {
      .card-wrapper {
        height: 80px;
        width: 170px;
        margin: 0 10px;

        p {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  @media (max-width: 426px) {
    flex-basis: 50%;
    .card {
      .card-wrapper {
        height: 70px;
        width: auto;
        margin: 0 10px;

        p {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
`;

const HomePageThirdSectionCard = ({ title }) => {
  return (
    <HomePageThirdSectionCardWrapper>
      <div className="card">
        <div className="card-wrapper">
          <p>{title}</p>
        </div>
      </div>
    </HomePageThirdSectionCardWrapper>
  );
};

export default HomePageThirdSectionCard;
