import React from "react";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const CustomSolutionDevelopmentPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .text {
        animation: textAnimation 2s ease-in-out forwards;
        h1 {
          ${(props) => props.theme.font_bold_106};
          margin-bottom: 20px;
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          padding: 0 325px;
          &:first-of-type {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  @keyframes textAnimation {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-150px);
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .text {
          h1 {
            font-size: 90px;
            line-height: 115px;
            margin-bottom: 10px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
            padding: 0 200px;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        .text {
          h1 {
            font-size: 75px;
            line-height: 97px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 50px;
        .text {
          h1 {
            font-size: 65px;
            line-height: 85px;
          }
          p {
            font-size: 15px;
            line-height: 19px;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 25px;
        .text {
          h1 {
            font-size: 60px;
            line-height: 78px;
          }
          p {
            font-size: 15px;
            line-height: 19px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .text {
          h1 {
            font-size: 45px;
            line-height: 58px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            padding: 0;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    @keyframes textAnimation {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-80px);
      }
    }
  }
  @media (max-width: 376px) {
    .wrapper {
      .inner {
        .text {
          h1 {
            font-size: 40px;
            line-height: 52px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            padding: 0;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
`;

const CustomSolutionDevelopmentPageIntro = () => {
  return (
    <CustomSolutionDevelopmentPageIntroWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="text">
            <h1>Who we are</h1>
            <p>
              Ice Labs is a trusted custom solution development and technology
              consulting company, headquartered in Munich, Germany and with
              delivery centers in Germany and Eastern Europe.
            </p>
            <p>
              Since our inception, we have delivered innovative software
              solutions to companies and enterprises worldwide that partner with
              us for our core engineering, profound integration capabilities and
              technology consulting. Our vast technology and industry expertise
              help our clients across multiple business domains to deliver
              successful and scalable products.
            </p>
          </div>
        </div>
      </div>
    </CustomSolutionDevelopmentPageIntroWrapper>
  );
};

export default CustomSolutionDevelopmentPageIntro;
