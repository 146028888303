import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HomePageFirstSectionCardWrapper = styled.div`
  aspect-ratio: 1/1;
  .card {
    text-decoration: none;

    .card-wrapper {
      display: flex;
      height: 100%;
      .card-inner {
        position: relative;
        background: transparent
          linear-gradient(360deg, #1f3040 -35%, #1f304000 135%) 0% 0% no-repeat
          padding-box;
        box-shadow: 18px 18px 24px #07111ae6;
        padding: 40px;
        border-radius: 10px;
        text-align: center;
        transition: all 0.6s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title {
          ${(props) => props.theme.font_demibold_26};
          color: ${(props) => props.theme.secondary_color};
          margin-bottom: 15px;
        }
        .text {
          ${(props) => props.theme.font_regular_20};
          color: #9bb3cf;
        }
        > span {
          position: absolute;
          display: block;
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
          ${(props) => props.theme.font_demibold_18};
          color: #fff;
          opacity: 0;
          transition: all 0.8s ease-out;
        }
        &:after {
          content: "";
          opacity: 0;
          width: 0;
          height: 0;
          position: absolute;
          background-color: ${(props) => props.theme.secondary_color};
          left: 50%;
          bottom: -80px;
          transform: translateX(-50%);
          border-radius: 50%;
          z-index: -1;
          transition: all 0.8s ease-out;
        }
      }
    }
    &:hover {
      .card-wrapper {
        .card-inner {
          border-radius: 50%;
          box-shadow: 25px 25px 40px #000910;
          background: #1f3040 0% 0% no-repeat padding-box;
          > span {
            opacity: 1;
          }
          &:after {
            width: 160px;
            height: 160px;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .card {
      height: .card-wrapper {

      }
    }
  }
  @media (max-width: 1025px) {
    .card {
      .card-wrapper {
        .card-inner {
          padding: 20px;
          .title {
            font-size: 22px;
            line-height: 28px;
          }
          .text {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    .card {
      .card-wrapper {
        .card-inner {
          padding: 20px;
          .title {
            font-size: 16px;
            line-height: 20px;
          }
          .text {
            font-size: 12px;
            line-height: 15px;
          }
          > span {
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
      &:hover {
        .card-wrapper {
          .card-inner {
            > span {
              bottom: -28px;
            }
            &:after {
              width: 100px;
              height: 100px;
              bottom: -55px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 426px) {
    aspect-ratio: auto;
    .card {
      .card-wrapper {
        .card-inner {
          padding: 20px;
          .title {
            font-size: 16px;
            line-height: 20px;
          }
          .text {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      &:hover {
        .card-wrapper {
          .card-inner {
            border-radius: 10px;
            > span {
              display: none;
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const HomePageFirstSectionCard = ({ title, text, to }) => {
  return (
    <HomePageFirstSectionCardWrapper>
      <Link to={to} className="card">
        <div className="card-wrapper">
          <div className="card-inner">
            <div className="title">
              <h3>{title}</h3>
            </div>
            <div className="text">
              <p>{text}</p>
            </div>
            <span>Learn more</span>
          </div>
        </div>
      </Link>
    </HomePageFirstSectionCardWrapper>
  );
};

export default HomePageFirstSectionCard;
