import React, { useEffect, useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import MaskGroup89 from "../../img/team-extension/mask-group-89.svg";
import { useInView } from "react-intersection-observer";
const TeamExtensionPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 545px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      .title {
        position: relative;
        z-index: 1;
        transform: translateY(-150px);
        h1 {
          ${(props) => props.theme.font_bold_106};
          margin-bottom: 25px;
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          &:last-of-type {
            margin-top: 25px;
          }
        }

        animation: TitleAnimation 3s ease-in-out forwards;
      }
      .title.visible {
        animation: TitleAnimationEnd 1s ease-in-out forwards;
      }
      .avatars {
        position: absolute;
        bottom: -500px;
        animation: avatarsAnimation 2s ease-in-out forwards;
      }
      .circle1,
      .circle2 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
      }
      .circle1 {
        width: 1593px;
        height: 1649px;
        background-color: #000;
        top: -1225px;
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #ffffff00 100%) 0% 0% no-repeat
          padding-box;
        mix-blend-mode: overlay;
        animation: circle1Animation 2s ease-in-out forwards;
      }
      .circle1.visible {
        animation: circle1AnimationEnd 1.9s ease-in-out forwards;
      }
      .circle2.visible {
        animation: circle2AnimationEnd 1.86s ease-in-out forwards;
      }
      .circle2 {
        width: 2155px;
        height: 2155px;
        top: -1480px;
        background-color: #fff;
        z-index: -2;
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #ffffff00 100%) 0% 0% no-repeat
          padding-box;
        mix-blend-mode: overlay;
        animation: circle2Animation 2s ease-in-out forwards;
      }
    }
  }

  @keyframes TitleAnimation {
    0% {
      transform: translateY(-150px);
    }
    100% {
      transform: translateY(70px);
    }
  }
  @keyframes TitleAnimationEnd {
    0% {
      transform: translateY(70px);
    }
    100% {
      transform: translateY(-150px);
    }
  }
  @keyframes circle1Animation {
    0% {
      opacity: 1;

      top: var(--circle1AnimationTopFirst);
    }
    100% {
      opacity: 1;

      top: var(--circle1AnimationTopSecond);
    }
  }
  @keyframes circle1AnimationEnd {
    0% {
      top: -825px;
    }
    55% {
      opacity: 1;
    }
    100% {
      top: 825px;
      opacity: 0;
    }
  }
  @keyframes circle2Animation {
    0% {
      top: var(--circle2AnimationTopFirst);
    }
    100% {
      top: var(--circle2AnimationTopSecond);
    }
  }
  @keyframes circle2AnimationEnd {
    0% {
      top: -1080px;
    }
    55% {
      opacity: 1;
    }
    100% {
      opacity: 0;

      top: 570px;
    }
  }
  @keyframes avatarsAnimation {
    0% {
      bottom: -500px;
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      bottom: 1000px;
      opacity: 0;
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 300px;
        .title {
          h1 {
            font-size: 90px;
            line-height: 115px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        padding: 0 250px;
        .title {
          h1 {
            font-size: 82px;
            line-height: 106px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .title {
          h1 {
            font-size: 75px;
            line-height: 97px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .title {
          h1 {
            font-size: 45px;
            line-height: 58px;
            margin-bottom: 15px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            &:last-of-type {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
`;

const TeamExtensionPageIntro = () => {
  const options = {
    root: null,
    rootMargin: "200px",
    threshold: 0.9,
  };
  const { ref: endAnimationRef, inView: isVisible, entry } = useInView(options);
  return (
    <TeamExtensionPageIntroWrapper ref={endAnimationRef}>
      <div className="wrapper section">
        <div className="inner">
          <div className={isVisible ? "circle1" : "circle1 visible"}></div>
          <div className={isVisible ? "circle2" : "circle2 visible"}></div>
          <div className="avatars">
            <img src={MaskGroup89} alt="avatars"></img>
          </div>
          <div className={isVisible ? "title" : "title visible"}>
            <h1>Team Extension</h1>
            <p>
              Leverage the power of our extended team model and create new or
              extend existing teams equipped with technical expertise and
              engineering talent, ready to bring your innovations to the market.
            </p>
            <p>
              Whether you are looking to upscale your in-house development
              capacity, build-up a new field of technical expertise, or
              accelerate the current development of your software or product,
              our agile team extensions can support you at all stages.
            </p>
          </div>
        </div>
      </div>
    </TeamExtensionPageIntroWrapper>
  );
};

export default TeamExtensionPageIntro;
