import React from "react";
import styled from "styled-components";
import { ArrowDown } from "@styled-icons/bootstrap/ArrowDown";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";

const HomePageIntroWrapper = styled.div`
  scroll-behavior: smooth;

  .home-page-intro-wrapper {
    height: 100vh;
    overflow: hidden;
    position: relative;

    .home-page-intro {
      position: relative;
      height: 100%;

      .home-page-intro-title {
        position: relative;
        width: 65%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1 {
          text-align: center;
          ${(props) => props.theme.font_bold_106};
          color: white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          overflow: auto;
          &:nth-child(1) {
            color: #fff;
            mix-blend-mode: darken;
          }
          &:nth-child(3) {
            opacity: 0;
            color: ${(props) => props.theme.primary_color};
            -webkit-text-stroke: 0.5px #fff;
            animation: animateCircleStroke 10s ease-in 3s forwards;
            clip-path: circle(21% at 50% 50%);
          }
          &:nth-child(2) {
            opacity: 0;
            color: ${(props) => props.theme.secondary_color};
            animation: animateCircleBlue 10s ease-in 3s forwards;
            clip-path: circle(31% at 50% 50%);
          }
        }
      }
      .home-page-intro-circles-wrapper {
        .home-page-intro-circles {
          position: absolute;
          border: 2px solid white;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          &:nth-of-type(1) {
            opacity: 0;
            width: 900px;
            height: 900px;
            animation: animateBgCircles1 12s ease-in forwards;
          }
          &:nth-of-type(2) {
            animation: animateBgCircles1 12s ease-in forwards;
            opacity: 0;
            width: 1100px;
            height: 1100px;
          }
          &:nth-of-type(3) {
            animation: animateBgCircles1 12s ease-in forwards;
            opacity: 0;
            width: 1300px;
            height: 1300px;
          }
          &:nth-of-type(4) {
            animation: animateBgCircles1 12s ease-in forwards;
            opacity: 0;
            width: 1500px;
            height: 1500px;
          }
          &:nth-of-type(5) {
            animation: animateBgCircles1 12s ease-in forwards;
            opacity: 0;
            width: 1700px;
            height: 1700px;
          }
          &:nth-of-type(6) {
            animation: animateBgCircles1 12s ease-in forwards;
            opacity: 0;
            width: 1900px;
            height: 1900px;
          }
        }
      }
      .home-page-intro-learn-btn {
        position: absolute;
        bottom: -130px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        text-align: center;
        opacity: 0;
        animation: learnHowAnimation1 4s ease-in-out 7.5s forwards;
        p {
          color: ${(props) => props.theme.secondary_color};
          cursor: pointer;
          ${(props) => props.theme.font_regular_22};
          margin-bottom: 15px;
        }
        > span {
          display: inline-block;
          text-align: center;
          > span {
            cursor: pointer;
            border: 1px solid #fff;
            border-radius: 50%;
            animation: learnHowAnimation2 4s ease-in-out 7.5s forwards;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            > span {
              border-radius: 50%;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 45px;
              height: 45px;
              background-color: ${(props) => props.theme.secondary_color};
              animation: learnHowAnimation3 4s ease-in-out 7.5s forwards;
            }
          }
        }
      }
    }
    .home-page-intro-bottom-fade {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(7, 17, 26, 1) 100%
      );
      height: 400px;
    }
  }
  @keyframes animateCircleStroke {
    0% {
      opacity: 1;
      clip-path: circle(0% at 50% 50%);
    }
    20% {
      clip-path: circle(21% at 50% 50%);
    }
    30% {
      clip-path: circle(21% at 50% 50%);
    }
    45% {
      clip-path: circle(15% at 85% 70%);
    }
    60% {
      clip-path: circle(15% at 50% 5%);
    }
    75% {
      clip-path: circle(15% at 35% 50%);
    }
    90% {
      opacity: 1;
      clip-path: circle(21% at 50% 50%);
    }
    100% {
      opacity: 1;
      clip-path: circle(21% at 50% 50%);
    }
  }

  @keyframes animateCircleBlue {
    0% {
      opacity: 1;
      clip-path: circle(0% at 50% 50%);
    }
    20% {
      clip-path: circle(31% at 50% 50%);
    }
    30% {
      clip-path: circle(31% at 50% 50%);
    }
    45% {
      clip-path: circle(20% at 85% 70%);
    }
    60% {
      clip-path: circle(20% at 50% 5%);
    }
    75% {
      clip-path: circle(20% at 35% 50%);
    }
    90% {
      opacity: 1;
      clip-path: circle(31% at 50% 50%);
    }
    100% {
      opacity: 1;
      clip-path: circle(31% at 50% 50%);
    }
  }

  @keyframes animateBgCircles1 {
    0% {
      top: 50%;
      left: 50%;
      opacity: 1;
      transform: translate(-50%, -50%) scale(2.5);
    }
    20% {
      transform: translate(-50%, -50%) scale(1.45);
    }
    25% {
      transform: translate(-50%, -50%) scale(1.45);
    }
    41.675% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }
    62.5% {
      top: 60%;
      left: 70%;
      transform: translate(-50%, -50%) scale(0.75);
    }
    75% {
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.75);
    }
    87.5% {
      top: 50%;
      left: 40%;
      transform: translate(-50%, -50%) scale(0.85);
    }
    100% {
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes learnHowAnimation1 {
    0% {
      opacity: 0;
      bottom: -130px;
    }
    25% {
      bottom: 60px;
    }
    50% {
      bottom: 0px;
    }
    100% {
      opacity: 1;
      bottom: 60px;
    }
  }
  @keyframes learnHowAnimation2 {
    0% {
      width: 200px;
      height: 200px;
    }
    25% {
      width: 70px;
      height: 70px;
    }
    50% {
      width: 85px;
      height: 85px;
    }
    100% {
      width: 70px;
      height: 70px;
    }
  }
  @keyframes learnHowAnimation3 {
    0% {
      width: 90px;
      height: 90px;
    }
    25% {
      width: 45px;
      height: 45px;
    }
    50% {
      width: 60px;
      height: 60px;
    }
    100% {
      width: 45px;
      height: 45px;
    }
  }

  @media (max-width: 1900px) {
    .home-page-intro-wrapper {
      .home-page-intro {
        .home-page-intro-title {
          h1 {
            font-size: 75px;
            line-height: 98px;
          }
        }
        .home-page-intro-circles-wrapper {
          .home-page-intro-circles {
            &:nth-of-type(1) {
              width: 700px;

              height: 700px;
            }
            &:nth-of-type(2) {
              width: 900px;

              height: 900px;
            }
            &:nth-of-type(3) {
              width: 1100px;

              height: 1100px;
            }
            &:nth-of-type(4) {
              width: 1300px;

              height: 1300px;
            }
            &:nth-of-type(5) {
              width: 1500px;

              height: 1500px;
            }
            &:nth-of-type(6) {
              width: 1700px;

              height: 1700px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .home-page-intro-wrapper {
      .home-page-intro {
        .home-page-intro-title {
          h1 {
            font-size: 55px;
            line-height: 71px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .home-page-intro-wrapper {
      .home-page-intro {
        .home-page-intro-title {
          h1 {
            font-size: 40px;
            line-height: 51px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              animation: none;
              opacity: 1;
            }
          }
        }
        .home-page-intro-circles-wrapper {
          .home-page-intro-circles {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              opacity: 1;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(1);
              animation: none;
            }
            &:nth-of-type(1) {
              width: 300px;
              height: 300px;
            }
            &:nth-of-type(2) {
              width: 500px;
              height: 500px;
            }
            &:nth-of-type(3) {
              width: 700px;
              height: 700px;
            }
            &:nth-of-type(4) {
              width: 900px;
              height: 900px;
            }
            &:nth-of-type(5) {
              width: 1100px;
              height: 1100px;
            }
            &:nth-of-type(6) {
              width: 1300px;
              height: 1300px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .home-page-intro-wrapper {
      .home-page-intro {
        .home-page-intro-title {
          width: 90%;
          h1 {
            font-size: 35px;
            line-height: 45px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              animation: none;
              opacity: 1;
            }
          }
        }
        .home-page-intro-learn-btn {
          p {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 5px;
          }
          > span {
            transform: scale(0.8);
          }
        }
        .home-page-intro-circles-wrapper {
          .home-page-intro-circles {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              opacity: 1;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(1);
              animation: none;
            }
            &:nth-of-type(1) {
              width: 250px;
              height: 250px;
            }
            &:nth-of-type(2) {
              width: 350px;
              height: 350px;
            }
            &:nth-of-type(3) {
              width: 450px;
              height: 450px;
            }
            &:nth-of-type(4) {
              width: 550px;
              height: 550px;
            }
            &:nth-of-type(5) {
              width: 650px;
              height: 650px;
            }
            &:nth-of-type(6) {
              width: 750px;
              height: 750px;
            }
          }
        }
        .home-page-intro-learn-btn {
          animation: none;
          opacity: 1;
          bottom: 45px;
          > span {
            > span {
              animation: none;
              > span {
                animation: none;
              }
            }
          }
        }
      }
    }
  }
`;

const HomePageIntro = () => {
  const { setActiveSection, activeSection } = useContext(GlobalContext);

  const NextSection = (number) => {
    setActiveSection(number);
  };
  return (
    <HomePageIntroWrapper>
      <div className="home-page-intro-wrapper section">
        <div className="home-page-intro ">
          <div className="home-page-intro-title">
            <h1>
              A one-stop shop<br></br> that provides high-end software solutions
            </h1>
            <h1>
              A one-stop shop<br></br> that provides high-end software solutions
            </h1>
            <h1>
              A one-stop shop<br></br> that provides high-end software solutions
            </h1>
          </div>
          <div className="home-page-intro-circles-wrapper">
            <div className="home-page-intro-circles"></div>
            <div className="home-page-intro-circles"></div>
            <div className="home-page-intro-circles"></div>
            <div className="home-page-intro-circles"></div>
            <div className="home-page-intro-circles"></div>
            <div className="home-page-intro-circles"></div>
          </div>
          <div className="home-page-intro-learn-btn">
            <p onClick={() => NextSection(1)}>Learn how we can help</p>
            <span>
              <span>
                <span>
                  <ArrowDown size="20"></ArrowDown>
                </span>
              </span>
            </span>
          </div>
        </div>
        <div className="home-page-intro-bottom-fade"></div>
      </div>
    </HomePageIntroWrapper>
  );
};

export default HomePageIntro;
