import "./App.css";
import { GlobalProvider } from "./Consts/GlobalContext";
import GlobalStyle from "./Consts/GlobalStyle";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop.js";
import theme from "./Consts/Theme";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import TeamExtensionPage from "./Components/TeamExtension/TeamExtensionPage";
import CustomSolutionDevelopmentPage from "./Components/CustomSolutionDevelopment/CustomSolutionDevelopmentPage";
import ManagedServicesPage from "./Components/ManagedServices/ManagedServicesPage";
import AboutPage from "./Components/About/AboutPage";
import CareersPage from "./Components/CareersPage/CareersPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import BlogPage from "./Components/BlogPage/BlogPage";
import BlogSingleElementPage from "./Components/BlogSingleElementPage/BlogSingleElementPage";

function App() {
  return (
    <>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <ScrollToTop>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route
                  exact
                  path="/team-extension"
                  component={TeamExtensionPage}
                />
                <Route
                  exact
                  path="/custom-solution-development"
                  component={CustomSolutionDevelopmentPage}
                />
                <Route
                  exact
                  path="/managed-services"
                  component={ManagedServicesPage}
                />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/careers" component={CareersPage} />
                <Route exact path="/contact" component={ContactPage} />
                <Route exact path="/blog" component={BlogPage} />
                <Route exact path="/blog/1" component={BlogSingleElementPage} />
              </Switch>
            </ScrollToTop>
          </Router>
        </ThemeProvider>
      </GlobalProvider>
    </>
  );
}

export default App;
