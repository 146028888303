import React from "react";
import styled from "styled-components";
import profile from "../../img/partners/profile.svg";
import Footer from "../Footer/Footer";

const ContactPageSecondSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    background-color: #07111a;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 100%;
        .text {
          margin-bottom: 150px;
          text-align: center;
          h2 {
            ${(props) => props.theme.font_medium_48};
          }
          p {
            ${(props) => props.theme.font_regular_20};
            color: #fff;
            padding: 0 150px;
          }
        }
        .cards-wrapper {
          display: flex;
          .card {
            flex-basis: 25%;
            height: 230px;
            .card-inner {
              padding: 0 15px;
              height: 100%;

              .card-content {
                text-align: center;
                background: transparent
                  linear-gradient(
                    156deg,
                    #1f3040 0%,
                    #1f304065 98%,
                    #1f304063 100%
                  )
                  0% 0% no-repeat padding-box;
                box-shadow: 15px 15px 60px #000910bf;
                border-radius: 10px;
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 20px;
                &:before {
                  content: "";
                  width: 160px;
                  height: 160px;
                  border-radius: 50%;
                  background: linear-gradient(
                    0deg,
                    rgba(7, 17, 26, 1) 50%,
                    rgba(7, 17, 26, 0) 50%
                  );
                  position: absolute;
                  top: -80px;
                  left: 50%;
                  transform: translateX(-50%);
                }
                img {
                  width: 140px;
                  height: 140px;
                  position: absolute;
                  top: -70px;
                  left: 50%;
                  transform: translateX(-50%);
                }
                h5 {
                  ${(props) => props.theme.font_demibold_26};
                  color: ${(props) => props.theme.secondary_color};
                  margin-bottom: 20px;
                }
                p,
                span {
                  ${(props) => props.theme.font_regular_20};
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 250px;
      }
    }
  }
  @media (max-width: 1441px) {
    .wrapper {
      .inner {
        padding: 0 200px;
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 150px;
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .content {
          .text {
            margin-bottom: 125px;
            h2 {
              font-size: 40px;
              line-height: 52px;
            }
            p {
              font-size: 18px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .content {
          .text {
            margin-bottom: 125px;
            h2 {
              font-size: 40px;
              line-height: 52px;
            }
            p {
              font-size: 18px;
              line-height: 23px;
            }
          }
          .cards-wrapper {
            .card {
              height: 220px;
              .card-inner {
                .card-content {
                  h5 {
                    font-size: 24px;
                    line-height: 31px;
                  }
                  p,
                  span {
                    font-size: 16px;
                    line-height: 21px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .content {
          .text {
            margin-bottom: 50px;
            h2 {
              font-size: 26px;
              line-height: 33px;
            }
            p {
              font-size: 12px;
              line-height: 15px;
              padding: 0;
            }
          }
          .cards-wrapper {
            flex-wrap: wrap;
            margin: 0 -5px;
            row-gap: 45px;
            .card {
              flex-basis: 50%;
              height: 125px;
              .card-inner {
                padding: 0 5px;
                .card-content {
                  h5 {
                    font-size: 18px;
                    line-height: 23px;
                    margin-bottom: 6px;
                  }
                  p,
                  span {
                    font-size: 12px;
                    line-height: 15px;
                  }
                  img {
                    width: 50px;
                    height: 50px;
                    top: -25px;
                  }
                  &:before {
                    width: 70px;
                    height: 70px;
                    top: -35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ContactPageSecondSection = () => {
  return (
    <ContactPageSecondSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <div className="text">
              <h2>We are here to help you!</h2>
              <p>
                We will be happy to assist you and tell you more about our
                product and solutions. Just call us on +389 78 33 60 32 or send
                as an inquiry and one of our client consultants will get back to
                you.
              </p>
            </div>
            <div className="cards-wrapper">
              <div className="card">
                <div className="card-inner">
                  <div className="card-content">
                    <img src={profile}></img>
                    <h5>John Doe</h5>
                    <p>johndoe@icelabs.com </p>
                    <span>+123 456 789</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <div className="card-content">
                    <img src={profile}></img>
                    <h5>John Doe</h5>
                    <p>johndoe@icelabs.com </p>
                    <span>+123 456 789</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <div className="card-content">
                    <img src={profile}></img>
                    <h5>John Doe</h5>
                    <p>johndoe@icelabs.com </p>
                    <span>+123 456 789</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <div className="card-content">
                    <img src={profile}></img>
                    <h5>John Doe</h5>
                    <p>johndoe@icelabs.com </p>
                    <span>+123 456 789</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ContactPageSecondSectionWrapper>
  );
};

export default ContactPageSecondSection;
