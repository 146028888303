import React, { useEffect, useState } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";
import BottomFade from "../BottomFade/BottomFade";
import ContactForm from "../ContactForm/ContactForm";
import Footer from "../Footer/Footer";
import Menu from "../NavBar/Menu";
import PartnersSection from "../PartnersSection/PartnersSection";
import HomePageFirstSection from "./HomePageFirstSection";
import HomePageIntro from "./HomePageIntro";
import HomePageSecondSection from "./HomePageSecondSection";
import HomePageThirdSection from "./HomePageThirdSection";

const HomePageWrapper = styled.div`
  .mobile-off {
  }
`;

const HomePage = () => {
  const { setIsWheelEnabled, MouseWheelHandler, isMobileScreen } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!isMobileScreen) {
      setIsWheelEnabled(false);
      setTimeout(() => {
        setIsWheelEnabled(true);
      }, 12000);
    }
  }, []);

  return (
    <div
      onWheel={(e) => MouseWheelHandler(e)}
      onTouchStart={(e) => MouseWheelHandler(e)}
      onTouchEnd={(e) => MouseWheelHandler(e)}
    >
      <Menu />
      <HomePageIntro />
      <HomePageFirstSection />
      <HomePageSecondSection />
      <HomePageThirdSection />
      {!isMobileScreen && <PartnersSection />}
      <ContactForm />
      <BottomFade />
    </div>
  );
};

export default HomePage;
