import React from "react";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";
import Footer from "../Footer/Footer";

const BlogSubscribeSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 475px;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 100%;
        h2 {
          ${(props) => props.theme.font_medium_48};
          text-align: center;
        }
        .subscribe-wrapper {
          background: transparent
            linear-gradient(179deg, #1f3040 0%, #1f304029 100%) 0% 0% no-repeat
            padding-box;
          box-shadow: 25px 25px 40px #0009109e;
          border-radius: 10px;
          margin-top: 70px;
          padding: 100px 270px;
          position: relative;
          h4 {
            ${(props) => props.theme.font_regular_20};
            text-align: center;
            color: #fff;
            margin-bottom: 45px;
          }
          .circle-lines {
            position: absolute;
            top: -53px;
            left: 50%;
            transform: translatex(-50%);
            .down {
              transform: rotate(180deg);
              margin-top: 70px;
            }
            span {
              width: 18px;
              height: 18px;
              background-color: ${(props) => props.theme.secondary_color};
              display: block;
              border-radius: 50%;
              position: relative;
            }
            hr {
              border: 0;
              border-left: 2px solid #9bb3cf;
              margin: 0;
              height: 40px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 15px;
              z-index: -1;
            }
          }

          .form-group {
            margin-bottom: 30px;
            input {
              padding: 20px;
              background-color: transparent;
              border: 1px solid #9bb3cf;
              border-radius: 5px;
              color: #9bb3cf;
              display: block;
              width: 100%;
              ${(props) => props.theme.font_regular_18};

              &::placeholder {
                ${(props) => props.theme.font_regular_18};
                color: #9bb3cf;
              }
              &:focus {
                outline: 0;
                border-color: ${(props) => props.theme.secondary_color};
              }
            }
          }
          .form-end {
            .left {
              margin-bottom: 30px;
              display: flex;
              column-gap: 10px;
              align-items: center;
              flex-basis: 60%;
              input {
                margin: 0;
              }
              label {
                ${(props) => props.theme.font_regular_12}
              }
            }
            .right {
              text-align: right;
              > div {
                display: block;
                a {
                  padding: 15px 30px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 275px;
      }
    }
  }
  @media (max-width: 1441px) {
    .wrapper {
      .inner {
        padding: 0 250px;
        .content {
          h2 {
            font-size: 35px;
            line-height: 45px;
          }
          .subscribe-wrapper {
            padding: 75px 270px;
          }
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 200px;
      }
    }
  }

  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 200px;
        .content {
          h2 {
            font-size: 35px;
            line-height: 45px;
          }
          .subscribe-wrapper {
            padding: 50px 200px;
            margin-top: 55px;
            .circle-lines {
              top: -40px;
              hr {
                height: 25px;
              }
              .down {
                margin-top: 44px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 200px;
        .content {
          h2 {
            font-size: 35px;
            line-height: 45px;
          }
          .subscribe-wrapper {
            padding: 50px 100px;
            margin-top: 55px;
            .circle-lines {
              top: -40px;
              hr {
                height: 25px;
              }
              .down {
                margin-top: 44px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .content {
          h2 {
            font-size: 30px;
            line-height: 39px;
          }
          .subscribe-wrapper {
            padding: 20px;
            margin-top: 30px;
            h4 {
              font-size: 16px;
              line-height: 21px;
              margin-bottom: 20px;
            }
            .circle-lines {
              display: none;
            }
            .form-group {
              input {
                padding: 10px;
                font-size: 16px;
                line-height: 21px;
                &::placeholder {
                  font-size: 16px;
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BlogSubscribeSection = () => {
  return (
    <BlogSubscribeSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <h2>Receive the latest updates in your inbox</h2>
            <div className="subscribe-wrapper">
              <div className="circle-lines">
                <div className="up">
                  <span>
                    <hr />
                  </span>
                </div>
                <div className="down">
                  <span>
                    <hr />
                  </span>
                </div>
              </div>
              <h4>
                Want to receive Ice Labs news and updates from our blog? Join
                our mailing list here!
              </h4>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  required
                />
              </div>
              <div className="form-end">
                <div className="left">
                  <div>
                    <input type="checkbox" id="accept" name="accept" />
                  </div>

                  <label htmlFor="accept">
                    I accept that the information I provide is processed
                    according to Ice Labs privacy policy.
                  </label>
                </div>
                <div className="right">
                  <CustomButton title="Subscribe" to="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </BlogSubscribeSectionWrapper>
  );
};

export default BlogSubscribeSection;
