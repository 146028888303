import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import BottomFade from "../BottomFade/BottomFade";
import ContactForm from "../ContactForm/ContactForm";
import InfoSection from "../InfoSection/InfoSection";
import LatestBlogs from "../LatestBlogs/LatestBlogs";
import Menu from "../NavBar/Menu";
import PartnersSection from "../PartnersSection/PartnersSection";
import ManagedServicesPageFirstSection from "./ManagedServicesPageFirstSection";
import ManagedServicesPageIntro from "./ManagedServicesPageIntro";

const ManagedServicesPage = () => {
  const { setIsWheelEnabled, MouseWheelHandler, isMobileScreen } =
    useContext(GlobalContext);

  useEffect(() => {
    setIsWheelEnabled(false);
    setTimeout(() => {
      setIsWheelEnabled(true);
    }, 3000);
  }, []);
  return (
    <div
      onWheel={(e) => MouseWheelHandler(e)}
      onTouchStart={(e) => MouseWheelHandler(e)}
      onTouchEnd={(e) => MouseWheelHandler(e)}
    >
      <Menu />
      <ManagedServicesPageIntro />
      <ManagedServicesPageFirstSection />
      <InfoSection />
      <LatestBlogs />
      {!isMobileScreen && <PartnersSection />}
      <ContactForm />
      <BottomFade />
    </div>
  );
};

export default ManagedServicesPage;
