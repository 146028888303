const theme = {
  primary_color: "#07111A",
  secondary_color: "#55A4FF",
  font_bold_106: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "106px",
    "line-height": "130px",
    "font-weight": "700",
    color: `#fff`,
  },
  font_medium_48: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "48px",
    "line-height": "56px",
    "font-weight": "500",
    color: `#fff`,
  },
  font_medium_33: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "33px",
    "line-height": "41px",
    "font-weight": "500",
    color: `#fff`,
  },
  font_demibold_26: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "26px",
    "line-height": "35px",
    "font-weight": "600",
  },
  font_regular_26: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "26px",
    "line-height": "35px",
    "font-weight": "400",
  },
  font_medium_24: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "24px",
    "line-height": "32px",
    "font-weight": "500",
    color: `#fff`,
  },
  font_regular_20: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "20px",
    "line-height": "28px",
    "font-weight": "400",
  },
  font_regular_22: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "22px",
    "line-height": "29px",
    "font-weight": "400",
  },
  font_demibold_22: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "22px",
    "line-height": "29px",
    "font-weight": "600",
  },
  font_demibold_18: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "18px",
    "line-height": "25px",
    "font-weight": "600",
  },
  font_medium_18: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "18px",
    "line-height": "25px",
    "font-weight": "500",
  },
  font_regular_18: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "18px",
    "line-height": "25px",
    "font-weight": "400",
  },
  font_bold_18_muli: {
    "font-family": "Muli",
    "font-size": "18px",
    "line-height": "25px",
    "font-weight": "600",
  },
  font_regular_16: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "16px",
    "line-height": "22px",
    "font-weight": "400",
  },
  font_demibold_14: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "14px",
    "line-height": "19px",
    "font-weight": "600",
  },
  font_regular_14: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "14px",
    "line-height": "19px",
    "font-weight": "400",
  },
  font_regular_12_muli: {
    "font-family": "Muli",
    "font-size": "18px",
    "line-height": "25px",
    "font-weight": "600",
  },
  font_regular_12: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "12px",
    "line-height": "14px",
    "font-weight": "500",
    color: "#fff",
  },
  font_11: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "11px",
    "line-height": "15px",
    "font-weight": "400",
    color: `#fff`,
  },
  menu_links: {
    "font-family": "TT Commons, sans-serif",
    "font-size": "16px",
    "line-height": "21px",
    "font-weight": "600",
    color: "#9bb3cf",
  },
  language: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "18px",
    "line-height": "21px",
    "font-weight": "600",
    color: `#202945`,
  },
  log_in: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "18px",
    "line-height": "25px",
    "letter-spacing": "0.45px",
    "font-weight": "600",
    "text-transform": "uppercase",
    color: "#2ED9C3",
  },
  footer_14: {
    "font-family": "TT Commons,sans-serif",
    "font-size": "14px",
    "line-height": "19px",
    "font-weight": "500",
    "text-transform": "capitalize",
  },
};

export default theme;
