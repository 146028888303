import React from "react";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import image from "../../img/custom-solution-development/img.svg";

const CustomSolutionDevelopmentPageFirstSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 170px;
      display: flex;
      justify-content: center;
      align-items: end;
      text-align: center;
      > div {
        flex-grow: 1;
      }
      .image.active {
        animation: imageSectionAnimation1 2s ease-in-out forwards;
      }
      .image {
        margin-bottom: 150px;
        position: relative;
        transform: translatey(-450px);
        animation: imageSectionAnimation2 2s ease-in-out forwards;
        display: inline-block;

        img {
          width: 1000px;
          transform: translateY(200px);
          animation: mainImageAnimation 2s ease-in-out forwards;
        }
        img.image-animation {
          animation: mainImageSecondAnimation 2s ease-in-out forwards;
        }
        .rectangles.active {
          &:first-of-type {
            animation: rectangleSecondAnimation1 2s ease-in-out forwards;
          }
          &:nth-of-type(2) {
            animation: rectangleSecondAnimation2 2s ease-in-out forwards;
          }
          &:last-of-type {
            animation: rectangleSecondAnimation3 2s ease-in-out forwards;
          }
        }
        .rectangles {
          position: absolute;
          z-index: -1;
          border-radius: 10px;
          &:first-of-type {
            width: 1500px;
            height: 1000px;
            top: -275px;
            left: -260px;
            background: transparent
              linear-gradient(2deg, #1f3040 0%, #1f304029 100%) 0% 0% no-repeat
              padding-box;
            box-shadow: 25px 25px 40px #0009109e;
            opacity: 23%;
            animation: rectangleAnimation1 2s ease-in-out forwards;
          }
          &:nth-of-type(2) {
            width: 1600px;
            height: 1100px;
            top: -320px;
            left: -306px;
            background: transparent
              linear-gradient(360deg, #1f3040 0%, #1f304029 100%) 0% 0%
              no-repeat padding-box;
            box-shadow: 25px 25px 40px #0009109e;
            opacity: 34%;
            z-index: -2;
            animation: rectangleAnimation2 2s ease-in-out forwards;
          }
          &:last-of-type {
            width: 1700px;
            height: 1200px;
            z-index: -3;
            box-shadow: 25px 25px 40px #0009109e;
            background: transparent
              linear-gradient(1deg, #1f3040 0%, #1f304029 100%) 0% 0% no-repeat
              padding-box;
            opacity: 28%;
            animation: rectangleAnimation3 2s ease-in-out forwards;
          }
        }
      }
    }
  }

  @keyframes rectangleAnimation1 {
    0% {
      width: var(--rectangleAnimation1width1);
      height: var(--rectangleAnimation1height1);
      top: var(--rectangleAnimation1top1);
      left: var(--rectangleAnimation1left1);
    }
    100% {
      width: var(--rectangleAnimation1width2);
      height: var(--rectangleAnimation1height2);
      top: var(--rectangleAnimation1top2);
      left: var(--rectangleAnimation1left2);
    }
  }
  @keyframes rectangleAnimation2 {
    0% {
      width: var(--rectangleAnimation2width1);
      height: var(--rectangleAnimation2height1);
      top: var(--rectangleAnimation2top1);
      left: var(--rectangleAnimation2left1);
    }
    100% {
      width: var(--rectangleAnimation2width2);
      height: var(--rectangleAnimation2height2);
      top: var(--rectangleAnimation2top2);
      left: var(--rectangleAnimation2left2);
    }
  }
  @keyframes rectangleAnimation3 {
    0% {
      width: var(--rectangleAnimation3width1);
      height: var(--rectangleAnimation3height1);
      top: var(--rectangleAnimation3top1);
      left: var(--rectangleAnimation3left1);
    }
    100% {
      width: var(--rectangleAnimation3width2);
      height: var(--rectangleAnimation3height2);
      top: var(--rectangleAnimation3top2);
      left: var(--rectangleAnimation3left2);
    }
  }

  @keyframes rectangleSecondAnimation1 {
    0% {
      width: 1000px;
      height: 750px;
      top: -12px;
      left: 0;
    }
    100% {
      width: 800px;
      height: 550px;
      top: -12px;
      left: 0;
    }
  }
  @keyframes rectangleSecondAnimation2 {
    0% {
      width: 1100px;
      height: 850px;
      top: -70px;
      left: -45px;
    }
    100% {
      width: 900px;
      height: 650px;
      top: -70px;
      left: -45px;
    }
  }
  @keyframes rectangleSecondAnimation3 {
    0% {
      width: 1200px;
      height: 950px;
      top: -120px;
      left: -96px;
    }
    100% {
      width: 1000px;
      height: 750px;
      top: -120px;
      left: -96px;
    }
  }

  @keyframes mainImageSecondAnimation {
    0% {
      width: 1000px;
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0px);
      width: 800px;
    }
  }

  @keyframes mainImageAnimation {
    0% {
      transform: translateY(var(--mainImageSecondAnimationTransform));
      width: 800px;
    }
    100% {
      transform: translateY(0px);
      width: 1000px;
    }
  }

  @keyframes imageSectionAnimation1 {
    0% {
      transform: translateY(-450px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes imageSectionAnimation2 {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-450px);
    }
  }
  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0;
        .image {
          margin-bottom: 150px;
          transform: translatey(-350px);
        }
      }
    }
    @keyframes mainImageAnimation {
      0% {
        transform: translateY(var(--mainImageSecondAnimationTransform));
        width: 700px;
      }
      100% {
        transform: translateY(0px);
        width: 1000px;
      }
    }
    @keyframes mainImageSecondAnimation {
      0% {
        width: 1000px;
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
        width: 700px;
      }
    }
    @keyframes rectangleSecondAnimation1 {
      0% {
        width: 1000px;
        height: 750px;
        top: -12px;
        left: 0;
      }
      100% {
        width: 700px;
        height: 500px;
        top: -12px;
        left: 0;
      }
    }
    @keyframes rectangleSecondAnimation2 {
      0% {
        width: 1100px;
        height: 850px;
        top: -70px;
        left: -45px;
      }
      100% {
        width: 800px;
        height: 600px;
        top: -70px;
        left: -45px;
      }
    }
    @keyframes rectangleSecondAnimation3 {
      0% {
        width: 1200px;
        height: 950px;
        top: -120px;
        left: -96px;
      }
      100% {
        width: 900px;
        height: 700px;
        top: -120px;
        left: -96px;
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0;
        .image {
          margin-bottom: 150px;
          transform: translatey(-450px);
        }
      }
    }
    @keyframes mainImageAnimation {
      0% {
        transform: translateY(var(--mainImageSecondAnimationTransform));
        width: 700px;
      }
      100% {
        transform: translateY(0px);
        width: 850px;
      }
    }
    @keyframes mainImageSecondAnimation {
      0% {
        width: 850px;
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
        width: 700px;
      }
    }
    @keyframes rectangleSecondAnimation1 {
      0% {
        width: 850px;
        height: 600px;
        top: -12px;
        left: 0;
      }
      100% {
        width: 700px;
        height: 500px;
        top: -12px;
        left: 0;
      }
    }
    @keyframes rectangleSecondAnimation2 {
      0% {
        width: 950px;
        height: 700px;
        top: -70px;
        left: -45px;
      }
      100% {
        width: 800px;
        height: 600px;
        top: -70px;
        left: -45px;
      }
    }
    @keyframes rectangleSecondAnimation3 {
      0% {
        width: 1050px;
        height: 800px;
        top: -120px;
        left: -96px;
      }
      100% {
        width: 900px;
        height: 700px;
        top: -120px;
        left: -96px;
      }
    }

    @keyframes imageSectionAnimation1 {
      0% {
        transform: translateY(-350px);
      }
      100% {
        transform: translateY(0px);
      }
    }
    @keyframes imageSectionAnimation2 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-350px);
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0;
        .image {
          transform: translatey(-250px);
        }
      }
    }
    @keyframes mainImageAnimation {
      0% {
        transform: translateY(var(--mainImageSecondAnimationTransform));
        width: 550px;
      }
      100% {
        transform: translateY(0px);
        width: 850px;
      }
    }
    @keyframes mainImageSecondAnimation {
      0% {
        width: 850px;
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
        width: 550px;
      }
    }
    @keyframes rectangleSecondAnimation1 {
      0% {
        width: 850px;
        height: 600px;
        top: -12px;
        left: 0;
      }
      100% {
        width: 550px;
        height: 400px;
        top: -12px;
        left: 0;
      }
    }
    @keyframes rectangleSecondAnimation2 {
      0% {
        width: 950px;
        height: 700px;
        top: -70px;
        left: -45px;
      }
      100% {
        width: 650px;
        height: 500px;
        top: -70px;
        left: -45px;
      }
    }
    @keyframes rectangleSecondAnimation3 {
      0% {
        width: 1050px;
        height: 800px;
        top: -120px;
        left: -96px;
      }
      100% {
        width: 750px;
        height: 600px;
        top: -120px;
        left: -96px;
      }
    }

    @keyframes imageSectionAnimation1 {
      0% {
        transform: translateY(-250px);
      }
      100% {
        transform: translateY(0px);
      }
    }
    @keyframes imageSectionAnimation2 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-250px);
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0;
        .image {
          transform: translatey(-350px);
        }
      }
    }
    @keyframes mainImageAnimation {
      0% {
        transform: translateY(var(--mainImageSecondAnimationTransform));
        width: 550px;
      }
      100% {
        transform: translateY(0px);
        width: 750px;
      }
    }
    @keyframes mainImageSecondAnimation {
      0% {
        width: 750px;
        transform: translateY(0px);
      }
      100% {
        transform: translateY(0px);
        width: 550px;
      }
    }
    @keyframes rectangleSecondAnimation1 {
      0% {
        width: 750px;
        height: 500px;
        top: -12px;
        left: 0;
      }
      100% {
        width: 550px;
        height: 400px;
        top: -12px;
        left: 0;
      }
    }
    @keyframes rectangleSecondAnimation2 {
      0% {
        width: 850px;
        height: 600px;
        top: -70px;
        left: -45px;
      }
      100% {
        width: 650px;
        height: 500px;
        top: -70px;
        left: -45px;
      }
    }
    @keyframes rectangleSecondAnimation3 {
      0% {
        width: 950px;
        height: 700px;
        top: -120px;
        left: -96px;
      }
      100% {
        width: 750px;
        height: 600px;
        top: -120px;
        left: -96px;
      }
    }

    @keyframes imageSectionAnimation1 {
      0% {
        transform: translateY(-350px);
      }
      100% {
        transform: translateY(0px);
      }
    }
    @keyframes imageSectionAnimation2 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-350px);
      }
    }
  }
`;

const CustomSolutionDevelopmentPageFirstSection = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
  };
  const { ref: mainImageRef, inView: isVisible, entry } = useInView(options);

  return (
    <CustomSolutionDevelopmentPageFirstSectionWrapper ref={mainImageRef}>
      <div className="wrapper section">
        <div className="inner">
          <div>
            <div className={isVisible ? "image active" : "image"}>
              <img
                src={image}
                className={isVisible ? "image-animation" : ""}
              ></img>
              <div
                className={isVisible ? "rectangles active" : "rectangles"}
              ></div>
              <div
                className={isVisible ? "rectangles active" : "rectangles"}
              ></div>
              <div
                className={isVisible ? "rectangles active" : "rectangles"}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </CustomSolutionDevelopmentPageFirstSectionWrapper>
  );
};

export default CustomSolutionDevelopmentPageFirstSection;
