import React from "react";
import styled from "styled-components";

const LatestBlogsCardWrapper = styled.div`
  flex-basis: 33%;
  height: 100%;
  cursor: pointer;
  .card-inner {
    height: 100%;
    .card-content {
      height: 100%;
      border-radius: 10px;
      border: 2px solid #2c3b4a;
      padding: 35px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px;
        z-index: -1;
        background: linear-gradient(
          135deg,
          rgba(12, 29, 45, 0.7987570028011204) 0%,
          rgba(44, 59, 74, 1) 70%
        );
        opacity: 0;
        transition: all 0.6s ease-out;
      }
      .category {
        display: block;
        ${(props) => props.theme.font_demibold_14};
        color: #9bb3cf;
        margin-bottom: 15px;
      }
      h4 {
        ${(props) => props.theme.font_demibold_26};
        color: #fff;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        height: 169px;
        border-radius: 5px;
        margin-bottom: 20px;
        display: block;
      }
      p {
        ${(props) => props.theme.font_regular_16};
        color: #fff;
        margin-bottom: 40px;
      }
      .read-time {
        ${(props) => props.theme.font_regular_14};
        color: #9bb3cf;
      }
    }
  }
  &:hover {
    .card-inner {
      .card-content {
        box-shadow: 40px 40px 60px #00000075;
        &:before {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .card-inner {
      .card-content {
        .category {
          font-size: 13px;
          line-height: 17px;
        }
        h4 {
          font-size: 24px;
          line-height: 32px;
        }
        p {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .card-inner {
      .card-content {
        .category {
          font-size: 12px;
          line-height: 15px;
        }
        h4 {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 10px;
        }
        img {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .card-inner {
      .card-content {
        .category {
          font-size: 10px;
          line-height: 13px;
        }
        h4 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 10px;
        }
        img {
          margin-bottom: 10px;
          height: 150px;
        }
        p {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .card-inner {
      .card-content {
        img {
          height: 130px;
        }
      }
    }
  }

  @media (max-width: 426px) {
    flex-basis: 100%;
    &:last-child {
      display: none;
    }
    &:only-child {
      display: block;
    }
    .card-inner {
      .card-content {
        padding: 10px;
        .category {
          font-size: 8px;
          line-height: 10px;
          margin-bottom: 5px;
        }
        h4 {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 5px;
        }
        img {
          height: 120px;
        }
        p {
          font-size: 11px;
          line-height: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (max-width: 360px) {
    .card-inner {
      .card-content {
        img {
          height: 100px;
        }
      }
    }
  }
`;

const LatestBlogsCard = ({ title, text, category, readTime, img }) => {
  return (
    <LatestBlogsCardWrapper>
      <div className="card-inner">
        <div className="card-content">
          <span className="category">{category}</span>
          <h4>{title}</h4>
          <img src={img}></img>
          <p>{text}</p>
          <span className="read-time">{readTime}</span>
        </div>
      </div>
    </LatestBlogsCardWrapper>
  );
};

export default LatestBlogsCard;
