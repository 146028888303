import React from "react";
import styled from "styled-components";
import people from "../../img/info-section/people.svg";
import centeredcircles from "../../img/info-section/centered-circles.svg";
import circles from "../../img/info-section/circles.svg";
const InfoSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .bg-circle1 {
      position: absolute;
      width: 1000px;
      height: 1000px;
      border: 1px solid #9bb3cf;
      border-radius: 50%;
      background-color: transparent;
      right: -470px;
      top: -150px;
    }
    .bg-circle2 {
      position: absolute;
      width: 2900px;
      height: 2900px;
      border: 1px solid #9bb3cf;
      border-radius: 50%;
      background-color: transparent;
      right: -470px;
      top: 300px;
      &:before {
        content: "";
        width: 55px;
        height: 55px;
        position: absolute;
        right: 640px;
        top: 200px;
        background-color: ${(props) => props.theme.secondary_color};
        border-radius: 50%;
      }
    }
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: end;
      > div {
        margin-bottom: 25px;
      }
      h2 {
        ${(props) => props.theme.font_medium_48};
        margin-bottom: 30px;
        text-align: center;
      }
      .cards-wrapper {
        .card {
          background-color: #162432;
          display: flex;
          border-radius: 10px;
          opacity: 0.8;
          .card-left {
            flex-basis: 20%;
            height: 225px;
            position: relative;
            img {
              position: absolute;
              bottom: 0;
              left: 0;
              height: 100%;
            }
          }
          .card-right {
            margin: auto 0;
            padding: 0 25px;
            h5 {
              ${(props) => props.theme.font_demibold_26};
              color: ${(props) => props.theme.secondary_color};
              margin-bottom: 10px;
            }
            p {
              ${(props) => props.theme.font_regular_20};
              color: #9bb3cf;
            }
          }
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
        > div {
          margin-bottom: 50px;
        }
        .cards-wrapper {
          .card {
            .card-left {
              height: 190px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        h2 {
          font-size: 40px;
          line-height: 52px;
        }
        .cards-wrapper {
          .card {
            .card-left {
              height: 160px;
            }
            .card-right {
              h5 {
                font-size: 24px;
                line-height: 31px;
              }
              p {
                font-size: 18px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        h2 {
          font-size: 35px;
          line-height: 45px;
        }
        .cards-wrapper {
          .card {
            .card-left {
              height: 140px;
            }
            .card-right {
              h5 {
                font-size: 24px;
                line-height: 31px;
              }
              p {
                font-size: 18px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .cards-wrapper {
          .card {
            .card-right {
              h5 {
                font-size: 22px;
                line-height: 28px;
              }
              p {
                font-size: 16px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        align-items: center;
        > div {
          margin-bottom: 0;
        }
        h2 {
          font-size: 25px;
          line-height: 33px;
          margin-bottom: 15px;
        }
        .cards-wrapper {
          .card {
            .card-left {
              height: 150px;
              position: relative;
              z-index: 1;
            }
            .card-right {
              padding: 0 10px 0 25px;
              position: relative;
              z-index: 2;
              h5 {
                font-size: 18px;
                line-height: 23px;
              }
              p {
                font-size: 15px;
                line-height: 19px;
              }
            }
          }
        }
      }
    }
  }
`;

const InfoSection = () => {
  return (
    <InfoSectionWrapper>
      <div className="wrapper section">
        <div className="bg-circle1"></div>
        <div className="bg-circle2"></div>
        <div className="inner">
          <div>
            <h2>Why ice labs</h2>
            <div className="cards-wrapper">
              <div className="card">
                <div className="card-left">
                  <img src={people} alt="img"></img>
                </div>
                <div className="card-right">
                  <h5>Handpicked tech professionals</h5>
                  <p>
                    Our teams are equipped with diverse strengths, interests,
                    and engineering backgrounds to offer truly versatile
                    software development services.
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-left">
                  <img src={centeredcircles} alt="img"></img>
                </div>
                <div className="card-right">
                  <h5>Handpicked tech professionals</h5>
                  <p>
                    Our teams are equipped with diverse strengths, interests,
                    and engineering backgrounds to offer truly versatile
                    software development services.
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="card-left">
                  <img src={circles} alt="img"></img>
                </div>
                <div className="card-right">
                  <h5>Handpicked tech professionals</h5>
                  <p>
                    Our teams are equipped with diverse strengths, interests,
                    and engineering backgrounds to offer truly versatile
                    software development services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfoSectionWrapper>
  );
};

export default InfoSection;
