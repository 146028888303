import React from "react";
import styled from "styled-components";

const AboutPageThirdSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);

    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        width: 100%;
      }
      h2 {
        ${(props) => props.theme.font_medium_48};
        text-align: center;
        margin-bottom: 65px;
      }
      .card-wrapper {
        display: flex;
        margin: 0 -15px;
        .card {
          flex-basis: 25%;
          .card-inner {
            padding: 0 15px;
            h3 {
              ${(props) => props.theme.font_demibold_26};
              color: ${(props) => props.theme.secondary_color};
              padding: 25px 30px 25px 45px;
              background-color: #111f2b;
              box-shadow: 18px 18px 40px #07111a;
              border-radius: 150px;
            }
            ul {
              padding-left: 45px;
              margin-top: 30px;
              ${(props) => props.theme.font_regular_20};
              color: #9bb3cf;
              li {
                &:not(:last-child) {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 100px;
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        h2 {
          font-size: 40px;
          line-height: 52px;
          margin-bottom: 45px;
        }
        .card-wrapper {
          .card {
            .card-inner {
              h3 {
                font-size: 22px;
                line-height: 28px;
              }
              ul {
                font-size: 18px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        h2 {
          font-size: 40px;
          line-height: 52px;
        }
        .card-wrapper {
          .card {
            .card-inner {
              h3 {
                font-size: 20px;
                line-height: 26px;
                padding: 25px 30px 25px 35px;
              }
              ul {
                font-size: 18px;
                line-height: 23px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        h2 {
          font-size: 30px;
          line-height: 39px;
          margin-bottom: 20px;
        }
        .card-wrapper {
          flex-wrap: wrap;

          .card {
            flex-basis: 50%;
            .card-inner {
              h3 {
                font-size: 18px;
                line-height: 23px;
                padding: 15px;
                text-align: center;
              }
              ul {
                font-size: 14px;
                line-height: 18px;
                padding-left: 30px;
                margin-top: 15px;
                li {
                  &:not(:last-child) {
                    margin-bottom: 8px;
                  }
                }
              }
            }
            &:not(:nth-child(n + 3)) {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 376px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        h2 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        .card-wrapper {
          flex-wrap: wrap;

          .card {
            flex-basis: 50%;
            .card-inner {
              h3 {
                font-size: 16px;
                line-height: 21px;
                padding: 10px;
                text-align: center;
              }
              ul {
                font-size: 14px;
                line-height: 18px;
                padding-left: 30px;
                margin-top: 10px;
                li {
                  &:not(:last-child) {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            &:not(:nth-child(n + 3)) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
`;

const AboutPageThirdSection = () => {
  return (
    <AboutPageThirdSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div>
            <h2>A selection of our technology stack:</h2>
            <div className="card-wrapper">
              <div className="card">
                <div className="card-inner">
                  <h3>Frontend Technologies</h3>
                  <ul>
                    <li>Angular</li>
                    <li>React (Redux)</li>
                    <li>Vue</li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <h3>Backend Technologies</h3>
                  <ul>
                    <li>Node (Express)</li>
                    <li>Java EE</li>
                    <li>Spring</li>
                    <li>Nest.js</li>
                    <li>Next.js</li>
                    <li>Strapi</li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <h3>Mobile Technologies</h3>
                  <ul>
                    <li>Kotlin</li>
                    <li>Swift</li>
                    <li>Java</li>
                    <li>ObjectiveC</li>
                    <li>React Native</li>
                    <li>Ionic</li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="card-inner">
                  <h3>BI & AWS Technologies</h3>
                  <ul>
                    <li>AWS Aurora</li>
                    <li>AWS EMR</li>
                    <li>AWS Kinesis</li>
                    <li>AWS RedShift</li>
                    <li>Pentaho</li>
                    <li>Python</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AboutPageThirdSectionWrapper>
  );
};

export default AboutPageThirdSection;
