import React from "react";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";

const HomePageSecondSectionWrapper = styled.div`
  .home-page-second-section-wrapper {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 375px;
    .bg-circle {
      position: absolute;
      width: 1000px;
      height: 1000px;
      border: 1px solid #9bb3cf;
      border-radius: 50%;
      background-color: transparent;
      left: -470px;
      top: 565px;
      &:before {
        content: "";
        width: 55px;
        height: 55px;
        position: absolute;
        right: 305px;
        bottom: 0;
        background-color: ${(props) => props.theme.secondary_color};
        border-radius: 50%;
      }
      &:after {
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        right: -9px;
        top: 467px;
        background-color: ${(props) => props.theme.secondary_color};
        border-radius: 50%;
      }
    }
    .home-page-second-section-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .home-page-second-section-inner-left {
        flex-basis: 40%;
        h3 {
          ${(props) => props.theme.font_medium_48};
          margin-bottom: 75px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: ${(props) => props.theme.secondary_color};
            border-radius: 50%;
            top: 80px;
            left: 0;
          }
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          &:last-of-type {
            margin: 35px 0;
          }
        }
      }
      .home-page-second-section-inner-right {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .circle-wrapper {
          transform: rotate(45deg);
          transition: all 0.8s ease-out;
          &:hover {
            transform: rotate(180deg);
            .circle {
              span {
                transform: rotate(-180deg);
              }
            }
          }
          .circle {
            position: relative;
            width: 430px;
            height: 215px;
            span {
              transition: all 0.8s ease-out;

              color: White;
              transform: rotate(-45deg);
              display: block;
              position: absolute;
              z-index: 1;
              width: 30%;
              text-align: center;
              ${(props) => props.theme.font_regular_20};
            }
            &:first-of-type {
              border-top-left-radius: 215px;
              border-top-right-radius: 215px;
              border-bottom: 0;
              background: linear-gradient(
                -90deg,
                rgba(0, 0, 0, 1) 0%,
                rgba(85, 164, 255, 1) 100%
              );
              span {
                bottom: -25px;
                left: -12px;
              }
              &:before {
                content: "";
                width: 110px;
                height: 110px;
                background-color: #000;
                border-radius: 50%;
                position: absolute;
                bottom: -55px;
                right: 0;
                border: 1px solid ${(props) => props.theme.secondary_color};
                z-index: 1;
              }
            }
            &:last-of-type {
              border-bottom-left-radius: 215px;
              border-bottom-right-radius: 215px;
              border-top: 0;
              background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 1) 0%,
                rgba(85, 164, 255, 1) 100%
              );
              span {
                right: -7px;
                top: -33px;
              }
              &:before {
                content: "";
                width: 110px;
                height: 110px;
                background-color: #000;
                border-radius: 50%;
                position: absolute;
                top: -55px;
                left: 0;
                border: 1px solid ${(props) => props.theme.secondary_color};
              }
              &:after {
                content: "";
                width: 210px;
                height: 210px;
                background-color: #000;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                top: -105px;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .home-page-second-section-wrapper {
      padding: 0 150px;
    }
  }
  @media (max-width: 1441px) {
    .home-page-second-section-wrapper {
      padding: 0 100px;
      .bg-circle {
        &:before {
          right: 450px;
          bottom: -26px;
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .home-page-second-section-wrapper {
      padding: 0 50px;
      .home-page-second-section-inner {
        .home-page-second-section-inner-left {
          h3 {
            margin-bottom: 35px;
            font-size: 42px;
            line-height: 54px;
            &:before {
              display: none;
            }
          }
          p {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .home-page-second-section-wrapper {
      padding: 0 25px;
    }
  }
  @media (max-width: 769px) {
    .home-page-second-section-wrapper {
      .home-page-second-section-inner {
        .home-page-second-section-inner-left {
          flex-basis: 48%;
          h3 {
            font-size: 30px;
            line-height: 39px;
            margin-bottom: 25px;
          }
          p {
            font-size: 16px;
            line-height: 20px;
            &:last-of-type {
              margin: 20px 0;
            }
          }
        }
        .home-page-second-section-inner-right {
          .circle-wrapper {
            .circle {
              position: relative;
              width: 350px;
              height: 175px;
              span {
                font-size: 18px;
                line-height: 23px;
              }
              &:first-of-type {
                span {
                  bottom: -24px;
                  left: -4px;
                }
                &:before {
                  width: 100px;
                  height: 100px;
                  bottom: -50px;
                }
              }
              &:last-of-type {
                span {
                  right: -2px;
                  top: -24px;
                }
                &:before {
                  width: 100px;
                  height: 100px;
                  top: -50px;
                }
                &:after {
                  width: 150px;
                  height: 150px;
                  top: -75px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .home-page-second-section-wrapper {
      .home-page-second-section-inner {
        display: block;
        .home-page-second-section-inner-left {
          flex-basis: 48%;
          h3 {
            font-size: 26px;
            line-height: 33px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            &:last-of-type {
              margin: 10px 0;
            }
          }
        }
        .home-page-second-section-inner-right {
          justify-content: center;
          .circle-wrapper {
            .circle {
              position: relative;
              width: 250px;
              height: 125px;
              span {
                font-size: 13px;
                line-height: 17px;
              }
              &:first-of-type {
                span {
                  bottom: -16px;
                  left: -4px;
                }
                &:before {
                  width: 70px;
                  height: 70px;
                  bottom: -35px;
                }
              }
              &:last-of-type {
                span {
                  right: -2px;
                  top: -18px;
                }
                &:before {
                  width: 70px;
                  height: 70px;
                  top: -35px;
                }
                &:after {
                  width: 110px;
                  height: 110px;
                  top: -55px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const HomePageSecondSection = () => {
  return (
    <HomePageSecondSectionWrapper>
      <div className="home-page-second-section-wrapper section">
        <div className="bg-circle"></div>
        <div className="home-page-second-section-inner">
          <div className="home-page-second-section-inner-left">
            <h3>Who we are</h3>
            <p>
              Ice Labs is a trusted custom solution development and technology
              consulting company, headquartered in Munich, Germany and with
              delivery centers in Germany and Eastern Europe.
            </p>
            <p>
              Since our inception, we have delivered innovative software
              solutions to companies and enterprises worldwide that partner with
              us for our core engineering, profound integration capabilities and
              technology consulting. Our vast technology and industry expertise
              help our clients across multiple business domains to deliver
              successful and scalable products.
            </p>
            <CustomButton title="Find out more" />
          </div>
          <div className="home-page-second-section-inner-right">
            <div className="circle-wrapper">
              <div className="circle">
                <span>leading solutions</span>
              </div>
              <div className="circle">
                <span>solving problems</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HomePageSecondSectionWrapper>
  );
};

export default HomePageSecondSection;
