import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import cog from "../../img/managed-services/cog.svg";

const ManagedServicesPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      .first-cog {
        position: absolute;
        width: 530px;
        height: 530px;
        bottom: -50px;
        left: 525px;
        animation: FirstCogAnimation 4s ease-in forwards;
      }
      .second-cog {
        position: absolute;
        right: 555px;
        bottom: -320px;
        transform: rotate(10deg);
        animation: SecondCogAnimation 4s ease-in forwards;
      }
      .first-cog.active {
        animation: FirstCogAnimation2 1s ease-in forwards;
      }
      .second-cog.active {
        animation: SecondCogAnimation2 1s ease-in forwards;
      }
      .text {
        transform: translateY(-120px);
        animation: TextAnimation 4s ease-in forwards;
        h1 {
          ${(props) => props.theme.font_bold_106};
          margin-bottom: 20px;
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          padding: 0 325px;
          &:first-of-type {
            margin-bottom: 25px;
          }
        }
      }
      .text.active {
        animation: TextAnimation2 1s ease-in forwards;
      }
    }
  }

  @keyframes TextAnimation {
    0% {
      transform: translateY(-120px);
    }
    40% {
      transform: translateY(0px);
    }
    60% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(120px);
    }
  }
  @keyframes TextAnimation2 {
    0% {
      transform: translateY(120px);
    }
    100% {
      transform: translateY(-120px);
    }
  }
  @keyframes FirstCogAnimation {
    0% {
      bottom: var(--managedServicesFirstCogAnimationBottom1);
      transform: rotate(0deg);
    }
    40% {
      bottom: var(--managedServicesFirstCogAnimationStatic);
      transform: rotate(45deg);
    }
    60% {
      bottom: var(--managedServicesFirstCogAnimationStatic);
      transform: rotate(45deg);
    }
    100% {
      bottom: var(--managedServicesFirstCogAnimationBottom2);
      transform: rotate(0deg);
    }
  }
  @keyframes FirstCogAnimation2 {
    0% {
      bottom: 650px;
      transform: rotate(0deg);
    }
    100% {
      bottom: 1150px;
      transform: rotate(45deg);
    }
  }

  @keyframes SecondCogAnimation {
    0% {
      bottom: var(--managedServicesSecondCogAnimationBottom1);
      transform: rotate(10deg);
    }
    40% {
      bottom: var(--managedServicesSecondCogAnimationStatic);
      transform: rotate(45deg);
    }
    60% {
      bottom: var(--managedServicesSecondCogAnimationStatic);
      transform: rotate(45deg);
    }
    100% {
      bottom: var(--managedServicesSecondCogAnimationBottom2);
      transform: rotate(0deg);
    }
  }
  @keyframes SecondCogAnimation2 {
    0% {
      bottom: 450px;
      transform: rotate(0deg);
    }
    100% {
      bottom: 850px;
      transform: rotate(45deg);
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        .text {
          h1 {
            font-size: 90px;
            line-height: 115px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
            padding: 0 200px;
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        .first-cog {
          left: 400px;
        }
        .second-cog {
          right: 550px;
        }
        .text {
          h1 {
            font-size: 82px;
            line-height: 106px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
            padding: 0 100px;
          }
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        .first-cog {
          left: 450px;
        }
        .second-cog {
          right: 500px;
        }
      }
    }
    @keyframes FirstCogAnimation2 {
      0% {
        bottom: 450px;
        transform: rotate(0deg);
      }
      100% {
        bottom: 1150px;
        transform: rotate(45deg);
      }
    }
    @keyframes SecondCogAnimation2 {
      0% {
        bottom: 300px;
        transform: rotate(0deg);
      }
      100% {
        bottom: 850px;
        transform: rotate(45deg);
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .first-cog {
          left: 450px;
        }
        .second-cog {
          right: 250px;
        }
        .text {
          h1 {
            font-size: 75px;
            line-height: 97px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .first-cog {
          left: 70px;
        }
        .second-cog {
          right: -15px;
        }
        .text {
          h1 {
            font-size: 45px;
            line-height: 58px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            padding: 0;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
`;

const ManagedServicesPageIntro = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
  };
  const {
    ref: cogsAnimationRef,
    inView: isVisible,
    entry,
  } = useInView(options);

  return (
    <ManagedServicesPageIntroWrapper ref={cogsAnimationRef}>
      <div className="wrapper section">
        <div className="inner">
          <img
            className={isVisible ? "first-cog " : "first-cog active"}
            src={cog}
          ></img>
          <div className={isVisible ? "text " : "text active"}>
            <h1>Managed Services</h1>
            <p>
              We have the skills to support and manage your business processes,
              by providing reliable and cost-effective technology support
              services. We strive to simplify the complexity of operational and
              support systems.
            </p>
            <p>
              Rely on our extensive knowledge to keep your business processes
              secure and protected or resolve any technical issue and challenge,
              while you focus on achieving your business objectives.
            </p>
          </div>
          <img
            className={isVisible ? "second-cog" : "second-cog active"}
            src={cog}
          ></img>
        </div>
      </div>
    </ManagedServicesPageIntroWrapper>
  );
};

export default ManagedServicesPageIntro;
