import React, { useEffect } from "react";
import styled from "styled-components";
import CustomCard from "../CustomCard/CustomCard";
import improve from "../../img/managed-services/improve.svg";
import wrench from "../../img/managed-services/wrench.svg";
import security from "../../img/managed-services/security.svg";
import building from "../../img/managed-services/building.svg";
import { useInView } from "react-intersection-observer";
import { GlobalContext } from "../../Consts/GlobalContext";
import { useContext } from "react";

const ManagedServicesPageFirstSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      h2 {
        ${(props) => props.theme.font_medium_48};
        margin-bottom: 80px;
      }
      .cards-wrapper {
        display: flex;
        margin: 0 -15px;
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;

        h2 {
          font-size: 40px;
          line-height: 52px;
          margin-bottom: 75px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        padding: 0 100px;
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        h2 {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 35px;
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        h2 {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 35px;
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        h2 {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .cards-wrapper {
          flex-wrap: wrap;
          margin: 0 -5px;
          row-gap: 10px;
        }
      }
    }
  }
`;

const ManagedServicesPageFirstSection = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
    triggerOnce: true,
  };
  const { ref: introCogsRef, inView: tempView, entry } = useInView(options);
  const { isMobileScreen } = useContext(GlobalContext);
  useEffect(() => {
    if (tempView) {
      let r = document.querySelector(":root");
      if (window.innerWidth < 1367) {
        r.style.setProperty(
          "--managedServicesSecondCogAnimationBottom2",
          "300px"
        );
        r.style.setProperty(
          "--managedServicesFirstCogAnimationBottom2",
          "450px"
        );
        r.style.setProperty(
          "--managedServicesSecondCogAnimationBottom1",
          "850px"
        );
        r.style.setProperty(
          "--managedServicesSecondCogAnimationStatic",
          "450px"
        );
        r.style.setProperty(
          "--managedServicesFirstCogAnimationBottom1",
          "1150px"
        );
        r.style.setProperty(
          "--managedServicesFirstCogAnimationStatic",
          "650px"
        );
      } else {
        r.style.setProperty(
          "--managedServicesSecondCogAnimationBottom1",
          "850px"
        );
        r.style.setProperty(
          "--managedServicesSecondCogAnimationBottom2",
          "450px"
        );
        r.style.setProperty(
          "--managedServicesSecondCogAnimationStatic",
          "450px"
        );
        r.style.setProperty(
          "--managedServicesFirstCogAnimationBottom1",
          "1150px"
        );
        r.style.setProperty(
          "--managedServicesFirstCogAnimationBottom2",
          "650px"
        );
        r.style.setProperty(
          "--managedServicesFirstCogAnimationStatic",
          "650px"
        );
      }
    }
  }, [tempView]);
  return (
    <ManagedServicesPageFirstSectionWrapper ref={introCogsRef}>
      <div className="wrapper section">
        <div className="inner">
          <div>
            <h2>What we offer</h2>
            <div className="cards-wrapper">
              <CustomCard
                title="InfraOps"
                text="We leverage our expertise in IT infrastructure management and streamline all resources effectively. We merge, standardize, automate, and virtualize business systems to create and deliver flexible, efficient, and low-cost IT infrastructure management services."
                isArrow={false}
                img={improve}
              />
              <CustomCard
                title="AppOps"
                text="Application Support and Maintenance are crucial components in reducing and preventing downtimes. Intertec is committed to providing 24/7 support for your business-critical applications and ensuring smooth operations."
                isArrow={false}
                img={wrench}
              />
              <CustomCard
                title="SecOps"
                text="Powered by years of professional experience in operations and maintenance, we have the skills to simplify your operations and support systems. Deploy our team to oversee and operate day-to-day services and keep your business secure and protected."
                isArrow={false}
                img={security}
              />
              <CustomCard
                title="ProServices"
                text="Project Management; Information Transformation Services; Technical and Business Consulting; Service Level Management"
                isArrow={false}
                img={building}
              />
            </div>
          </div>
        </div>
      </div>
    </ManagedServicesPageFirstSectionWrapper>
  );
};

export default ManagedServicesPageFirstSection;
