import React, { useState } from "react";
import styled from "styled-components";

const CareersPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 475px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .intro {
        transform: translateY(100px);
        transition: all 0.8s ease-in-out;

        h1 {
          ${(props) => props.theme.font_bold_106};
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          opacity: 0;
          transition: all 0.8s ease-in-out;
        }
        .switch-animation-wrapper {
          background-color: #1a2a39;
          height: 265px;
          border-radius: 170px;
          width: 650px;
          margin: 60px auto;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          z-index: 1;
          .switch-animation-inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 20%;
            border-radius: 170px;
            background-color: transparent;
            transition: all 0.8s ease-in-out;
            z-index: 2;
          }
          .switch-animation-circle {
            position: absolute;
            left: 0%;
            transform: translateX(0%);
            top: 0%;
            width: 265px;
            height: 100%;
            background-color: #1a2a39;
            border-radius: 50%;
            box-shadow: 18px 18px 40px #07111a8c;
            transition: all 0.8s ease-in-out;
            overflow: hidden;
            z-index: 3;
          }
        }
      }
      .intro.active {
        transform: translatey(0);
        p {
          opacity: 1;
        }
        .switch-animation-wrapper {
          .switch-animation-inner {
            background-color: ${(props) => props.theme.secondary_color};
            width: 100%;
          }
          .switch-animation-circle {
            left: 100%;
            transform: translateX(-100%);
            background-color: ${(props) => props.theme.secondary_color};
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 375px;
        .intro {
          .switch-animation-wrapper {
            margin: 40px auto;
          }
        }
      }
    }
  }

  @media (max-width: 1537px) {
    .wrapper {
      .inner {
        padding: 0 150px;
        .intro {
          h1 {
            font-size: 80px;
            line-height: 104px;
          }
          .switch-animation-wrapper {
            margin: 30px auto;
            height: 240px;
            width: 580px;
            .switch-animation-circle {
              width: 240px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .intro {
          h1 {
            font-size: 80px;
            line-height: 104px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
          }
          .switch-animation-wrapper {
            margin: 30px auto;
            height: 220px;
            width: 500px;
            .switch-animation-circle {
              width: 220px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .intro {
          h1 {
            font-size: 40px;
            line-height: 52px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
          }
          .switch-animation-wrapper {
            margin: 25px auto;
            height: 115px;
            width: 250px;
            .switch-animation-circle {
              width: 115px;
            }
          }
        }
      }
    }
  }
`;

const CareersPageIntro = () => {
  const [switchAnimation, setSwitchAnimation] = useState(false);

  const handleSwitchAnimation = (e) => {
    setSwitchAnimation(!switchAnimation);
  };

  return (
    <CareersPageIntroWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className={switchAnimation ? "intro active" : "intro"}>
            <h1>Start your career with icelabs</h1>
            <div
              className={
                switchAnimation
                  ? "switch-animation-wrapper active"
                  : "switch-animation-wrapper"
              }
              onClick={(e) => handleSwitchAnimation(e)}
            >
              <div className="switch-animation-inner"></div>
              <div className="switch-animation-circle"></div>
            </div>
            <p className={switchAnimation ? "active" : "false"}>
              At Icelabs, you can build a dynamic career in your field of
              interest, work with professionals to gain industry insight and
              have exposure to international projects as well as enjoy on-going
              learning and development opportunities.
            </p>
          </div>
        </div>
      </div>
    </CareersPageIntroWrapper>
  );
};

export default CareersPageIntro;
