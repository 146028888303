import React from "react";
import styled from "styled-components";
import laptop from "../../img/latest-blogs/laptop.png";

const BlogSingleElementPageIntroWrapper = styled.div`
  .wrapper {
    height: 100vh;
    position: relative;
    .inner {
      height: 100%;
      padding: 0 550px;
      display: flex;
      justify-content: center;
      align-items: end;
      .content {
        margin-bottom: 45px;
        width: 100%;
        p {
          ${(props) => props.theme.font_demibold_14};
          color: #9bb3cf;
        }
        h2 {
          ${(props) => props.theme.font_medium_48};
          margin: 15px 0 40px;
        }
        span {
          color: #9bb3cf;
          ${(props) => props.theme.font_regular_14};
          display: block;
          margin-bottom: 15px;
        }
        img {
          width: 100%;
          display: block;
          height: 500px;
          border-radius: 7px;
        }
      }
    }
  }
`;

const BlogSingleElementPageIntro = () => {
  return (
    <BlogSingleElementPageIntroWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <p>SOFTWARE DEVELOPMENT</p>
            <h2>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            </h2>
            <span>10 min read</span>
            <img src={laptop}></img>
          </div>
        </div>
      </div>
    </BlogSingleElementPageIntroWrapper>
  );
};

export default BlogSingleElementPageIntro;
