import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import bulb from "../../img/team-extension/bulb.svg";
import cogs from "../../img/team-extension/cogs.svg";
import rocket from "../../img/team-extension/rocket.svg";
import headphones from "../../img/team-extension/headphones.svg";
import CustomCard from "../CustomCard/CustomCard";

const TeamExtensionPageFirstSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      .page-title {
        ${(props) => props.theme.font_medium_48};
        text-align: center;
        margin-bottom: 95px;
      }
      .cards-wrapper {
        display: flex;
        margin: 0 -15px;
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;

        .page-title {
          font-size: 40px;
          line-height: 52px;
          margin-bottom: 75px;
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        padding: 0 100px;
      }
    }
  }

  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .page-title {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 35px;
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .page-title {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 35px;
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        .page-title {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .cards-wrapper {
          flex-wrap: wrap;
          margin: 0 -5px;
          row-gap: 10px;
        }
      }
    }
  }
`;

const TeamExtensionPageFirstSection = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
    triggerOnce: true,
  };
  const { ref: tempRef, inView: tempView, entry } = useInView(options);

  useEffect(() => {
    console.log(tempView);
    if (tempView) {
      let r = document.querySelector(":root");
      r.style.setProperty("--circle1AnimationTopFirst", "825px");
      r.style.setProperty("--circle1AnimationTopSecond", "-825px");
      r.style.setProperty("--circle2AnimationTopFirst", "570px");
      r.style.setProperty("--circle2AnimationTopSecond", "-1080px");
    }
  }, [tempView]);
  return (
    <TeamExtensionPageFirstSectionWrapper ref={tempRef}>
      <div className="wrapper section">
        <div className="inner">
          <div>
            <h2 className="page-title">Our Process</h2>
            <div className="cards-wrapper">
              <CustomCard
                img={bulb}
                title="Discovery phase"
                text="We identify your business needs"
                isArrow={true}
              />
              <CustomCard
                img={cogs}
                title="Setup phase"
                text="We begin our matching process for potentially suitable candidates within our own ranks as well as in our talent development program, whereby you get to participate in conducting the interviews."
                isArrow={true}
              />
              <CustomCard
                img={rocket}
                title="Launch phase"
                text="We have a team of professionals ready to put your solution in motion."
                isArrow={true}
              />
              <CustomCard
                img={headphones}
                title="Ramp-up phase"
                text="We provide continuous support to help you scale your team up efficiently."
                isArrow={true}
              />
            </div>
          </div>
        </div>
      </div>
    </TeamExtensionPageFirstSectionWrapper>
  );
};

export default TeamExtensionPageFirstSection;
