import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import BottomFade from "../BottomFade/BottomFade";
import ContactForm from "../ContactForm/ContactForm";
import ContactFormCareers from "../ContactFormCareers/ContactFormCareers";
import Menu from "../NavBar/Menu";
import CareersPageFirstSection from "./CareersPageFirstSection";
import CareersPageIntro from "./CareersPageIntro";
import CareersPageSecondSection from "./CareersPageSecondSection";
import CareersPageSecondSectionPart2 from "./CareersPageSecondSectionPart2";

const CareersPage = () => {
  const { setIsWheelEnabled, MouseWheelHandler, isMobileScreen } =
    useContext(GlobalContext);

  useEffect(() => {
    setIsWheelEnabled(false);
    setTimeout(() => {
      setIsWheelEnabled(true);
    }, 3000);
  }, []);
  return (
    <div
      onWheel={(e) => MouseWheelHandler(e)}
      onTouchStart={(e) => MouseWheelHandler(e)}
      onTouchEnd={(e) => MouseWheelHandler(e)}
    >
      <Menu />
      <CareersPageIntro />
      <CareersPageFirstSection />
      {!isMobileScreen && <CareersPageSecondSection />}
      {!isMobileScreen && <CareersPageSecondSectionPart2 />}
      <ContactFormCareers />
      <BottomFade />
    </div>
  );
};

export default CareersPage;
