import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import LogoWhite from "../../img/logo-white.svg";
import { KeyboardArrowDown } from "@styled-icons/material-rounded/KeyboardArrowDown";

const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 45px 90px 0;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(7, 17, 26, 1) 100%
  );
  .menu-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-logo {
      .menu-logo-link {
        display: block;
        img {
          display: block;
        }
      }
    }

    .menu-links {
      ul {
        display: flex;
        list-style-type: none;
        li {
          width: 108px;
          position: relative;
          .menu-progresion {
            width: 100%;
            height: 2px;
            background: #9bb3cf;
            border-radius: 8px;
            position: relative;
            margin-bottom: 20px;
            .progresion-bar {
              height: 4px;
              border-radius: 8px;
              background: #55a4ff;
              width: 10%;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              transition: width 0.2s linear;
            }
          }

          &:not(:last-child) {
            margin-right: 30px;
          }

          a,
          p {
            ${(props) => props.theme.menu_links};
            display: inline-block;
            text-decoration: none;
          }
          p {
            cursor: pointer;
            display: flex;
            align-items: center;
            span {
              display: inline-block;
              transform: rotate(-180deg);
              transition: all 0.8s ease-in-out;
              svg {
                display: block;
              }
            }
          }
          .active-link {
            color: #55a4ff;
          }

          .sub-menu-wrapper {
            padding-top: 20px;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            max-height: 100px;
            transition: all 0.8s ease-in-out;
            .sub-menu {
              max-height: 100px;
              display: block;
              background: linear-gradient(
                180deg,
                rgba(31, 48, 64, 1) 0%,
                rgba(31, 48, 64, 0.9136029411764706) 100%
              );
              box-shadow: 25px 25px 40px #000910bf;
              border-radius: 10px;
              padding: 25px;
              transition: all 0.8s ease-in-out;

              li {
                width: auto;

                a {
                  color: #9bb3cf;
                  ${(props) => props.theme.font_regular_16};
                  width: 140px;
                }
                &:not(:last-child) {
                  margin-right: 0;
                  border-bottom: 1px solid #9bb3cf;
                  a {
                    margin-bottom: 20px;
                  }
                }
                &:not(:first-child) {
                  a {
                    margin-top: 20px;
                  }
                }

                &:hover {
                  a {
                    color: ${(props) => props.theme.secondary_color};
                  }
                }
              }
            }
          }
          &:hover {
            p,
            a {
              color: ${(props) => props.theme.secondary_color};
            }
            p {
              span {
                transform: rotate(0deg);
              }
            }
            .sub-menu-wrapper {
              opacity: 1;
              visibility: visible;
              max-height: 350px;
              .sub-menu {
                max-height: 350px;
              }
            }
          }
        }
      }
    }

    .menu-buttons {
      display: flex;
      column-gap: 20px;
    }
  }

  @media (max-width: 1900px) {
    padding: 25px 65px 0;

    .menu-inner {
      .menu-links {
        ul {
          li {
            width: 90px;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1441px) {
    padding: 25px 25px 0;
    .menu-inner {
      .menu-links {
        ul {
          li {
            width: 80px;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    padding: 25px 15px 0;
  }
  @media (max-width: 1279px) {
    .menu-inner {
      .menu-links {
        display: none;
      }
      .menu-buttons {
        display: none;
      }
    }
  }
  @media (max-width: 426px) {
    padding: 10px 15px 0;
    .menu-inner {
      .menu-logo {
        .menu-logo-link {
          img {
            width: 80px;
          }
        }
      }
    }
  }
`;

const Menu = () => {
  const [activePage, setActivePage] = useState(0);

  const MenuProgressionFn = () => {
    window.addEventListener("scroll", (e) => {
      var winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      var height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      if (scrolled <= 10) {
        scrolled = 10;
      }
      if (document.querySelector(".progresion-bar")) {
        document.querySelector(".progresion-bar").style.width = scrolled + "%";
      }
    });
  };
  const activeMenuFn = () => {
    let locationHash = window.location.pathname.split("/")[1];
    if (locationHash === "") {
      setActivePage("");
    } else if (
      locationHash === "services" ||
      locationHash === "team-extension" ||
      locationHash === "custom-solution-development" ||
      locationHash === "managed-services"
    ) {
      setActivePage("services");
    } else if (locationHash === "about") {
      setActivePage("about");
    } else if (locationHash === "careers") {
      setActivePage("careers");
    } else if (locationHash === "contact") {
      setActivePage("contact");
    } else if (locationHash === "blog") {
      setActivePage("blog");
    }
  };
  useEffect(() => {
    MenuProgressionFn();
    activeMenuFn();
  }, [window.location.pathname]);
  return (
    <MenuWrapper>
      <div className="menu-inner">
        <div className="menu-logo">
          <Link to="/" className="menu-logo-link">
            <img src={LogoWhite} alt="icelabs"></img>
          </Link>
        </div>
        <div className="menu-links">
          <ul>
            <li>
              <div className="menu-progresion">
                {activePage === "" ? (
                  <div className="progresion-bar"></div>
                ) : (
                  ""
                )}
              </div>
              <Link className={activePage === "" ? "active-link" : ""} to="/">
                Home
              </Link>
            </li>
            <li>
              <div className="menu-progresion">
                {activePage === "services" ? (
                  <div className="progresion-bar"></div>
                ) : (
                  ""
                )}
              </div>
              <p className={activePage === "services" ? "active-link" : ""}>
                Services
                <span>
                  <KeyboardArrowDown size={20} />
                </span>
              </p>
              <div className="sub-menu-wrapper">
                <ul className="sub-menu">
                  <li>
                    <Link to="team-extension">Team Extension</Link>
                  </li>
                  <li>
                    <Link to="custom-solution-development">
                      Custom Solution Development
                    </Link>
                  </li>
                  <li>
                    <Link to="managed-services">Managed Services</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="menu-progresion">
                {activePage === "blog" ? (
                  <div className="progresion-bar"></div>
                ) : (
                  ""
                )}
              </div>
              <Link
                className={activePage === "blog" ? "active-link" : ""}
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li>
              <div className="menu-progresion">
                {activePage === "about" ? (
                  <div className="progresion-bar"></div>
                ) : (
                  ""
                )}
              </div>
              <Link
                className={activePage === "about" ? "active-link" : ""}
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <div className="menu-progresion">
                {activePage === "careers" ? (
                  <div className="progresion-bar"></div>
                ) : (
                  ""
                )}
              </div>
              <Link
                className={activePage === "careers" ? "active-link" : ""}
                to="/careers"
              >
                Careers
              </Link>
            </li>
            <li>
              <div className="menu-progresion">
                {activePage === "contact" ? (
                  <div className="progresion-bar"></div>
                ) : (
                  ""
                )}
              </div>
              <Link
                className={activePage === "contact" ? "active-link" : ""}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="menu-buttons">
          <CustomButton title="Request partnership" />
          <CustomButton title="Start a career" colorTheme="dark" />
        </div>
      </div>
    </MenuWrapper>
  );
};

export default Menu;
