import React from "react";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

const CustomSolutionDevelopmentPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .text {
        animation: textAnimation 2s ease-in-out forwards;
        h1 {
          ${(props) => props.theme.font_bold_106};
          margin-bottom: 20px;
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          padding: 0 325px;
          &:first-of-type {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  @keyframes textAnimation {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-150px);
    }
  }
  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .text {
          h1 {
            font-size: 90px;
            line-height: 115px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
            padding: 0 200px;
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    .wrapper {
      .inner {
        .text {
          h1 {
            font-size: 75px;
            line-height: 97px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 50px;
        .text {
          h1 {
            font-size: 65px;
            line-height: 85px;
          }
          p {
            font-size: 15px;
            line-height: 19px;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    @keyframes textAnimation {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-100px);
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 25px;
        .text {
          h1 {
            font-size: 60px;
            line-height: 78px;
          }
          p {
            font-size: 15px;
            line-height: 19px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .text {
          h1 {
            font-size: 45px;
            line-height: 58px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            padding: 0;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 376px) {
    .wrapper {
      .inner {
        .text {
          h1 {
            font-size: 40px;
            line-height: 52px;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            padding: 0;
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    @keyframes textAnimation {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-50px);
      }
    }
  }
`;

const CustomSolutionDevelopmentPageIntro = () => {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.9,
    initialInView: true,
  };
  const { ref: mainImageRef, inView: isVisible, entry } = useInView(options);

  useEffect(() => {
    let r = document.querySelector(":root");
    if (!isVisible) {
      r.style.setProperty("--rectangleAnimation1top1", "-12px");
      r.style.setProperty("--rectangleAnimation1left1", "0px");
      r.style.setProperty("--rectangleAnimation1top2", "-12px");
      r.style.setProperty("--rectangleAnimation1left2", "0px");

      r.style.setProperty("--rectangleAnimation2top1", "-70px");
      r.style.setProperty("--rectangleAnimation2left1", "-45px");
      r.style.setProperty("--rectangleAnimation2top2", "-70px");
      r.style.setProperty("--rectangleAnimation2left2", "-45px");

      r.style.setProperty("--rectangleAnimation3top1", "-120px");
      r.style.setProperty("--rectangleAnimation3left1", "-96px");
      r.style.setProperty("--rectangleAnimation3top2", "-120px");
      r.style.setProperty("--rectangleAnimation3left2", "-96px");

      r.style.setProperty("--mainImageSecondAnimationTransform", "0px");
      if (window.innerWidth < 1025) {
        r.style.setProperty("--rectangleAnimation1width1", "550px");
        r.style.setProperty("--rectangleAnimation1height1", "400px");

        r.style.setProperty("--rectangleAnimation1width2", "750px");
        r.style.setProperty("--rectangleAnimation1height2", "500px");

        r.style.setProperty("--rectangleAnimation2width1", "650px");
        r.style.setProperty("--rectangleAnimation2height1", "500px");

        r.style.setProperty("--rectangleAnimation2width2", "850px");
        r.style.setProperty("--rectangleAnimation2height2", "600px");

        r.style.setProperty("--rectangleAnimation3width1", "750px");
        r.style.setProperty("--rectangleAnimation3height1", "600px");

        r.style.setProperty("--rectangleAnimation3width2", "950px");
        r.style.setProperty("--rectangleAnimation3height2", "700px");
      } else if (window.innerWidth < 1281) {
        r.style.setProperty("--rectangleAnimation1width1", "550px");
        r.style.setProperty("--rectangleAnimation1height1", "400px");

        r.style.setProperty("--rectangleAnimation1width2", "850px");
        r.style.setProperty("--rectangleAnimation1height2", "600px");

        r.style.setProperty("--rectangleAnimation2width1", "650px");
        r.style.setProperty("--rectangleAnimation2height1", "500px");

        r.style.setProperty("--rectangleAnimation2width2", "950px");
        r.style.setProperty("--rectangleAnimation2height2", "700px");

        r.style.setProperty("--rectangleAnimation3width1", "750px");
        r.style.setProperty("--rectangleAnimation3height1", "600px");

        r.style.setProperty("--rectangleAnimation3width2", "1050px");
        r.style.setProperty("--rectangleAnimation3height2", "800px");
      } else if (window.innerWidth < 1367) {
        r.style.setProperty("--rectangleAnimation1width1", "700px");
        r.style.setProperty("--rectangleAnimation1height1", "500px");

        r.style.setProperty("--rectangleAnimation1width2", "850px");
        r.style.setProperty("--rectangleAnimation1height2", "600px");

        r.style.setProperty("--rectangleAnimation2width1", "800px");
        r.style.setProperty("--rectangleAnimation2height1", "600px");

        r.style.setProperty("--rectangleAnimation2width2", "950px");
        r.style.setProperty("--rectangleAnimation2height2", "700px");

        r.style.setProperty("--rectangleAnimation3width1", "900px");
        r.style.setProperty("--rectangleAnimation3height1", "700px");

        r.style.setProperty("--rectangleAnimation3width2", "1050px");
        r.style.setProperty("--rectangleAnimation3height2", "800px");
      } else if (window.innerWidth < 1441) {
        r.style.setProperty("--rectangleAnimation1width1", "700px");
        r.style.setProperty("--rectangleAnimation1height1", "500px");

        r.style.setProperty("--rectangleAnimation1width2", "1000px");
        r.style.setProperty("--rectangleAnimation1height2", "750px");

        r.style.setProperty("--rectangleAnimation2width1", "800px");
        r.style.setProperty("--rectangleAnimation2height1", "600px");

        r.style.setProperty("--rectangleAnimation2width2", "1100px");
        r.style.setProperty("--rectangleAnimation2height2", "850px");

        r.style.setProperty("--rectangleAnimation3width1", "900px");
        r.style.setProperty("--rectangleAnimation3height1", "700px");

        r.style.setProperty("--rectangleAnimation3width2", "1200px");
        r.style.setProperty("--rectangleAnimation3height2", "950px");
      } else {
        r.style.setProperty("--rectangleAnimation1width1", "800px");
        r.style.setProperty("--rectangleAnimation1height1", "550px");
        r.style.setProperty("--rectangleAnimation1width2", "1000px");
        r.style.setProperty("--rectangleAnimation1height2", "750px");

        r.style.setProperty("--rectangleAnimation2width1", "900px");
        r.style.setProperty("--rectangleAnimation2height1", "650px");
        r.style.setProperty("--rectangleAnimation2width2", "1100px");
        r.style.setProperty("--rectangleAnimation2height2", "850px");

        r.style.setProperty("--rectangleAnimation3width1", "1000px");
        r.style.setProperty("--rectangleAnimation3height1", "750px");
        r.style.setProperty("--rectangleAnimation3width2", "1200px");
        r.style.setProperty("--rectangleAnimation3height2", "950px");
      }
    }
  }, [isVisible]);
  return (
    <CustomSolutionDevelopmentPageIntroWrapper ref={mainImageRef}>
      <div className="wrapper section">
        <div className="inner">
          <div className="text">
            <h1>Custom Solution Development</h1>
            <p>
              We drive real business value through our custom solution model
              that offers innovative and cost-effective software development
              solutions to complex challenges. We strive not to not lose sight
              of the bigger picture and always pay attention to the end user‘s
              desires and needs.
            </p>
            <p>
              Trust Ice Labs' engineering team to maintain and oversee all
              stages of the development cycle - from the initial stages to
              post-delivery support with a robust Agile development methodology.
            </p>
          </div>
        </div>
      </div>
    </CustomSolutionDevelopmentPageIntroWrapper>
  );
};

export default CustomSolutionDevelopmentPageIntro;
