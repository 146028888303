import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import styled from "styled-components";

const AboutPageSliderWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      h2 {
        position: absolute;
        left: 430px;
        top: 285px;
        ${(props) => props.theme.font_medium_48};
        z-index: 2;
      }
      .circle1,
      .circle2,
      .circle3 {
        position: absolute;
        border-radius: 50%;
        background-color: ${(props) => props.theme.secondary_color};
      }
      .circle1 {
        left: 766px;
        top: 560px;
        width: 40px;
        height: 40px;
        animation: circle1Animation0 0.8s ease-in-out forwards;
      }
      .circle2 {
        left: 1131px;
        top: 402px;
        width: 22px;
        height: 22px;
        z-index: 2;
        animation: circle2Animation0 0.8s ease-in-out forwards;
      }

      .circle3 {
        left: 1556px;
        top: 478px;
        width: 10px;
        height: 10px;
        z-index: 2;
        // animation: circle3Animation0 0.8s ease-in-out forwards;
      }
      .swiper-wrapper {
        align-items: center;
        .swiper-slide {
          background-color: #fff;
          aspect-ratio: 1/1;
          border-radius: 50%;
          background-color: transparent;
          border: 1px solid #9bb3cf;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.8s ease-in-out;
          cursor: pointer;
          position: relative;
          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 0.8s ease-in-out;
            ${(props) => props.theme.font_demibold_26};
            color: ${(props) => props.theme.secondary_color};
          }
        }
        .swiper-slide-active {
          width: 388px !important;
          height: 388px;
          background: transparent
            linear-gradient(135deg, #1f3040 0%, #1f304000 100%) 0% 0% no-repeat
            padding-box;
          // box-shadow: 18px 18px 40px #07111a;
          border: 0;
          span {
            opacity: 1;
          }
        }
      }
    }
  }

  @keyframes circle1Animation0 {
    0% {
      left: 526px;
      top: 644px;
    }
    100% {
      left: 766px;
      top: 560px;
    }
  }

  @keyframes circle1Animation1 {
    0% {
      left: 766px;
      top: 560px;
    }
    100% {
      left: 687px;
      top: 636px;
    }
  }
  @keyframes circle1Animation2 {
    0% {
      left: 687px;
      top: 636px;
    }
    100% {
      left: 455px;
      top: 595px;
    }
  }
  @keyframes circle1Animation3 {
    0% {
      left: 455px;
      top: 595px;
    }
    100% {
      left: 526px;
      top: 644px;
    }
  }

  @keyframes circle2Animation0 {
    0% {
      left: 1173px;
      top: 570px;
    }
    100% {
      left: 1131px;
      top: 402px;
    }
  }

  @keyframes circle2Animation1 {
    0% {
      left: 1131px;
      top: 402px;
    }
    100% {
      left: 1101px;
      top: 472px;
    }
  }
  @keyframes circle2Animation2 {
    0% {
      left: 1101px;
      top: 472px;
    }
    100% {
      left: 1049px;
      top: 472px;
    }
  }
  @keyframes circle2Animation3 {
    0% {
      left: 1049px;
      top: 472px;
    }
    100% {
      left: 1173px;
      top: 570px;
    }
  }

  @keyframes circle3Animation0 {
    0% {
      left: 1636px;
      top: 409px;
    }
    100% {
      left: 1556px;
      top: 478px;
    }
  }

  @keyframes circle3Animation1 {
    0% {
      left: 1556px;
      top: 478px;
    }
    100% {
      left: 1516px;
      top: 400px;
    }
  }
  @keyframes circle3Animation2 {
    0% {
      left: 1516px;
      top: 400px;
    }
    100% {
      left: 1645px;
      top: 558px;
    }
  }
  @keyframes circle3Animation3 {
    0% {
      left: 1645px;
      top: 558px;
    }
    100% {
      left: 1636px;
      top: 409px;
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 0 0 20px;
        h2 {
          left: 295px;
        }
        .circle1,
        .circle2,
        .circle3 {
          display: none;
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        h2 {
          left: 320px;
          top: 210px;
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        .swiper-wrapper {
          .swiper-slide-active {
            width: 220px !important;
            height: 220px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0;
        h2 {
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          top: 132px;
          font-size: 36px;
          line-height: 46px;
        }
        .swiper-wrapper {
          .swiper-slide {
            span {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 376px) {
    .wrapper {
      .inner {
        .swiper-wrapper {
          .swiper-slide-active {
            width: 170px !important;
            height: 170px;
          }
          .swiper-slide {
            span {
              font-size: 16px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
`;

const AboutPageSlider = () => {
  let i = 1;
  const swiperSliderAnimation = (actvIndx) => {
    if (i === 4) {
      i = 1;
      document.querySelector(
        ".circle1"
      ).style.animation = `circle1Animation0 0.8s ease-in forwards`;
      document.querySelector(
        ".circle2"
      ).style.animation = `circle2Animation0 0.8s ease-in forwards`;
      document.querySelector(
        ".circle3"
      ).style.animation = `circle3Animation0 0.8s ease-in forwards`;
    } else {
      document.querySelector(
        ".circle1"
      ).style.animation = `circle1Animation${i} 0.8s ease-in forwards`;
      document.querySelector(
        ".circle2"
      ).style.animation = `circle2Animation${i} 0.8s ease-in forwards`;
      document.querySelector(
        ".circle3"
      ).style.animation = `circle3Animation${i} 0.8s ease-in forwards`;
      i++;
    }
  };

  return (
    <AboutPageSliderWrapper>
      <div className="wrapper section">
        <div className="inner">
          <h2>We partner with</h2>
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="circle3"></div>
          <Swiper
            spaceBetween={50}
            slidesPerView={6.5}
            breakpoints={{
              1600: {
                spaceBetween: 50,
              },
              1440: {
                spaceBetween: 30,
              },
              1366: {
                slidesPerView: 5.5,
                slidesOffsetBefore: 272,
              },
              1280: {
                slidesPerView: 5,
                slidesOffsetBefore: 272,
                spaceBetween: 55,
              },
              1024: {
                slidesPerView: 5,
                slidesOffsetBefore: 232,
                spaceBetween: 55,
              },
              300: {
                slidesPerView: 2.5,
                slidesOffsetBefore: 100,
                spaceBetween: 20,
              },
            }}
            loop={true}
            allowTouchMove={false}
            slideToClickedSlide={true}
            slidesOffsetBefore={252}
            onRealIndexChange={(swiper) =>
              swiperSliderAnimation(swiper.activeIndex)
            }
          >
            <SwiperSlide>
              <span>Enterprises</span>
            </SwiperSlide>
            <SwiperSlide>
              <span>Innovative companies</span>
            </SwiperSlide>
            <SwiperSlide>
              <span>Startups</span>
            </SwiperSlide>
            <SwiperSlide>
              <span>Scaleups</span>
            </SwiperSlide>
            <SwiperSlide>
              <span>IT Solution Providers</span>
            </SwiperSlide>
            <SwiperSlide>
              <span>Innovative companies</span>
            </SwiperSlide>
            <SwiperSlide>
              <span>Innovative companies</span>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </AboutPageSliderWrapper>
  );
};

export default AboutPageSlider;
