import React from "react";
import styled from "styled-components";
import { KeyboardArrowRight } from "@styled-icons/material-rounded/KeyboardArrowRight";

const CareersPageSecondSectionPart2Wrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 275px;
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        width: 100%;
        h2 {
          ${(props) => props.theme.font_medium_48};
          text-align: center;
          margin-bottom: 25px;
        }
        .intro {
          border-top: 1px solid #9bb3cf;
          border-bottom: 1px solid #9bb3cf;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 50px;
          .intro-left,
          .intro-right {
            padding: 45px 0;
            p {
              ${(props) => props.theme.font_demibold_26};
              color: ${(props) => props.theme.secondary_color};
            }
          }
          .intro-left {
            padding-left: 100px;
          }

          .intro-right {
            padding-right: 100px;
          }
        }
        .card-wrapper {
          padding: 0 100px;
          .card {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 110px;
            .card-left,
            .card-right {
              flex-basis: 35%;
              p {
                ${(props) => props.theme.font_demibold_26};
                color: #fff;
                margin-bottom: 15px;
              }
              span {
                display: block;
                ${(props) => props.theme.font_regular_16};
                color: #9bb3cf;
              }
            }

            .card-middle {
              border-radius: 50%;
              cursor: pointer;
              &:before {
                content: "";
                width: 148px;
                height: 148px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
              }
              &:after {
                content: "";
                width: 148px;
                height: 148px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
              }
              &:hover {
                background-color: #13202d;
                &:before {
                  background-color: #13202d;
                  opacity: 0.35;
                  width: 182px;
                  height: 182px;
                }
                &:after {
                  width: 222px;
                  height: 222px;
                  background-color: #13202d;
                  opacity: 0.13;
                }
              }
              > span {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #9bb3cf;
                border-radius: 50%;
                width: 148px;
                height: 148px;
                ${(props) => props.theme.font_bold_106};
                font-size: 70px;
                line-height: 84px;
                z-index: 1;
                background: linear-gradient(
                  0deg,
                  rgba(7, 17, 26, 0) 0%,
                  rgba(85, 164, 255, 1) 70%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
            .card-right {
              text-align: right;
            }
            &:not(:last-child) {
              margin-bottom: 50px;
            }
            .arrow {
              position: absolute;
              bottom: -40px;
              left: 50%;
              transform: translateX(-50%) rotate(90deg);
              color: ${(props) => props.theme.secondary_color};
            }
          }
        }
      }
    }
  }
  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
      }
    }
  }
  @media (max-width: 1537px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .content {
          .card-wrapper {
            padding: 0;
          }
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        align-items: flex-end;
        .content {
          margin-bottom: 25px;
          .card-wrapper {
            padding: 0;
            .card {
              &:not(:last-child) {
                margin-bottom: 30px;
              }
              .arrow {
                bottom: -30px;
              }
              .card-left,
              .card-right {
                p {
                  font-size: 24px;
                  line-height: 31px;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        .content {
          margin-bottom: 25px;
          .card-wrapper {
            padding: 0;
            .card {
              &:not(:last-child) {
                margin-bottom: 30px;
              }
              .arrow {
                bottom: -30px;
              }
              .card-left,
              .card-right {
                p {
                  font-size: 20px;
                  line-height: 26px;
                }
                span {
                  font-size: 15px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .content {
          .card-wrapper {
            .card {
              column-gap: 75px;
            }
          }
        }
      }
    }
  }
`;

const CareersPageSecondSectionPart2 = () => {
  return (
    <CareersPageSecondSectionPart2Wrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <div className="card-wrapper">
              <div className="card">
                <div className="card-left">
                  <p>Where do I begin?</p>
                  <span>
                    Take a look at our open positions. Once you find a role that
                    interests you, the first step is to complete your
                    application. Fulfill the form, leave your CV, submit all
                    your data or if any of the open positions doesn’t suit your
                    profile, send us your CV at recruitment@intertec.io. Once we
                    receive your application we will send you an email
                    confirmation.
                  </span>
                </div>
                <div className="card-middle">
                  <span>3</span>
                </div>
                <div className="card-right">
                  <p>Why Intertec?</p>
                  <span>
                    Students enrolling as interns work side-by-side with
                    Intertec engineers specializing in Java, Javascript,
                    AngularJS, ReactJS, AWS, Data Warehouses, ETL, AI, Machine
                    Learning, AWS SysOps Administration, DevOps, Manual and
                    Automated testing.
                  </span>
                </div>
                <span className="arrow">
                  <KeyboardArrowRight size={32} />
                </span>
              </div>
              <div className="card">
                <div className="card-left">
                  <p>Where do I begin?</p>
                  <span>
                    Take a look at our open positions. Once you find a role that
                    interests you, the first step is to complete your
                    application. Fulfill the form, leave your CV, submit all
                    your data or if any of the open positions doesn’t suit your
                    profile, send us your CV at recruitment@intertec.io. Once we
                    receive your application we will send you an email
                    confirmation.
                  </span>
                </div>
                <div className="card-middle">
                  <span>4</span>
                </div>
                <div className="card-right">
                  <p>Why Intertec?</p>
                  <span>
                    Students enrolling as interns work side-by-side with
                    Intertec engineers specializing in Java, Javascript,
                    AngularJS, ReactJS, AWS, Data Warehouses, ETL, AI, Machine
                    Learning, AWS SysOps Administration, DevOps, Manual and
                    Automated testing.
                  </span>
                </div>
                <span className="arrow">
                  <KeyboardArrowRight size={32} />
                </span>
              </div>
              <div className="card">
                <div className="card-left">
                  <p>Where do I begin?</p>
                  <span>
                    Take a look at our open positions. Once you find a role that
                    interests you, the first step is to complete your
                    application. Fulfill the form, leave your CV, submit all
                    your data or if any of the open positions doesn’t suit your
                    profile, send us your CV at recruitment@intertec.io. Once we
                    receive your application we will send you an email
                    confirmation.
                  </span>
                </div>
                <div className="card-middle">
                  <span>5</span>
                </div>
                <div className="card-right">
                  <p>Why Intertec?</p>
                  <span>
                    Students enrolling as interns work side-by-side with
                    Intertec engineers specializing in Java, Javascript,
                    AngularJS, ReactJS, AWS, Data Warehouses, ETL, AI, Machine
                    Learning, AWS SysOps Administration, DevOps, Manual and
                    Automated testing.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CareersPageSecondSectionPart2Wrapper>
  );
};

export default CareersPageSecondSectionPart2;
