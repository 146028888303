import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../Consts/GlobalContext";

const AboutPageFirstSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transform: translateY(-450px);
      animation: circleIntroAnimation1 2s ease-in-out forwards;
      .circle-wrapper {
        transform: rotate(45deg);
        animation: circleStaticAnimation 2s ease-in-out forwards;
        .circle {
          position: relative;
          width: 960px;
          height: 480px;
          animation: increaseCircleAnimation 2s ease-in-out forwards;
          span {
            transition: all 0.8s ease-out;
            color: White;
            transform: rotate(-45deg);
            display: block;
            position: absolute;
            z-index: 1;
            width: 21%;
            text-align: center;
            ${(props) => props.theme.font_medium_48};
            opacity: 0;
            animation: staticTextAnimation 2s ease-in-out forwards;
          }
          &:first-of-type {
            border-top-left-radius: 480px;
            border-top-right-radius: 480px;
            border-bottom: 0;
            background: linear-gradient(
              -90deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(85, 164, 255, 1) 100%
            );
            animation: increaseCircleAnimationTop 2s ease-in-out forwards;
            span {
              bottom: -50px;
              left: 26px;
            }
            span.first-animation {
              animation: firstTextAnimation1 2s ease-in-out forwards;
            }
            span.second-animation {
              animation: firstTextAnimation2 2s ease-in-out forwards;
            }
            span.third-animation {
              animation: thirdTextAnimation1 2s ease-in-out forwards;
            }
            span.hidden {
              animation: thirdTextAnimationHidden 2s ease-in-out forwards;
              bottom: -25px;
              left: 26px;
            }
            &:before {
              content: "";
              width: 270px;
              height: 270px;
              background-color: #000;
              border-radius: 50%;
              position: absolute;
              bottom: -135px;
              right: 0;
              border: 1px solid ${(props) => props.theme.secondary_color};
              z-index: 1;
              animation: increaseCircleAnimationBefore1 2s ease-in-out forwards;
            }
          }
          &:last-of-type {
            border-bottom-left-radius: 480px;
            border-bottom-right-radius: 480px;
            border-top: 0;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(85, 164, 255, 1) 100%
            );
            animation: increaseCircleAnimationBottom 2s ease-in-out forwards;
            span {
              right: 26px;
              top: -50px;
            }
            span.first-animation {
              animation: secondTextAnimation1 2s ease-in-out forwards;
            }
            span.second-animation {
              animation: secondTextAnimation2 2s ease-in-out forwards;
              ${(props) => props.theme.font_medium_33};
            }
            span.third-animation {
              animation: thirdTextAnimation2 2s ease-in-out forwards;
            }
            span.hidden {
              animation: thirdTextAnimationHidden 2s ease-in-out forwards;
              right: 26px;
              top: -29px;
            }
            &:before {
              content: "";
              width: 270px;
              height: 270px;
              background-color: #000;
              border-radius: 50%;
              position: absolute;
              top: -135px;
              left: 0;
              border: 1px solid ${(props) => props.theme.secondary_color};
              animation: increaseCircleAnimationBefore2 2s ease-in-out forwards;
            }
            &:after {
              content: "";
              width: 420px;
              height: 420px;
              background-color: #000;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              top: -210px;
              transform: translateX(-50%);
              animation: increaseCircleAnimationAfter 2s ease-in-out forwards;
            }
          }
        }

        .circle.decrease-animation {
          animation: decreaseCircleAnimation 2s ease-in-out forwards;
          &:first-of-type {
            animation: decreaseCircleAnimationTop 2s ease-in-out forwards;
            &:before {
              animation: decreaseCircleAnimationBefore1 2s ease-in-out forwards;
            }
          }

          &:last-of-type {
            animation: decreaseCircleAnimationBottom 2s ease-in-out forwards;
            &:before {
              animation: decreaseCircleAnimationBefore2 2s ease-in-out forwards;
            }
            &:after {
              animation: decreaseCircleAnimationAfter 2s ease-in-out forwards;
            }
          }
        }
      }
      .circle-wrapper.first-animation {
        animation: circleFirstAnimation 2s ease-in-out forwards;
      }
      .circle-wrapper.second-animation {
        animation: circleSecondAnimation 2s ease-in-out forwards;
      }
    }
    .inner.intro-animation {
      animation: circleIntroAnimation2 2s ease-in-out forwards;
    }
  }

  @keyframes circleIntroAnimation1 {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-450px);
    }
  }
  @keyframes circleIntroAnimation2 {
    0% {
      transform: translateY(-450px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes circleStaticAnimation {
    0% {
      transform: rotate(var(--circleStaticAnimationRotate));
    }
    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes staticTextAnimation {
    0% {
      ${(props) => props.theme.font_medium_33};
      opacity: 0;
    }
    100% {
      ${(props) => props.theme.font_medium_48};
      opacity: 1;
    }
  }

  @keyframes firstTextAnimation1 {
    0% {
      opacity: 1;
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(-220deg);
      opacity: 1;
    }
  }
  @keyframes firstTextAnimation2 {
    0% {
      transform: rotate(-220deg);
      opacity: 1;
    }
    100% {
      transform: rotate(-405deg);
      left: 25px;
      bottom: -40px;
      ${(props) => props.theme.font_medium_33};
      opacity: 0;
    }
  }
  @keyframes secondTextAnimation1 {
    0% {
      transform: rotate(-45deg);
      opacity: 1;
    }
    100% {
      transform: rotate(-220deg);
      opacity: 1;
    }
  }
  @keyframes secondTextAnimation2 {
    0% {
      transform: rotate(-220deg);
      opacity: 1;
    }
    100% {
      transform: rotate(-405deg);
      right: 35px;
      top: -45px;
      opacity: 0;
    }
  }

  @keyframes thirdTextAnimation1 {
    0% {
      transform: rotate(-220deg);
      opacity: 0;
    }
    100% {
      transform: rotate(-405deg);
      opacity: 1;
      bottom: -25px;
      left: 26px;
    }
  }
  @keyframes thirdTextAnimation2 {
    0% {
      transform: rotate(-220deg);
      opacity: 0;
    }
    100% {
      transform: rotate(-405deg);
      right: 26px;
      top: -29px;
      opacity: 1;
    }
  }
  @keyframes thirdTextAnimationHidden {
    0% {
      opacity: 1;
      transform: rotate(-405deg);
    }
    100% {
      opacity: 0;
      transform: rotate(-45deg);
    }
  }

  @keyframes circleFirstAnimation {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(220deg);
    }
  }
  @keyframes circleSecondAnimation {
    0% {
      transform: rotate(220deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }

  @keyframes increaseCircleAnimation {
    0% {
      width: 700px;
      height: 350px;
    }
    100% {
      width: 960px;
      height: 480px;
    }
  }
  @keyframes increaseCircleAnimationTop {
    0% {
      border-top-left-radius: 350px;
      border-top-right-radius: 350px;
      width: 700px;
      height: 350px;
    }
    100% {
      border-top-left-radius: 480px;
      border-top-right-radius: 480px;
      width: 960px;
      height: 480px;
    }
  }
  @keyframes increaseCircleAnimationBottom {
    0% {
      border-bottom-left-radius: 350px;
      border-bottom-right-radius: 350px;
      width: 700px;
      height: 350px;
    }
    100% {
      border-bottom-left-radius: 480px;
      border-bottom-right-radius: 480px;
      width: 960px;
      height: 480px;
    }
  }
  @keyframes increaseCircleAnimationBefore1 {
    0% {
      width: 205px;
      height: 205px;
      bottom: -105px;
    }
    100% {
      width: 270px;
      height: 270px;
      bottom: -135px;
    }
  }
  @keyframes increaseCircleAnimationBefore2 {
    0% {
      width: 205px;
      height: 205px;
      top: -105px;
    }
    100% {
      width: 270px;
      height: 270px;
      top: -135px;
    }
  }
  @keyframes increaseCircleAnimationAfter {
    0% {
      width: 290px;
      height: 290px;
      top: -152px;
    }
    100% {
      width: 420px;
      height: 420px;
      top: -210px;
    }
  }

  @keyframes decreaseCircleAnimationTop {
    0% {
      border-top-left-radius: 480px;
      border-top-right-radius: 480px;
      width: 960px;
      height: 480px;
    }
    100% {
      border-top-left-radius: 350px;
      border-top-right-radius: 350px;
      width: 700px;
      height: 350px;
    }
  }
  @keyframes decreaseCircleAnimationBottom {
    0% {
      border-bottom-left-radius: 480px;
      border-bottom-right-radius: 480px;
      width: 960px;
      height: 480px;
    }
    100% {
      border-bottom-left-radius: 350px;
      border-bottom-right-radius: 350px;
      width: 700px;
      height: 350px;
    }
  }
  @keyframes decreaseCircleAnimationBefore1 {
    0% {
      width: 270px;
      height: 270px;
      bottom: -135px;
    }
    100% {
      width: 205px;
      height: 205px;
      bottom: -105px;
    }
  }
  @keyframes decreaseCircleAnimationBefore2 {
    0% {
      width: 270px;
      height: 270px;
      top: -135px;
    }
    100% {
      width: 205px;
      height: 205px;
      top: -105px;
    }
  }
  @keyframes decreaseCircleAnimationAfter {
    0% {
      width: 420px;
      height: 420px;
      top: -210px;
    }
    100% {
      width: 290px;
      height: 290px;
      top: -152px;
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
      }
    }
    @keyframes circleIntroAnimation1 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-380px);
      }
    }
    @keyframes circleIntroAnimation2 {
      0% {
        transform: translateY(-380px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }

  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        .circle-wrapper {
          .circle {
            span {
              width: 25%;
            }
            &:first-of-type {
              span {
                bottom: -56px;
                left: 15px;
              }
            }
            &:last-of-type {
              span {
                right: 15px;
                top: -56px;
              }
            }
          }
        }
      }
    }
    @keyframes circleIntroAnimation1 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-300px);
      }
    }
    @keyframes circleIntroAnimation2 {
      0% {
        transform: translateY(-300px);
      }
      100% {
        transform: translateY(0px);
      }
    }

    @keyframes firstTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 1;
      }
      100% {
        transform: rotate(-405deg);
        left: 11px;
        bottom: -39px;
        ${(props) => props.theme.font_medium_33};
        opacity: 0;
      }
    }
    @keyframes secondTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 1;
      }
      100% {
        transform: rotate(-405deg);
        right: 18px;
        top: -46px;
        opacity: 0;
      }
    }
    @keyframes thirdTextAnimation1 {
      0% {
        transform: rotate(-220deg);
        opacity: 0;
      }
      100% {
        transform: rotate(-405deg);
        opacity: 1;
        bottom: -24px;
        left: 14px;
      }
    }
    @keyframes thirdTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 0;
      }
      100% {
        transform: rotate(-405deg);
        right: 14px;
        top: -29px;
        opacity: 1;
      }
    }

    @keyframes decreaseCircleAnimationTop {
      0% {
        border-top-left-radius: 480px;
        border-top-right-radius: 480px;
        width: 960px;
        height: 480px;
      }
      100% {
        border-top-left-radius: 300px;
        border-top-right-radius: 300px;
        width: 600px;
        height: 300px;
      }
    }

    @keyframes decreaseCircleAnimationBottom {
      0% {
        border-bottom-left-radius: 480px;
        border-bottom-right-radius: 480px;
        width: 960px;
        height: 480px;
      }
      100% {
        border-bottom-left-radius: 300px;
        border-bottom-right-radius: 300px;
        width: 600px;
        height: 300px;
      }
    }
    @keyframes decreaseCircleAnimationBefore1 {
      0% {
        width: 270px;
        height: 270px;
        bottom: -135px;
      }
      100% {
        width: 180px;
        height: 180px;
        bottom: -90px;
      }
    }
    @keyframes decreaseCircleAnimationBefore2 {
      0% {
        width: 270px;
        height: 270px;
        top: -135px;
      }
      100% {
        width: 180px;
        height: 180px;
        top: -90px;
      }
    }
    @keyframes decreaseCircleAnimationAfter {
      0% {
        width: 420px;
        height: 420px;
        top: -210px;
      }
      100% {
        width: 240px;
        height: 240px;
        top: -120px;
      }
    }
    @keyframes increaseCircleAnimationTop {
      0% {
        border-top-left-radius: 300px;
        border-top-right-radius: 300px;
        width: 600px;
        height: 300px;
      }
      100% {
        border-top-left-radius: 480px;
        border-top-right-radius: 480px;
        width: 960px;
        height: 480px;
      }
    }
    @keyframes increaseCircleAnimationBottom {
      0% {
        border-bottom-left-radius: 300px;
        border-bottom-right-radius: 300px;
        width: 600px;
        height: 300px;
      }
      100% {
        border-bottom-left-radius: 480px;
        border-bottom-right-radius: 480px;
        width: 960px;
        height: 480px;
      }
    }
    @keyframes increaseCircleAnimationBefore1 {
      0% {
        width: 180px;
        height: 180px;
        bottom: -90px;
      }
      100% {
        width: 270px;
        height: 270px;
        bottom: -135px;
      }
    }
    @keyframes increaseCircleAnimationBefore2 {
      0% {
        width: 180px;
        height: 180px;
        top: -90px;
      }
      100% {
        width: 270px;
        height: 270px;
        top: -135px;
      }
    }
    @keyframes increaseCircleAnimationAfter {
      0% {
        width: 240px;
        height: 240px;
        top: -120px;
      }
      100% {
        width: 420px;
        height: 420px;
        top: -210px;
      }
    }
  }

  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        .circle-wrapper {
          .circle {
            span {
              width: 25%;
              font-size: 38px;
              line-height: 49px;
            }
            &:first-of-type {
              span.hidden {
                bottom: -24px;
                left: 14px;
              }
            }
            &:last-of-type {
              span.hidden {
                right: 14px;
                top: -29px;
              }
            }
          }
        }
      }
    }
    @keyframes circleIntroAnimation1 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-300px);
      }
    }
    @keyframes circleIntroAnimation2 {
      0% {
        transform: translateY(-300px);
      }
      100% {
        transform: translateY(0px);
      }
    }

    @keyframes firstTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 1;
      }
      100% {
        transform: rotate(-405deg);
        left: 11px;
        bottom: -39px;
        ${(props) => props.theme.font_medium_33};
        opacity: 0;
      }
    }
    @keyframes secondTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 1;
      }
      100% {
        transform: rotate(-405deg);
        right: 18px;
        top: -46px;
        opacity: 0;
      }
    }
    @keyframes thirdTextAnimation1 {
      0% {
        transform: rotate(-220deg);
        opacity: 0;
      }
      100% {
        transform: rotate(-405deg);
        opacity: 1;
        bottom: -24px;
        left: 14px;
      }
    }
    @keyframes thirdTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 0;
      }
      100% {
        transform: rotate(-405deg);
        right: 14px;
        top: -29px;
        opacity: 1;
      }
    }

    @keyframes decreaseCircleAnimationTop {
      0% {
        border-top-left-radius: 450px;
        border-top-right-radius: 450px;
        width: 900px;
        height: 450px;
      }
      100% {
        border-top-left-radius: 250px;
        border-top-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
    }

    @keyframes decreaseCircleAnimationBottom {
      0% {
        border-bottom-left-radius: 450px;
        border-bottom-right-radius: 450px;
        width: 900px;
        height: 450px;
      }
      100% {
        border-bottom-left-radius: 250px;
        border-bottom-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
    }
    @keyframes decreaseCircleAnimationBefore1 {
      0% {
        width: 250px;
        height: 250px;
        bottom: -125px;
      }
      100% {
        width: 150px;
        height: 150px;
        bottom: -75px;
      }
    }
    @keyframes decreaseCircleAnimationBefore2 {
      0% {
        width: 250px;
        height: 250px;
        top: -125px;
      }
      100% {
        width: 150px;
        height: 150px;
        top: -75px;
      }
    }
    @keyframes decreaseCircleAnimationAfter {
      0% {
        width: 400px;
        height: 400px;
        top: -200px;
      }
      100% {
        width: 200px;
        height: 200px;
        top: -100px;
      }
    }
    @keyframes increaseCircleAnimationTop {
      0% {
        border-top-left-radius: 250px;
        border-top-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
      100% {
        border-top-left-radius: 450px;
        border-top-right-radius: 450px;
        width: 900px;
        height: 450px;
      }
    }
    @keyframes increaseCircleAnimationBottom {
      0% {
        border-bottom-left-radius: 250px;
        border-bottom-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
      100% {
        border-bottom-left-radius: 450px;
        border-bottom-right-radius: 450px;
        width: 900px;
        height: 450px;
      }
    }
    @keyframes increaseCircleAnimationBefore1 {
      0% {
        width: 150px;
        height: 150px;
        bottom: -75px;
      }
      100% {
        width: 250px;
        height: 250px;
        bottom: -125px;
      }
    }
    @keyframes increaseCircleAnimationBefore2 {
      0% {
        width: 150px;
        height: 150px;
        top: -75px;
      }
      100% {
        width: 250px;
        height: 250px;
        top: -125px;
      }
    }
    @keyframes increaseCircleAnimationAfter {
      0% {
        width: 200px;
        height: 200px;
        top: -100px;
      }
      100% {
        width: 400px;
        height: 400px;
        top: -200px;
      }
    }
    @keyframes staticTextAnimation {
      0% {
        ${(props) => props.theme.font_medium_33};
        opacity: 0;
      }
      100% {
        font-size: 38px;
        line-height: 49px;
        opacity: 1;
      }
    }
  }

  @media (max-width: 1025px) {
    @keyframes circleIntroAnimation1 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-320px);
      }
    }
    @keyframes circleIntroAnimation2 {
      0% {
        transform: translateY(-320px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }

  @media (max-width: 1281px) and (min-height: 1023px) {
    @keyframes circleIntroAnimation1 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-450px);
      }
    }
    @keyframes circleIntroAnimation2 {
      0% {
        transform: translateY(-450px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  @media (max-width: 963px) {
    .wrapper {
      .inner {
        .circle-wrapper {
          .circle {
            span {
              width: 25%;
              font-size: 38px;
              line-height: 49px;
            }
            &:first-of-type {
              span {
                bottom: -49px;
                left: 0px;
              }
              span.hidden {
                bottom: -24px;
                left: 14px;
              }
            }
            &:last-of-type {
              span {
                right: 0px;
                top: -49px;
              }
              span.hidden {
                right: 14px;
                top: -29px;
              }
            }
          }
        }
      }
    }

    @keyframes firstTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 1;
      }
      100% {
        transform: rotate(-405deg);
        left: 11px;
        bottom: -39px;
        ${(props) => props.theme.font_medium_33};
        opacity: 0;
      }
    }
    @keyframes secondTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 1;
      }
      100% {
        transform: rotate(-405deg);
        right: 18px;
        top: -46px;
        opacity: 0;
      }
    }
    @keyframes thirdTextAnimation1 {
      0% {
        transform: rotate(-220deg);
        opacity: 0;
      }
      100% {
        transform: rotate(-405deg);
        opacity: 1;
        bottom: -24px;
        left: 14px;
      }
    }
    @keyframes thirdTextAnimation2 {
      0% {
        transform: rotate(-220deg);
        opacity: 0;
      }
      100% {
        transform: rotate(-405deg);
        right: 14px;
        top: -29px;
        opacity: 1;
      }
    }

    @keyframes decreaseCircleAnimationTop {
      0% {
        border-top-left-radius: 400px;
        border-top-right-radius: 400px;
        width: 800px;
        height: 400px;
      }
      100% {
        border-top-left-radius: 250px;
        border-top-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
    }

    @keyframes decreaseCircleAnimationBottom {
      0% {
        border-bottom-left-radius: 400px;
        border-bottom-right-radius: 400px;
        width: 800px;
        height: 400px;
      }
      100% {
        border-bottom-left-radius: 250px;
        border-bottom-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
    }
    @keyframes decreaseCircleAnimationBefore1 {
      0% {
        width: 200px;
        height: 200px;
        bottom: -100px;
      }
      100% {
        width: 150px;
        height: 150px;
        bottom: -75px;
      }
    }
    @keyframes decreaseCircleAnimationBefore2 {
      0% {
        width: 200px;
        height: 200px;
        top: -100px;
      }
      100% {
        width: 150px;
        height: 150px;
        top: -75px;
      }
    }
    @keyframes decreaseCircleAnimationAfter {
      0% {
        width: 400px;
        height: 400px;
        top: -200px;
      }
      100% {
        width: 200px;
        height: 200px;
        top: -100px;
      }
    }
    @keyframes increaseCircleAnimationTop {
      0% {
        border-top-left-radius: 250px;
        border-top-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
      100% {
        border-top-left-radius: 400px;
        border-top-right-radius: 400px;
        width: 800px;
        height: 400px;
      }
    }
    @keyframes increaseCircleAnimationBottom {
      0% {
        border-bottom-left-radius: 250px;
        border-bottom-right-radius: 250px;
        width: 500px;
        height: 250px;
      }
      100% {
        border-bottom-left-radius: 400px;
        border-bottom-right-radius: 400px;
        width: 800px;
        height: 400px;
      }
    }
    @keyframes increaseCircleAnimationBefore1 {
      0% {
        width: 150px;
        height: 150px;
        bottom: -75px;
      }
      100% {
        width: 200px;
        height: 200px;
        bottom: -100px;
      }
    }
    @keyframes increaseCircleAnimationBefore2 {
      0% {
        width: 150px;
        height: 150px;
        top: -75px;
      }
      100% {
        width: 200px;
        height: 200px;
        top: -100px;
      }
    }
    @keyframes increaseCircleAnimationAfter {
      0% {
        width: 200px;
        height: 200px;
        top: -100px;
      }
      100% {
        width: 400px;
        height: 400px;
        top: -200px;
      }
    }
    @keyframes circleIntroAnimation1 {
      0% {
        transform: translateY(0px);
      }
      100% {
        transform: translateY(-220px);
      }
    }
    @keyframes circleIntroAnimation2 {
      0% {
        transform: translateY(-220px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
`;

const AboutPageFirstSection = () => {
  const { activeSection, activeSectionDirection, setActiveSection } =
    useContext(GlobalContext);

  useEffect(() => {
    console.log(activeSection);
    console.log(activeSectionDirection);
    if (activeSection === 2) {
      let r = document.querySelector(":root");
      r.style.setProperty("--circleStaticAnimationRotate", "405deg");
      r.style.setProperty("--circleIntroAnimationTransform1", "-450px");
      r.style.setProperty("--circleIntroAnimationTransform2", "0px");
    }
    if (activeSection === 1 && activeSectionDirection === "up") {
      setActiveSection(0);
    }
  }, [activeSection]);

  return (
    <>
      <AboutPageFirstSectionWrapper>
        <div className="section dummy"></div>
        <div className="wrapper section">
          <div
            className={
              activeSectionDirection === "down"
                ? activeSection === 2
                  ? "inner intro-animation"
                  : "inner"
                : activeSection >= 1 && activeSection <= 2
                ? "inner intro-animation"
                : "inner"
            }
          >
            <div
              className={
                activeSection === 1
                  ? "circle-wrapper first-animation"
                  : activeSection === 2
                  ? "circle-wrapper second-animation"
                  : "circle-wrapper"
              }
            >
              <div
                className={
                  activeSectionDirection === "down"
                    ? activeSection === 2
                      ? "circle decrease-animation"
                      : "circle"
                    : activeSection >= 1 && activeSection <= 2
                    ? "circle decrease-animation"
                    : "circle"
                }
              >
                <span
                  className={
                    activeSection === 1
                      ? "first-animation"
                      : activeSection === 2
                      ? "second-animation"
                      : ""
                  }
                >
                  leading solutions
                </span>
                <span
                  className={activeSection === 2 ? "third-animation" : "hidden"}
                >
                  quality
                </span>
              </div>
              <div
                className={
                  activeSectionDirection === "down"
                    ? activeSection === 2
                      ? "circle decrease-animation"
                      : "circle"
                    : activeSection >= 1 && activeSection <= 2
                    ? "circle decrease-animation"
                    : "circle"
                }
              >
                <span
                  className={
                    activeSection === 1
                      ? "first-animation"
                      : activeSection === 2
                      ? "second-animation"
                      : ""
                  }
                >
                  solving problems
                </span>
                <span
                  className={activeSection === 2 ? "third-animation" : "hidden"}
                >
                  value
                </span>
              </div>
            </div>
          </div>
        </div>
      </AboutPageFirstSectionWrapper>
    </>
  );
};

export default AboutPageFirstSection;
