import React from "react";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";
import Footer from "../Footer/Footer";

const ContactFormCareersWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 375px;
    .inner {
      flex-grow: 1;
      .title {
        ${(props) => props.theme.font_medium_48};
        text-align: center;
        margin-bottom: 60px;
      }
      .form-wrapper {
        padding: 60px;
        background: transparent
          linear-gradient(179deg, #1f3040 0%, #1f304029 100%) 0% 0% no-repeat
          padding-box;
        box-shadow: 25px 25px 40px #0009109e;
        border-radius: 10px;
        position: relative;
        .circle-lines {
          position: absolute;
          top: -40px;
          left: 50%;
          transform: translatex(-50%);
          .down {
            transform: rotate(180deg);
            margin-top: 44px;
          }
          span {
            width: 18px;
            height: 18px;
            background-color: ${(props) => props.theme.secondary_color};
            display: block;
            border-radius: 50%;
            position: relative;
          }
          hr {
            border: 0;
            border-left: 2px solid #9bb3cf;
            margin: 0;
            height: 25px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 15px;
            z-index: -1;
          }
        }
        .form-title {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          text-align: center;
          width: 50%;
          margin: 0 auto;
          margin-bottom: 30px;
        }
        .form-inner {
          display: flex;
          column-gap: 30px;
          .left {
            flex-basis: 50%;
          }
          .right {
            flex-basis: 50%;
            .form-end {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .left {
                display: flex;
                column-gap: 10px;
                align-items: center;
                flex-basis: 60%;
                input {
                  margin: 0;
                }
                label {
                  ${(props) => props.theme.font_regular_12}
                }
              }
              .right {
                flex-basis: 30%;
                text-align: right;
                > div {
                  display: inline-block;
                  a {
                    padding: 15px 30px;
                  }
                }
              }
            }
          }
          .form-group {
            input {
              padding: 20px;
              background-color: transparent;
              border: 1px solid #9bb3cf;
              border-radius: 5px;
              color: #9bb3cf;
              display: block;
              width: 100%;
              ${(props) => props.theme.font_regular_18};

              &::placeholder {
                ${(props) => props.theme.font_regular_18};
                color: #9bb3cf;
              }
              &:focus {
                outline: 0;
                border-color: ${(props) => props.theme.secondary_color};
              }
            }
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      padding: 0 150px;
    }
  }
  @media (max-width: 1537px) {
    .wrapper {
      padding: 0 100px;
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        .title {
          font-size: 40px;
          line-height: 52px;
        }
        .form-wrapper {
          padding: 30px 60px;
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        .title {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 25px;
        }
        .form-wrapper {
          padding: 20px 40px;
          .circle-lines {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      padding: 0 50px;
      .inner {
        .title {
          font-size: 35px;
          line-height: 45px;
          margin-bottom: 25px;
        }
        .form-wrapper {
          padding: 20px 40px;
          .circle-lines {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      padding: 0 50px;
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      padding: 0 25px;
      .inner {
        .title {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 15px;
        }
        .form-wrapper {
          padding: 15px;
          .form-title {
            width: 100%;
            margin-bottom: 10px;
            font-size: 17px;
            line-height: 22px;
          }
          .form-inner {
            display: block;
            .form-group {
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              input {
                padding: 15px;
              }
            }
            > .left {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 376px) and (max-height: 668px) {
    .wrapper {
      .inner {
        .title {
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 10px;
        }
        .form-wrapper {
          padding: 10px;
          .form-title {
            width: 100%;
            margin-bottom: 5px;
            font-size: 15px;
            line-height: 19px;
          }
          .form-inner {
            display: block;
            .form-group {
              &:not(:last-child) {
                margin-bottom: 5px;
              }
              input {
                padding: 10px;
                font-size: 16px;
                line-height: 21px;
                &::placeholder {
                  font-size: 16px;
                  line-height: 21px;
                }
              }
            }
            > .left {
              margin-bottom: 5px;
            }
            .right {
              .form-end {
                .left {
                  label {
                    font-size: 10px;
                    line-height: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ContactFormCareers = () => {
  return (
    <ContactFormCareersWrapper>
      <div className="wrapper section">
        <div className="inner">
          <p className="title">
            Haven't found the position you're interested in?
          </p>
          <div className="form-wrapper">
            <div className="circle-lines">
              <div className="up">
                <span>
                  <hr />
                </span>
              </div>
              <div className="down">
                <span>
                  <hr />
                </span>
              </div>
            </div>
            <p className="form-title">We are opening new vacancies every day</p>
            <div className="form-inner">
              <div className="left">
                <div className="form-group">
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    placeholder="Full Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Location"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="intrest"
                    name="intrest"
                    placeholder="I'm intrested in:"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="attach"
                    name="attach"
                    placeholder="Attach your CV"
                    required
                  />
                </div>
              </div>
              <div className="right">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="start"
                    name="start"
                    placeholder="Earliest start/notice period"
                    required
                  />
                </div>
                <div className="form-end">
                  <div className="left">
                    <div>
                      <input type="checkbox" id="accept" name="accept" />
                    </div>

                    <label htmlFor="accept">
                      I accept that the information I provide is processed
                      according to Ice Labs privacy policy.
                    </label>
                  </div>
                  <div className="right">
                    <CustomButton title="Submit" to="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </ContactFormCareersWrapper>
  );
};

export default ContactFormCareers;
