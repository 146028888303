import React from "react";
import styled from "styled-components";
import { KeyboardArrowRight } from "@styled-icons/material-rounded/KeyboardArrowRight";

const CustomCardWrapper = styled.div`
  flex-basis: 25%;
  .card {
    .card-inner {
      padding: 0 15px;
      position: relative;

      .card-image {
        margin: 0 auto;
        width: 148px;
        height: 148px;
        border-radius: 50%;
        border: 1px solid #13202d;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .arrow {
          display: none;
          position: absolute;
          right: -95px;
          top: 50%;
          color: ${(props) => props.theme.secondary_color};
          transform: translateY(-50%);
        }
        &:before {
          content: "";
          width: 148px;
          height: 148px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          border-radius: 50%;
          transition: all 0.3s ease-in-out;
        }
        &:after {
          content: "";
          width: 148px;
          height: 148px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          border-radius: 50%;
          transition: all 0.3s ease-in-out;
        }
        img {
          transition: all 0.3s ease-in-out;
        }
      }
      .card-text {
        text-align: center;
        margin-top: 55px;
        h6 {
          ${(props) => props.theme.font_demibold_26};
          color: #fff;
          margin-bottom: 10px;
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #9bb3cf;
        }
      }
    }
    &:hover {
      .card-inner {
        .card-image {
          background-color: #13202d;
          &:before {
            background-color: #13202d;
            opacity: 0.35;
            width: 182px;
            height: 182px;
          }
          &:after {
            width: 222px;
            height: 222px;
            background-color: #13202d;
            opacity: 0.13;
          }
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &:not(:last-child) {
    .card {
      .card-inner {
        .arrow {
          display: block;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .card {
      .card-inner {
        .card-text {
          h6 {
            font-size: 24px;
            line-height: 31px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .card {
      .card-inner {
        .card-text {
          h6 {
            font-size: 22px;
            line-height: 29px;
          }
          p {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .card {
      .card-inner {
        .card-image {
          .arrow {
            right: -70px;
          }
        }
        .card-text {
          h6 {
            font-size: 20px;
            line-height: 26px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    flex-basis: 50%;
    .card {
      .card-inner {
        padding: 0 5px;
        .card-image {
          width: 90px;
          height: 90px;
          .arrow {
            display: none;
          }
          &:before {
            width: 90px;
            height: 90px;
          }
          &:after {
            width: 90px;
            height: 90px;
          }
          img {
            transform: scale(0.6);
          }
        }
        .card-text {
          margin-top: 20px;
          h6 {
            font-size: 16px;
            line-height: 20px;
          }
          p {
            font-size: 13px;
            line-height: 15px;
          }
        }
      }
      &:hover {
        .card-inner {
          .card-image {
            &:before {
              width: 124px;
              height: 124px;
            }
            &:after {
              width: 164px;
              height: 164px;
            }
            img {
              transform: scale(0.75);
            }
          }
        }
      }
    }
  }

  @media (max-width: 376px) {
    .card {
      .card-inner {
        .card-image {
          width: 70px;
          height: 70px;
          .arrow {
            display: none;
          }
          &:before {
            width: 70px;
            height: 70px;
          }
          &:after {
            width: 70px;
            height: 70px;
          }
          img {
            transform: scale(0.5);
          }
        }
        .card-text {
          margin-top: 10px;
          h6 {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 5px;
          }
          p {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
      &:hover {
        .card-inner {
          .card-image {
            &:before {
              width: 104px;
              height: 104px;
            }
            &:after {
              width: 144px;
              height: 144px;
            }
            img {
              transform: scale(0.65);
            }
          }
        }
      }
    }
  }
`;

const CustomCard = ({ img, title, text, isArrow }) => {
  return (
    <CustomCardWrapper>
      <div className="card">
        <div className="card-inner">
          <div className="card-image">
            {isArrow && (
              <span className="arrow">
                <KeyboardArrowRight size={32} />
              </span>
            )}
            <img src={img}></img>
          </div>
          <div className="card-text">
            <h6>{title}</h6>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </CustomCardWrapper>
  );
};

export default CustomCard;
