import React from "react";
import styled from "styled-components";
import LatestBlogsCard from "../LatestBlogs/LatestBlogsCard";
import laptop from "../../img/latest-blogs/laptop.png";

const BlogPageThirdSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);

    position: relative;
    .inner {
      height: 100%;
      padding: 0 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      .card-wrapper {
        display: flex;
        column-gap: 30px;
      }
    }
  }
  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 150px;
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 100px;
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 50px;
      }
    }
  }
  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .card-wrapper {
          column-gap: 0;
          flex-wrap: wrap;
          row-gap: 10px;
        }
      }
    }
  }
  @media (max-width: 361px) and (max-height: 641px) {
    .wrapper {
      .inner {
        align-items: flex-end;
        .card-wrapper {
          margin-bottom: 30px;
        }
      }
    }
  }
`;

const BlogPageThirdSection = () => {
  return (
    <BlogPageThirdSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="card-wrapper">
            <LatestBlogsCard
              title="Lorem ipsum dolor sit amet, consetetur dolor"
              category="SOFTWARE DEVELOPMENT"
              img={laptop}
              text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet"
              readTime="10 min read"
            />
            <LatestBlogsCard
              title="Lorem ipsum dolor sit amet, consetetur dolor"
              category="SOFTWARE DEVELOPMENT"
              img={laptop}
              text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet"
              readTime="10 min read"
            />
            <LatestBlogsCard
              title="Lorem ipsum dolor sit amet, consetetur dolor"
              category="SOFTWARE DEVELOPMENT"
              img={laptop}
              text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet"
              readTime="10 min read"
            />
          </div>
        </div>
      </div>
    </BlogPageThirdSectionWrapper>
  );
};

export default BlogPageThirdSection;
