import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import BottomFade from "../BottomFade/BottomFade";
import Menu from "../NavBar/Menu";
import ContactPageFirstSection from "./ContactPageFirstSection";
import ContactPageIntro from "./ContactPageIntro";
import ContactPageSecondSection from "./ContactPageSecondSection";

const ContactPage = () => {
  const { setIsWheelEnabled, MouseWheelHandler } = useContext(GlobalContext);

  useEffect(() => {
    setIsWheelEnabled(false);
    setTimeout(() => {
      setIsWheelEnabled(true);
    }, 3000);
  }, []);
  return (
    <div
      onWheel={(e) => MouseWheelHandler(e)}
      onTouchStart={(e) => MouseWheelHandler(e)}
      onTouchEnd={(e) => MouseWheelHandler(e)}
    >
      <Menu />
      <ContactPageIntro />
      <ContactPageFirstSection />
      <ContactPageSecondSection />
      <BottomFade />
    </div>
  );
};

export default ContactPage;
