import React from "react";
import styled from "styled-components";
import { Amazon } from "@styled-icons/boxicons-logos/Amazon";
import profile from "../../img/partners/profile.svg";
const PartnersSectionWrapper = styled.div`
  .wrapper {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 375px;
    .inner {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .partners {
        width: 100%;
        position: relative;
        border: 2px solid #9bb3cf;
        display: flex;
        border-radius: 150px;
        align-items: center;
        .left {
          flex-basis: 30%;
          border-right: 2px solid #9bb3cf;
          p {
            ${(props) => props.theme.font_demibold_26};
            color: #fff;
            padding: 70px;
          }
        }
        .right {
          padding-left: 70px;

          .icons {
            width: 50px;
            color: #9bb3cf;
            &:not(:last-of-type) {
              margin-right: 150px;
            }
          }
        }
      }
      .cards {
        .card {
          position: absolute;
          transition: all 0.8s ease-out;
          .card-wrapper {
            background: transparent
              linear-gradient(164deg, #1f3040 0%, #1f3040d1 100%) 0% 0%
              no-repeat padding-box;
            box-shadow: 25px 25px 40px #0009109e;
            border-radius: 5px;
            position: relative;
            .card-inner {
              padding: 50px 35px 50px 110px;
              .text {
                ${(props) => props.theme.font_regular_20};
                color: #fff;
                margin-bottom: 15px;
              }
              .name {
                ${(props) => props.theme.font_bold_18_muli};
                color: ${(props) => props.theme.secondary_color};
              }
              .position {
                ${(props) => props.theme.font_regular_12_muli};
                color: #9bb3cf;
              }
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: -35px;
                transform: translateY(-50%) rotate(90deg);
                width: 138px;
                height: 69px;
                background-color: ${(props) => props.theme.primary_color};
                border-top-left-radius: 138px;
                border-top-right-radius: 138px;
                border-bottom: 0;
              }
            }
            .profile-icon {
              position: absolute;
              top: 50%;
              left: -59px;
              width: 118px;
              height: 118px;
              transform: translateY(-50%);
              box-shadow: 25px 25px 40px #0009109e;
              border-radius: 50%;
              img {
                width: 100%;
                display: block;
              }
            }
          }
          &:nth-child(1) {
            width: 47%;
            top: -210px;
            left: 215px;
          }
          &:nth-child(2) {
            width: 55%;
            bottom: -300px;
            left: 60px;
            .card-wrapper {
              .card-inner {
                padding: 50px 110px 50px 110px;
              }
            }
          }
          &:nth-child(3) {
            width: 40%;
            bottom: -230px;
            right: 50px;
          }
        }
      }

      &:hover {
        .cards {
          .card {
            &:nth-child(1) {
              top: -270px;
              left: 375px;
            }
            &:nth-child(2) {
              bottom: -325px;
              left: -30px;
            }
            &:nth-child(3) {
              bottom: -300px;
              right: 70px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      padding: 0 150px;
      .inner {
        .partners {
          .left {
            p {
              padding: 60px;
            }
          }
        }
        &:hover {
          .cards {
            .card {
              &:nth-child(1) {
                top: -230px;
                left: 375px;
              }
              &:nth-child(2) {
                bottom: -325px;
                left: 30px;
              }
              &:nth-child(3) {
                bottom: -300px;
                right: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1441px) {
    .wrapper {
      padding: 0 100px;
    }
  }
  @media (max-width: 1366px) and (max-height: 768px) {
    .wrapper {
      .inner {
        .partners {
          .left {
            p {
              padding: 50px;
            }
          }
          .cards {
            .card {
              &:nth-child(2) {
                bottom: -255px;
                left: 25px;
              }
              &:nth-child(3) {
                right: 20px;
              }
            }
          }
          &:hover {
            .cards {
              .card {
                &:nth-child(1) {
                  top: -210px;
                  left: 375px;
                }
                &:nth-child(2) {
                  bottom: -285px;
                  left: 5px;
                }
                &:nth-child(3) {
                  bottom: -280px;
                  right: 40px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        .partners {
          .cards {
            .card {
              .card-wrapper {
                .card-inner {
                  padding: 30px 45px 30px 70px;
                  .text {
                    font-size: 18px;
                    line-height: 23px;
                  }
                  .name {
                    font-size: 16px;
                    line-height: 21px;
                  }
                }
              }
              &:nth-child(1) {
                top: -165px;
              }
              &:nth-child(2) {
                bottom: -205px;
              }
              &:nth-child(3) {
                bottom: -190px;
              }
            }
          }
          &:hover {
            .cards {
              .card {
                &:nth-child(1) {
                  top: -180px;
                }
                &:nth-child(2) {
                  bottom: -240px;
                  left: 5px;
                }
                &:nth-child(3) {
                  bottom: -220px;
                  right: 40px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .wrapper {
      padding: 0 25px;

      .inner {
        .partners {
          .right {
            .icons {
              &:not(:last-of-type) {
                margin-right: 110px;
              }
            }
          }
          .cards {
            .card {
              .card-wrapper {
                .profile-icon {
                  width: 90px;
                  height: 90px;
                  left: -46px;
                }
                .card-inner {
                  padding: 15px 35px 15px 65px;
                  .text {
                    font-size: 18px;
                    line-height: 23px;
                  }
                  .name {
                    font-size: 16px;
                    line-height: 21px;
                  }
                  .position {
                    font-size: 16px;
                    line-height: 21px;
                  }
                  &:before {
                    width: 110px;
                    height: 55px;
                    left: -28px;
                  }
                }
              }
              &:nth-child(1) {
                top: -150px;
              }
              &:nth-child(2) {
                bottom: -180px;
                width: 50%;
                left: 60px;
              }
              &:nth-child(3) {
                bottom: -165px;
              }
            }
          }
          &:hover {
            .cards {
              .card {
                &:nth-child(1) {
                  top: -180px;
                }
                &:nth-child(2) {
                  bottom: -235px;
                  left: 40px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .wrapper {
      .inner {
        .partners {
          .left {
            p {
              padding: 20px;
              font-size: 18px;
              line-height: 23px;
            }
          }
          .right {
            .icons {
              &:not(:last-child) {
                margin-right: 50px;
              }
            }
          }
          .cards {
            .card {
              .card-wrapper {
                .profile-icon {
                  width: 60px;
                  height: 60px;
                  left: -30px;
                }
                .card-inner {
                  padding: 12px 40px 12px 48px;
                  .text {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 5px;
                  }
                  .name {
                    font-size: 13px;
                    line-height: 17px;
                  }
                  .position {
                    font-size: 13px;
                    line-height: 17px;
                  }
                  &:before {
                    width: 80px;
                    height: 40px;
                    left: -20px;
                  }
                }
              }
              &:nth-child(1) {
                top: -135px;
                left: 215px;
              }
              &:nth-child(2) {
                bottom: -135px;
                width: 50%;
                left: 50px;
              }
              &:nth-child(3) {
                bottom: -172px;
                right: 10px;
              }
            }
          }
          &:hover {
            .cards {
              .card {
                &:nth-child(1) {
                  top: -180px;
                  left: 250px;
                }
                &:nth-child(2) {
                  bottom: -185px;
                  left: 30px;
                }
                &:nth-child(3) {
                  bottom: -215px;
                  right: 30px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 426px) {
    // display: none;
  }
`;

const PartnersSection = () => {
  return (
    <PartnersSectionWrapper className="mobile-off">
      <div className="wrapper section">
        <div className="inner">
          <div className="partners">
            <div className="left">
              <p>A selection of our clients</p>
            </div>
            <div className="right">
              <Amazon className="icons" />
              <Amazon className="icons" />
              <Amazon className="icons" />
              <Amazon className="icons" />
            </div>
            <div className="cards">
              <div className="card">
                <div className="card-wrapper">
                  <div className="card-inner">
                    <p className="text">
                      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna."
                    </p>
                    <p className="name">John Doe</p>
                    <p className="position">Founder, Company Name</p>
                  </div>
                  <div className="profile-icon">
                    <img src={profile} alt="profile"></img>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-wrapper">
                  <div className="card-inner">
                    <p className="text">
                      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna."
                    </p>
                    <p className="name">John Doe</p>
                    <p className="position">Founder, Company Name</p>
                  </div>
                  <div className="profile-icon">
                    <img src={profile} alt="profile"></img>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-wrapper">
                  <div className="card-inner">
                    <p className="text">
                      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna."
                    </p>
                    <p className="name">John Doe</p>
                    <p className="position">Founder, Company Name</p>
                  </div>
                  <div className="profile-icon">
                    <img src={profile} alt="profile"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PartnersSectionWrapper>
  );
};

export default PartnersSection;
