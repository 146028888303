import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";
import HomePageThirdSectionCard from "./HomePageThirdSectionCard";

const HomePageThirdSectionWrapper = styled.div`
  overflow: hidden;
  .home-page-third-section-wrapper {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 375px;
    .bg-circle {
      position: absolute;
      width: 2900px;
      height: 2900px;
      border: 1px solid #9bb3cf;
      border-radius: 50%;
      background-color: transparent;
      left: -470px;
      top: 0;
      z-index: -1;
    }
    .home-page-third-section-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .home-page-third-section-inner-left {
        flex-basis: 50%;
        display: flex;
        margin: 0 -15px;
        .left,
        .right {
          > div {
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
        }
        .right {
          margin-top: 45px;
        }
      }
      .home-page-third-section-inner-right {
        flex-basis: 50%;
        text-align: right;
        h3 {
          ${(props) => props.theme.font_medium_48};
          margin-bottom: 80px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: ${(props) => props.theme.secondary_color};
            border-radius: 50%;
            top: 142px;
            right: 0;
          }
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
          margin-bottom: 35px;
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .home-page-third-section-wrapper {
      padding: 0 150px;
    }
  }
  @media (max-width: 1441px) {
    .home-page-third-section-wrapper {
      padding: 0 100px;
    }
  }
  @media (max-width: 1281px) {
    .home-page-third-section-wrapper {
      padding: 0 50px;
      .home-page-third-section-inner {
        .home-page-third-section-inner-left {
          .left,
          .right {
            > div {
              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }
          }
        }
        .home-page-third-section-inner-right {
          h3 {
            font-size: 42px;
            line-height: 54px;
          }
          p {
            font-size: 18px;
            line-height: 23px;
          }
        }
      }
    }
  }

  @media (max-width: 1025px) {
    .home-page-third-section-wrapper {
      padding: 0 25px;
      .home-page-third-section-inner {
        .home-page-third-section-inner-right {
          h3 {
            font-size: 34px;
            line-height: 44px;
            &:before {
              top: 115px;
            }
          }
          p {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .home-page-third-section-wrapper {
      padding: 0 25px;
      .home-page-third-section-inner {
        column-gap: 10px;
        .home-page-third-section-inner-left {
          margin: 0 -10px;
        }
        .home-page-third-section-inner-right {
          h3 {
            font-size: 26px;
            line-height: 33px;
            margin-bottom: 50px;
            &:before {
              top: 80px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 426px) {
    .home-page-third-section-wrapper {
      .home-page-third-section-inner {
        column-gap: 0px;
        flex-direction: column;
        .home-page-third-section-inner-left {
          margin: 0 -10px;
          order: 1;
          .right {
            display: none;
          }
          .left {
            display: flex;
            flex-wrap: wrap;
          }
        }
        .home-page-third-section-inner-right {
          margin-bottom: 15px;
          h3 {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 20px;
            &:before {
              display: none;
            }
          }
          p {
            font-size: 13px;
            line-height: 17px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`;

const HomePageThirdSection = () => {
  return (
    <HomePageThirdSectionWrapper>
      <div className="home-page-third-section-wrapper section">
        <div className="bg-circle"></div>
        <div className="home-page-third-section-inner">
          <div className="home-page-third-section-inner-left">
            <div className="left">
              <HomePageThirdSectionCard title="Web development" />
              <HomePageThirdSectionCard title="mobile development" />
              <HomePageThirdSectionCard title="big data and data engineering" />
              <HomePageThirdSectionCard title="manual and automated testing" />
            </div>
            <div className="right">
              <HomePageThirdSectionCard title="UI/UX design" />
              <HomePageThirdSectionCard title="IoT" />
              <HomePageThirdSectionCard title="RPA" />
              <HomePageThirdSectionCard title="Cloud engineering and solution architecture" />
            </div>
          </div>
          <div className="home-page-third-section-inner-right">
            <h3>We are the enthusiasts of solving business problems</h3>
            <p>
              Ice Labs is your conduit to the cutting-edge technological
              innovations. Combining our technical expertise and engineering
              talent we deliver your innovations to market, whilst sharing your
              values and challenging your idea.
            </p>
            <CustomButton title="Find out more" />
          </div>
        </div>
      </div>
    </HomePageThirdSectionWrapper>
  );
};

export default HomePageThirdSection;
