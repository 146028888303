import React from "react";
import styled from "styled-components";
import healthcare from "../../img/about/healthcare.svg";
import cart from "../../img/about/cart.svg";
import financial from "../../img/about/financial.svg";
import industry from "../../img/about/industry.svg";
import running from "../../img/about/running.svg";
import logistics from "../../img/about/logistics.svg";
import science from "../../img/about/science.svg";

const AboutPageSecondSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 295px;
      display: flex;
      justify-content: center;
      align-items: center;
      .card-wrapper {
        display: flex;
        flex-wrap: wrap;

        .card {
          position: relative;
          flex-basis: 25%;
          padding: 60px 40px;
          p {
            ${(props) => props.theme.font_regular_26};
            color: #9bb3cf;
          }
          h2 {
            ${(props) => props.theme.font_medium_48};
          }
          img{
            width:115px;
            height:105px;
            margin-left:-25px;
            transition: all 0.8s ease-in-out;
          }
          span{
            display:block;
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            opacity:0;
            transition: all 0.8s ease-in-out;
          }
          &:nth-child(2),
          &:nth-child(3){
            span{
                background: linear-gradient(180deg, rgba(7,17,26,0) 45%, rgba(24,39,53,0.908000700280112) 100%);
            }
          }
          &:nth-child(6),
          &:nth-child(7){
            span{
                background: linear-gradient(0deg, rgba(7,17,26,0) 45%, rgba(24,39,53,0.908000700280112) 100%);
            }
          }

          &:nth-child(1n + 1) {
            border-right: 2px solid #14222f;
          }

          &:not(:nth-child(1n + 5)) {
            border-bottom: 2px solid #14222f;
            padding-top: 80px;
            &:before {
              content: "";
              width: 2px;
              height: 100%;
              position: absolute;
              right: -2px;
              top: 0;
              background: linear-gradient(
                180deg,
                rgba(7, 17, 26, 1) 0%,
                rgba(20, 34, 47, 0) 100%
              );
            }
          }
          &:nth-child(1n + 5) {
            padding-bottom: 80px;
            &:before {
              content: "";
              width: 2px;
              height: 100%;
              position: absolute;
              right: -2px;
              top: 0;
              background: linear-gradient(
                0deg,
                rgba(7, 17, 26, 1) 0%,
                rgba(20, 34, 47, 0) 100%
              );
            }
          }
          &:nth-child(4) {
            border-right: 0 !important;
            padding-right: 75px;
            span{
                background: linear-gradient(225deg, rgba(7,17,26,0) 75%, rgba(24,39,53,0.908000700280112) 100%);
            }
          }
          &:nth-child(5) {
            padding-left: 75px;
            span{
                background: linear-gradient(45deg, rgba(7,17,26,0) 75%, rgba(24,39,53,0.908000700280112) 100%);
            }
          }
          &:last-child {
            border: 0 !important;
            padding-right: 75px;
            span{
                background: linear-gradient(315deg, rgba(7,17,26,0) 75%, rgba(24,39,53,0.908000700280112) 100%);
            }
            &:after {
              content: "";
              width: 100%;
              height: 2px;
              position: absolute;
              left: 0px;
              top -2px;
              background: linear-gradient(
                270deg,
                rgba(7, 17, 26, 1) 0%,
                rgba(20, 34, 47, 0) 100%
              );
            }
          }
          &:first-child {
            padding-left: 75px;
            display:flex;
            align-items:end;
            background: linear-gradient(135deg, rgba(7,17,26,0) 75%, rgba(24,39,53,0.908000700280112) 100%);
            &:after {
              content: "";
              width: 100%;
              height: 2px;
              position: absolute;
              left: 0px;
              bottom: -2px;
              background: linear-gradient(
                90deg,
                rgba(7, 17, 26, 1) 0%,
                rgba(20, 34, 47, 0) 100%
              );
            }
          }

          &:hover{
            img{
                transform:scale(1.2);
            }
            span{
                opacity:1;
            }
          }
        }
      }
    }
  }

  @media(max-width:1900px){
    .wrapper{
      .inner{
        padding:0 150px;
        .card-wrapper{
          .card{
            padding: 50px 30px;
          }
        }
      }
    }
  }
  @media(max-width:1441px){
    .wrapper{
      .inner{
        padding:0 100px;
        .card-wrapper{
          .card{
            padding: 50px 30px;
            h2{
              font-size:40px;
              line-height: 52px;
            }
            &:first-child{
              padding-left:50px;
              &:after{
                height:1px;
                bottom:-1px;
              }
            }
            &:nth-child(1n + 1){
              border-right: 1px solid #14222f;
            }
            &:not(:nth-child(1n + 5)){
              border-bottom: 1px solid #14222f;
              &:before{
                width:1px;
                right:-1px;
              }
            }
            &:last-child{
              &:after{
                height:1px;
                top:-1px;
              }
            }
          }
        }
      }
    }
  }

  @media(max-width:1281px){
    .wrapper {
      .inner{
        .card-wrapper{
          .card {
            h2{
              font-size:32px;
              line-height: 41px;
            }
            p{
              font-size:20px;
              line-height:26px;
            }
          }
        }
      }
    }
  }
  @media(max-width:1025px){
    .wrapper{
      .inner{
        padding:0 50px;
        .card-wrapper{
          .card{
            padding: 30px 20px;
            h2{
              font-size:26px;
              line-height: 34px;
            }
            &:first-child{
              padding-left:70px;
              
            }
            
          }
        }
      }
    }
  }

  @media(max-width:426px){
    .wrapper{
      .inner{
        padding:0 25px;
        .card-wrapper{
          .card{
            padding: 15px;
            flex-basis:50%;
            text-align: center;
            h2{
              font-size:20px;
              line-height: 26px;
            }
            p{
              font-size:16px;
              line-height:21px;
            }
            img{
              width:100px;
              height:90px;
            }
            &:first-child{
              padding-left:20px;
              order:1;
            }
            &:nth-child(5){
              padding-left:20px;
              order:7;
            }
            &:nth-child(4){
              padding-right:20px;
              order:2;
              
            }
            &:nth-child(3),
            &:nth-child(4){
              &:after{
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0px;
                bottom: -1px;
                background: linear-gradient( 270deg, rgba(7,17,26,1) 0%, rgba(20,34,47,0) 100% );
              }
            }
            &:nth-child(2){
              order:3;
            }
            &:nth-child(3){
              order:4;
              border-right:0;
              
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(7),
            &:last-child {
              &:before{
                display:none;
              }
            }
            &:nth-child(6){
              order:5;
              border-bottom:1px solid #14222f;
              
            }
            &:nth-child(6),
            &:nth-child(2){
              span{
                background: linear-gradient(90deg,rgba(7,17,26,0) 45%,rgba(24,39,53,0.908000700280112) 100%);
              }
            }
            &:nth-child(2),
            &:nth-child(6){
              &:after{
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0px;
                bottom: -1px;
                background: linear-gradient( 90deg, rgba(7,17,26,1) 0%, rgba(20,34,47,0) 100% );
                }
            }
            &:nth-child(7){
              order:6;
              border-right:0;
              border-bottom:1px solid #14222f;
              
            }
            &:nth-child(7),
            &:nth-child(3){
              span{
                background: linear-gradient(270deg,rgba(7,17,26,0) 45%,rgba(24,39,53,0.908000700280112) 100%);
              }
            }
            &:last-child{
              padding-right:20px;
              order:8;
            }
            &:not(:nth-child(1n + 5)){
              padding-top:20px;
            }
            &:nth-child(1n + 5){
              padding-bottom:20px;
            }
          }
        }
      }
    }
  }

  @media(max-width:361px){
    .wrapper{
      .inner{
        .card-wrapper{
          .card{
            img{
              width:80px;
              height:60px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
`;

const AboutPageSecondSection = () => {
  return (
    <AboutPageSecondSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="card-wrapper">
            <div className="card">
              <h2>Key Industries</h2>
            </div>
            <div className="card">
              <img src={healthcare}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
            <div className="card">
              <img src={cart}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
            <div className="card">
              <img src={financial}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
            <div className="card">
              <img src={industry}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
            <div className="card">
              <img src={running}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
            <div className="card">
              <img src={logistics}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
            <div className="card">
              <img src={science}></img>
              <p>Key Industries</p>
              <span className="hover-animation"></span>
            </div>
          </div>
        </div>
      </div>
    </AboutPageSecondSectionWrapper>
  );
};

export default AboutPageSecondSection;
