import React from "react";
import styled from "styled-components";
import CustomButton from "../CustomButton/CustomButton";
import {
  InstagramAlt,
  Linkedin,
  Twitter,
  FacebookCircle,
} from "@styled-icons/boxicons-logos";

const ContactPageFirstSectionWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(7, 17, 26, 0) 0%,
      rgba(7, 17, 26, 1) 5%
    );
    .inner {
      height: 100%;
      padding: 0 475px;
      display: flex;
      justify-content: center;
      align-items: end;
      .content {
        width: 100%;
        display: flex;
        margin-bottom: 50px;
        column-gap: 70px;
        .left {
          flex-basis: 50%;
          .form-group {
            input,
            textarea {
              padding: 20px;
              background-color: transparent;
              border: 1px solid #9bb3cf;
              border-radius: 5px;
              color: #9bb3cf;
              display: block;
              width: 100%;
              ${(props) => props.theme.font_regular_18};

              &::placeholder {
                ${(props) => props.theme.font_regular_18};
                color: #9bb3cf;
              }
              &:focus {
                outline: 0;
                border-color: ${(props) => props.theme.secondary_color};
              }
            }
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
          textarea {
            height: 260px;
            resize: none;
          }
        }
        .form-end {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .form-end-left {
            display: flex;
            column-gap: 10px;
            align-items: center;
            flex-basis: 60%;
            input {
              margin: 0;
            }
            label {
              ${(props) => props.theme.font_regular_12}
            }
          }
          .form-end-right {
            flex-basis: 30%;
            text-align: right;
            > div {
              display: inline-block;
              a {
                padding: 15px 30px;
              }
            }
          }
        }
        .right {
          flex-basis: 50%;
          .right-inner {
            height: 100%;
            background: transparent
              linear-gradient(179deg, #1f3040 0%, #1f304029 100%) 0% 0%
              no-repeat padding-box;
            box-shadow: 25px 25px 40px #0009109e;
            border-radius: 10px;
            padding: 70px 50px 50px;
            h3 {
              ${(props) => props.theme.font_demibold_26};
              color: #fff;
              margin-bottom: 60px;
            }
            .right-groups-wrapper {
              .right-groups {
                p {
                  ${(props) => props.theme.font_demibold_14};
                  color: #9bb3cf;
                  text-transform: uppercase;
                  margin-bottom: 5px;
                }
                span {
                  display: block;
                  ${(props) => props.theme.font_regular_16};
                  color: #fff;
                }
                &:not(:last-child) {
                  margin-bottom: 50px;
                }
              }
            }
            hr {
              border: 0;
              border-top: 1px solid #9bb3cf;
              margin: 65px 0;
            }
            .right-end {
              p {
                ${(props) => props.theme.font_demibold_14};
                color: #9bb3cf;
                margin-bottom: 25px;
              }
              .social-links-wrapper {
                display: flex;
                justify-content: space-between;
                .social-links {
                  width: 32px;
                  height: 32px;
                  color: ${(props) => props.theme.secondary_color};
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 250px;
      }
    }
  }
  @media (max-width: 1441px) {
    .wrapper {
      .inner {
        padding: 0 200px;
      }
    }
  }

  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 150px;
        .content {
          .left {
            textarea {
              height: 150px;
            }
            .form-group {
              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }
          }
          .right {
            .right-inner {
              padding: 40px 50px 50px;
              h3 {
                margin-bottom: 20px;
              }
              hr {
                margin: 40px 0;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .content {
          .left {
            .form-group {
              input {
                padding: 17px;
              }
              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }
          }
          .right {
            .right-inner {
              padding: 30px;
              h3 {
                margin-bottom: 25px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 50px;
        .content {
          .left {
            .form-group {
              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }
          }
          .right {
            .right-inner {
              hr {
                margin: 30px 0;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        align-items: center;
        .content {
          margin-bottom: 0;
          display: block;
          .left {
            .form-group {
              input,
              textarea {
                padding: 15px;
              }
              textarea {
                height: 100px;
              }
              &:not(:last-child) {
                margin-bottom: 15px;
              }
            }
          }
          .right {
            display: none;
            .right-inner {
              hr {
                margin: 10px 0;
              }
            }
          }
        }
      }
    }
  }
`;

const ContactPageFirstSection = () => {
  return (
    <ContactPageFirstSectionWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="content">
            <div className="left">
              <div className="form-group">
                <input
                  type="text"
                  id="fullname"
                  name="fullname"
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="Phone"
                  name="Phone"
                  placeholder="Phone"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="company"
                  name="company"
                  placeholder="Company"
                  required
                />
              </div>
              <div className="form-group">
                <textarea placeholder="Message"></textarea>
              </div>
              <div className="form-end">
                <div className="form-end-left">
                  <div>
                    <input type="checkbox" id="accept" name="accept" />
                  </div>

                  <label htmlFor="accept">
                    I accept that the information I provide is processed
                    according to Ice Labs privacy policy.
                  </label>
                </div>
                <div className="form-end-right">
                  <CustomButton title="Submit" to="" />
                </div>
              </div>
            </div>
            <div className="right">
              <div className="right-inner">
                <h3>Other ways to get in touch with us:</h3>
                <div className="right-groups-wrapper">
                  <div className="right-groups">
                    <p>PHONE</p>
                    <span>+389 78 33 60 32</span>
                  </div>
                  <div className="right-groups">
                    <p>email</p>
                    <span>contact@icelabs.mk</span>
                  </div>
                  <div className="right-groups">
                    <p>address</p>
                    <span>
                      Trifun Hadzijanev br 12/3-17, 1000 Skopje, Macedonia
                    </span>
                  </div>
                </div>
                <hr></hr>
                <div className="right-end">
                  <p>YOU CAN ALSO FIND US ON:</p>
                  <div className="social-links-wrapper">
                    <a href="/" className="social-links" target="_blank">
                      <FacebookCircle />
                    </a>
                    <a href="/" className="social-links" target="_blank">
                      <InstagramAlt />
                    </a>
                    <a href="/" className="social-links" target="_blank">
                      <Twitter />
                    </a>
                    <a href="/" className="social-links" target="_blank">
                      <Linkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContactPageFirstSectionWrapper>
  );
};

export default ContactPageFirstSection;
