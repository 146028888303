import React, { useContext } from "react";
import styled from "styled-components";
import {
  InstagramAlt,
  Linkedin,
  Twitter,
  FacebookCircle,
} from "@styled-icons/boxicons-logos";
import { GlobalContext } from "../../Consts/GlobalContext";

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px 375px;
  background-color: ${(props) => props.theme.primary_color};
  border-top: 1px solid #9bb3cf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.6s ease-in;

  .left {
    display: flex;
    p {
      ${(props) => props.theme.footer_14};
      color: white;
      &:first-child {
        margin-right: 18.5px;
      }
      &:nth-child(2) {
        margin-left: 18.5px;
      }
    }
  }
  .right {
    display: flex;
    .social-links {
      width: 24px;
      height: 24px;
      color: #9bb3cf;
      display: block;
      &:not(:last-of-type) {
        margin-right: 24px;
      }
    }
  }

  @media (max-width: 1900px) {
    padding: 20px 50px;
  }
  @media (max-width: 1439px) {
    padding: 20px 50px;
  }
  @media (max-width: 1023px) {
    padding: 15px 20px;
  }
  @media (max-width: 767px) {
    display: block;
    text-align: center;
    padding: 7px 0;
    .left {
      justify-content: center;
      margin-bottom: 5px;
    }
    .right {
      justify-content: center;
      margin-top: 0px;
    }
  }
`;
const Footer = () => {
  const { openMenu, t } = useContext(GlobalContext);

  return (
    <FooterWrapper>
      <div className="left">
        <p>
          {t("FooterTitle1")}
          &#10084;
          {t("FooterTitle2")}
        </p>
        |<p>{t("FooterText")}</p>
      </div>
      <div className="right">
        <a href="/" className="social-links" target="_blank">
          <FacebookCircle />
        </a>
        <a href="/" className="social-links" target="_blank">
          <InstagramAlt />
        </a>
        <a href="/" className="social-links" target="_blank">
          <Twitter />
        </a>
        <a href="/" className="social-links" target="_blank">
          <Linkedin />
        </a>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
