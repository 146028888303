import React, { createContext, useState } from "react";
import i18n from "i18next";
import { useEffect } from "react";
import MobileHeightFixer from "../Components/MobileHeightFixer/MobileHeightFixer";
import TranslateEn from "../Components/Translate/TranslateEn";
import TranslateMk from "../Components/Translate/TranslateMk";
import { initReactI18next, useTranslation } from "react-i18next";
import $ from "jquery";

const Context = createContext();
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: TranslateEn },
    mk: { translation: TranslateMk },
  },
  lng: "en",
  fallBackLng: "en",
  interpolation: { escapeValue: false },
});
const Provider = ({ children }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [activeSectionDirection, setActiveSectionDirection] = useState("");
  const [isWheelEnabled, setIsWheelEnabled] = useState(true);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [mobileScreenPositionStart, setMobileScreenPositionStart] = useState(0);
  const [mobileScreenPositionEnd, setMobileScreenPositionEnd] = useState(0);
  const { t } = useTranslation();

  const resizeCondition = () => {
    if (window.innerWidth < 426) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  };

  useEffect(() => {
    document.querySelector("#root").style.overflow = "hidden";
    document.querySelector("#root").style.touchAction = "pan-y";
  }, []);
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }, []);
  useEffect(() => {
    resizeCondition();

    window.addEventListener("resize", () => {
      resizeCondition();
    });
  }, [window.innerWidth]);

  const changeLang = (e) => {
    i18n.changeLanguage(e.currentTarget.getAttribute("name"));
    localStorage.setItem("language", e.currentTarget.getAttribute("name"));
    // closeLangMenu(e.currentTarget.parentElement.parentElement);
  };

  useEffect(() => {
    let sections = document.querySelectorAll(".section");
    if (sections[activeSection].classList) {
      if (!sections[activeSection].classList.contains("dummy")) {
        $("html, body").animate(
          {
            scrollTop: $(
              `.${
                sections[activeSection].parentElement.className.split(" ")[0]
              }`
            ).offset().top,
          },
          2000
        );
      }
    }
  }, [activeSection]);
  const MouseWheelHandler = (e) => {
    let delta = e && e.deltaY / 100;
    let sections = document.querySelectorAll(".section").length;

    if (e.type === "touchstart") {
      const currentPageX1 = Math.round(e.changedTouches[0].screenY);
      setMobileScreenPositionStart(currentPageX1);
    } else {
      if (e.type === "touchend") {
        const currentPageX2 = Math.round(e.changedTouches[0].screenY);
        if (mobileScreenPositionStart === currentPageX2) {
          return false;
        }
        if (mobileScreenPositionStart - currentPageX2 > 0) {
          delta = 1;
        } else {
          delta = -1;
        }
        setMobileScreenPositionEnd(currentPageX2);
      }
      if (activeSection === 0 && delta < 0) {
        return false;
      } else if (activeSection === sections - 1 && delta > 0) {
        return false;
      }
      if (!isWheelEnabled) {
        return;
      }
      setIsWheelEnabled(false);
      setTimeout(() => {
        setIsWheelEnabled(true);
      }, 1500);
      if (delta > 0) {
        setActiveSection(activeSection + 1);
        setActiveSectionDirection("down");
      } else if (delta < 0) {
        setActiveSection(activeSection - 1);
        setActiveSectionDirection("up");
      }
    }
  };

  const value = {
    t,
    changeLang,
    activeSection,
    setActiveSection,
    MouseWheelHandler,
    isWheelEnabled,
    setIsWheelEnabled,
    activeSectionDirection,
    setActiveSectionDirection,
    isMobileScreen,
    setIsMobileScreen,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const GlobalContext = Context;
export const GlobalProvider = Provider;
