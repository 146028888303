import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { GlobalContext } from "../../Consts/GlobalContext";
import BlogSubscribeSection from "../BlogPage/BlogSubscribeSection";
import BottomFade from "../BottomFade/BottomFade";
import Menu from "../NavBar/Menu";
import BlogSingleElementPageFirstSection from "./BlogSingleElementPageFirstSection";
import BlogSingleElementPageIntro from "./BlogSingleElementPageIntro";
import BlogSingleElementPageSecondSection from "./BlogSingleElementPageSecondSection";
import BlogSingleElementPageThirdSection from "./BlogSingleElementPageThirdSection";

const BlogSingleElementPage = () => {
  const { setIsWheelEnabled, MouseWheelHandler } = useContext(GlobalContext);

  useEffect(() => {
    setIsWheelEnabled(false);
    setTimeout(() => {
      setIsWheelEnabled(true);
    }, 3000);
  }, []);
  return (
    <div onWheel={(e) => MouseWheelHandler(e)}>
      <Menu />
      <BlogSingleElementPageIntro />
      <BlogSingleElementPageFirstSection />
      <BlogSingleElementPageSecondSection />
      <BlogSingleElementPageThirdSection />
      <BlogSubscribeSection />
      <BottomFade />
    </div>
  );
};

export default BlogSingleElementPage;
