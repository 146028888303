import React from "react";
import styled from "styled-components";
import bg from "../../img/contact/bg.png";

const ContactPageIntroWrapper = styled.div`
  .wrapper {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .inner {
      height: 100%;
      padding: 0 475px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;
      .text {
        h1 {
          ${(props) => props.theme.font_bold_106};
        }
        p {
          ${(props) => props.theme.font_regular_20};
          color: #fff;
        }
      }
      .bg {
        position: absolute;
        z-index: -1;
        top: 220px;
        left: 0;
      }

      .circle-lines {
        position: absolute;
        bottom: 230px;
        left: 50%;
        transform: translatex(-50%);
        .down {
          transform: rotate(180deg);
          margin-top: 70px;
        }
        span {
          width: 18px;
          height: 18px;
          background-color: #fff;
          display: block;
          border-radius: 50%;
          position: relative;
        }
        hr {
          border: 0;
          border-left: 2px solid #9bb3cf;
          margin: 0;
          height: 45px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 15px;
          z-index: -1;
        }
      }
    }
  }
  @media (max-width: 1900px) {
    .wrapper {
      .inner {
        padding: 0 250px;
        .circle-lines {
          display: none;
        }
      }
    }
  }
  @media (max-width: 1441px) {
    .wrapper {
      .inner {
        .text {
          h1 {
            font-size: 90px;
            line-height: 115px;
          }
        }
      }
    }
  }
  @media (max-width: 1367px) {
    .wrapper {
      .inner {
        padding: 0 200px;
        .bg {
          top: 63px;
          left: -285px;
        }
      }
    }
  }
  @media (max-width: 1281px) {
    .wrapper {
      .inner {
        padding: 0 150px;
        .bg {
          left: -330px;
        }
      }
    }
  }
  @media (max-width: 1025px) {
    .wrapper {
      .inner {
        padding: 0 100px;
        .bg {
          left: -450px;
        }
        .text {
          margin-bottom: 150px;
        }
      }
    }
  }

  @media (max-width: 426px) {
    .wrapper {
      .inner {
        padding: 0 25px;
        .bg {
          left: -750px;
          top: 0;
        }
        .text {
          margin-bottom: 0px;
          h1 {
            font-size: 50px;
            line-height: 65px;
          }
          p {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;

const ContactPageIntro = () => {
  return (
    <ContactPageIntroWrapper>
      <div className="wrapper section">
        <div className="inner">
          <div className="text">
            <h1>Contact us</h1>
            <p>
              If you’ve got any questions, queries or requests that haven’t been
              covered already, fill the form below, and we’ll get back to you as
              soon as we can!
            </p>
          </div>
          <img className="bg" src={bg}></img>
          <div className="circle-lines">
            <div className="up">
              <span>
                <hr />
              </span>
            </div>
            <div className="down">
              <span>
                <hr />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ContactPageIntroWrapper>
  );
};

export default ContactPageIntro;
