import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CustomButtonWrapper = styled.div`
  a {
    text-align: center;
    display: inline-block;
    padding: 15px 20px;
    border-radius: 30px;
    background-color: ${(props) => props.theme.secondary_color};
    ${(props) => props.theme.font_medium_18};
    color: #fff;
    text-decoration: none;
    outline-color: #1f3040;
    transition: outline 0.3s ease-out, border 0.3s ease-out;
    &:hover {
      background-color: #1f3040;
      color: ${(props) => props.theme.secondary_color};
      outline: 5px solid #1f3040;
      outline-offset: -1px;
    }
  }
  a.dark-theme {
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.secondary_color};
    color: ${(props) => props.theme.secondary_color};
    &:hover {
      color: #fff;
      border-color: #fff;
      outline: none;
    }
  }
  @media (max-width: 769px) {
    a {
      font-size: 16px;
      line-height: 21px;
      padding: 10px 15px;
    }
  }
  @media (max-width: 426px) {
    a {
      font-size: 14px;
      line-height: 18px;
      padding: 10px 15px;
    }
  }
`;

const CustomButton = ({ title, to = "/coming-soon", colorTheme }) => {
  return (
    <CustomButtonWrapper>
      <Link className={colorTheme === "dark" ? "dark-theme" : ""} to={to}>
        {title}
      </Link>
    </CustomButtonWrapper>
  );
};

export default CustomButton;
